import {
  faBookOpen,
  faPlus,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../../../design-system/SButton";
import CreateCustomFieldModal from "../../../../pages/brand/customFields/CreateCustomFieldModal";
import { useBrandStore } from "../../../../store/brandStore";
import { useCreationStore } from "../../../../store/creationStore";
import { IBaseData } from "../../../../utils/interfaces/IBase";
import { ISingleBrandVM } from "../../../../utils/interfaces/IBrand";
import { useNavigate } from "react-router-dom";

interface BrandPreviewHeaderProps {
  currentBrand?: IBaseData<ISingleBrandVM>;
}

export const BrandPreviewHeader = ({
  currentBrand,
}: BrandPreviewHeaderProps) => {
  const {
    isCustomFieldModalOpen,
    setIsCustomFieldModalOpen,
    activeField,
    setActiveField,
  } = useBrandStore();
  const { setIsPreviewOpen } = useCreationStore();
  const navigate = useNavigate();
  if (!currentBrand?.data) return null;
  return (
    <div className="bg-gray-50 h-fit flex items-center justify-between p-5 border-b border-gray-100 rounded-t-xl">
      <CreateCustomFieldModal
        isOpen={isCustomFieldModalOpen}
        onClose={() => {
          setIsCustomFieldModalOpen(false);
          setActiveField(undefined);
        }}
        field={activeField}
      />
      <div className="flex items-center justify-between w-full h-[24px]">
        <div className="flex items-center gap-2">
          <SButton
            onClick={() => setIsPreviewOpen(false)}
            type="secondaryGray"
            size="sm"
            icon={faXmark}
            className="md:hidden"
          />
          <p className="text-lg-b text-gray-700">Identity</p>
        </div>
        <div className="flex items-center gap-[8px]">
          {/* <SButton
            onClick={() => {
              setIsCustomFieldModalOpen(true);
            }}
            lIcon={faPlus}
            type="secondaryGray"
            size="md"
          >
            Field
          </SButton> */}
          {/* <SButton
            type="secondaryGray"
            icon={faBookOpen}
            size="md"
            onClick={() => {
              navigate("/brand?subsection=brandbook");
            }}
          /> */}
        </div>
      </div>
    </div>
  );
};
