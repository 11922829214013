import { useEffect } from "react";
import { usePostCopilotSession } from "../../../api/copilot/session/post";
import { toast } from "../../../hooks/toast";
import { useCopilotStore } from "../../../store/copilotStore";
import { CopilotRole, CopilotPurpose } from "../../../utils/enums/Enums";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ICopilotSessionVM } from "../../../utils/interfaces/ICopilot";
import { usePostCopilotChatMessage } from "../../../api/copilot/chat/post";
import { ICopilotMessage } from "../../../utils/copilot-base/ICopilotBase";

export const useCopilotHelper = () => {
  const {
    messages,
    setMessages,
    activeSession,
    setIsInputDisabled,
    setActiveSession,
    setIsWriting,
  } = useCopilotStore();
  const { mutateAsync: createCopilotSession, isPending } =
    usePostCopilotSession();

  const { mutateAsync: postMessage } = usePostCopilotChatMessage();

  const sendMessage = async ({
    value,
    setValue,
  }: {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
  }) => {
    let msgsArr: ICopilotMessage[] = [...messages];
    if (value && value.length > 0) {
      setIsInputDisabled(true);
      //   setCurrentRows(minRows);
      let tmpValue = value;
      let tmpUser: ICopilotMessage = {
        role: CopilotRole.USER,
        content: {
          Type: "UserMessage",
          Message: tmpValue,
        },
        files: [],
        copilotSessionId: activeSession?.id!,
        version: 1,
      };
      let tmpAi: ICopilotMessage = {
        role: CopilotRole.ASSISTANT,
        content: undefined,
        files: [],
        copilotSessionId: activeSession?.id!,
        version: 1,
      };
      msgsArr.push(tmpUser);
      msgsArr.push(tmpAi);
      setMessages(msgsArr);
      postMessage({
        sessionId: activeSession?.id!,
        message: value,
      })
        .then((res) => {
          const latestMessages = [...useCopilotStore.getState().messages];
          // TODO: komentari movxsna
          latestMessages[latestMessages.length - 1].content =
            res.data.data[0].content;
          setMessages(latestMessages);
          setIsInputDisabled(false);
        })
        .catch((err) => {
          const latestMessages = [...useCopilotStore.getState().messages];
          latestMessages[latestMessages.length - 1].content =
            err.response.data.data;
          setMessages(latestMessages);
          setIsInputDisabled(false);
        });
    }
  };

  const handleCreateCopilotSession =
    async (): Promise<IBaseData<ICopilotSessionVM> | void> => {
      try {
        return await createCopilotSession({
          purposeType: CopilotPurpose.POSTCREATION,
        }).then((res) => {
          setMessages([]);
          setActiveSession(res.data);
        });
      } catch (err) {
        //@ts-ignore
        toast(err?.response?.data?.message);
        throw err;
      }
    };
  return { sendMessage, handleCreateCopilotSession };
};
