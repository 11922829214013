import { useEffect, useState } from "react";
import { useCreationHelperFunctions } from "../../creation/hooks/useCreationHelperFunctions";
import { useFillSuggestedPrompts } from "../../creation/hooks/useFillSuggestedPrompts";
import { useBriefHelperFunctions } from "../../creation/Tabs/Brief/hooks/useBriefHelperFunctions";
import { useUploadFiles } from "../../../hooks/useUploadFiles";
import { useCreationStore } from "../../../store/creationStore";
import { useUpdatePost } from "../../creation/hooks/useUpdatePost";
import { useFillChat } from "../../creation/Tabs/Brief/hooks/useFIllChat";
import { useInitPreview } from "./useInitPreview";

export const useAgentChat = () => {
  const [isUpdatedByCopilot, setIsUpdatedByCopilot] = useState(false);
  const { briefMessages, copilotSessionId } = useCreationStore();
  const { updateStory } = useUpdatePost();
  const [chatResponse, setChatResponse] = useState("");
  const [disable, setDisable] = useState(false);
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { files, setFiles, selectFile } = useUploadFiles();
  const { sendMessage } = useBriefHelperFunctions({
    setFiles,
    files,
    setChatResponse,
    setDisable,
    setValue,
  });
  const { initialLoad } = useFillChat({
    setChatResponse,
    setDisable,
    setValue,
  });
  useInitPreview({ initialLoad, setIsUpdatedByCopilot });
  useFillSuggestedPrompts();

  useEffect(() => {
    document.getElementById("copilotPostBriefChat")?.scrollTo({
      top: 1000000,
      left: 0,
      behavior: "smooth",
    });
  }, [briefMessages]);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1200);
    return () => clearTimeout(timer);
  }, [copilotSessionId]);

  useEffect(() => {
    if (briefMessages.length === 0) {
      setIsLoading(false);
    }
  }, [briefMessages]);

  const handleGetStory = async (story: string) => {
    await updateStory(story);
    await sendMessage(`Generate post:${story}`, true);
  };
  const { isPostPublishedOrScheduled } = useCreationHelperFunctions();
  const [isExpandedImageModalOpen, setIsExpandedImageModalOpen] = useState<
    null | string
  >(null);
  const [isExpandedVideoModalOpen, setIsExpandedVideoModalOpen] =
    useState(false);

  return {
    isUpdatedByCopilot,
    briefMessages,
    copilotSessionId,
    handleGetStory,
    isPostPublishedOrScheduled,
    isExpandedImageModalOpen,
    setIsExpandedImageModalOpen,
    isExpandedVideoModalOpen,
    setIsExpandedVideoModalOpen,
    value,
    setValue,
    files,
    setFiles,
    selectFile,
    setDisable,
    disable,
    chatResponse,
    setChatResponse,
    sendMessage,
    isLoading,
  };
};
