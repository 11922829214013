import { useEffect, useState } from "react";
import { ICopilotMessage } from "../../../../../utils/copilot-base/ICopilotBase";
import { useCreationStore } from "../../../../../store/creationStore";
import {
  AgentLoadingState,
  AgentLoadingStateValues,
} from "../../../../../utils/enums/Enums";

export const useThoughtStatus = (msg: ICopilotMessage) => {
  const { copilotAgentLoadingState } = useCreationStore();
  const [thoughts, setThoughts] = useState<null | string>(null);
  useEffect(() => {
    let thoughtSwitcher: ReturnType<typeof setInterval> | undefined = undefined;
    if (!msg.content && !msg.message) {
      if (
        copilotAgentLoadingState === AgentLoadingState.Default ||
        copilotAgentLoadingState === null
      ) {
        setThoughts("Thinking...");
        thoughtSwitcher = setInterval(() => {
          setThoughts((prev) => {
            if (prev === "Thinking...") return "Analyzing...";
            if (prev === "Analyzing...") return "Creating...";
            return "Thinking...";
          });
        }, 4000);
      } else {
        copilotAgentLoadingState &&
          setThoughts(
            AgentLoadingStateValues[copilotAgentLoadingState] as string
          );
      }
    } else {
      clearInterval(thoughtSwitcher);
      setThoughts(null);
    }

    return () => {
      clearInterval(thoughtSwitcher);
      setThoughts(null);
    };
  }, [msg, copilotAgentLoadingState]);

  return { thoughts };
};
