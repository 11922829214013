import { IBaseData } from "../../../../utils/interfaces/IBase";
import { ISingleBrandVM } from "../../../../utils/interfaces/IBrand";
import { SaveStatus } from "../../../shared/SaveStatus";
import {
  faGif,
  faPaintbrushPencil,
  faSparkles,
  faUpload,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../../../design-system/SButton";
import { useCreationStore } from "../../../../store/creationStore";
import STertiaryTabs from "../../../../design-system/STertiaryTabs";

interface PostHeaderProps {
  currentBrand?: IBaseData<ISingleBrandVM>;
  isSaving: boolean;
  postName?: string;
}

export const PostHeader = ({
  currentBrand,
  isSaving,
  postName,
}: PostHeaderProps) => {
  const { setIsPreviewOpen, selectedPreviewTab, setSelectedPreviewTab } =
    useCreationStore();

  if (!currentBrand?.data) return null;
  return (
    <div className="h-fit flex flex-col items-start justify-between rounded-t-xl">
      <div className="flex items-center justify-between w-full border-b border-gray-100 bg-gray-50 p-3.5">
        <div className="w-full flex justify-between items-center gap-2">
          <STertiaryTabs
            items={[
              { text: "Current", index: 0 },
              { text: "Recents", index: 1 },
            ]}
            selected={selectedPreviewTab}
            setSelected={setSelectedPreviewTab}
          />
          <SaveStatus isSaving={isSaving} />
          {/* <SButton
            type="secondaryGray"
            size="md"
            isLoading={isPending}
            lIcon={isPending ? faRefresh : faPlusSquare}
            data-tooltip-id="edit-post-button"
            data-tooltip-content="New post"
            onClick={async () => {
              await handleCreateNewPost();
              setSelectedTab(0);
              setSelectedPreviewTab(0);
              setSelectedChatId("general");
              }}
              />
              <Tooltip id="edit-post-button" place="bottom" /> */}
        </div>
        <div className="flex items-center w-fit gap-2">
          <SButton
            onClick={() => setIsPreviewOpen(false)}
            type="secondaryGray"
            size="sm"
            icon={faXmark}
            className="md:hidden"
          />
        </div>
      </div>
    </div>
  );
};
