export enum TeamRole {
  OWNER = 1,
  MEMBER = 2,
  VIEWER = 3,
}

export enum InvitationStatus {
  SENT = 1,
  ACCEPTED = 2,
  REJECTED = 3,
  CANCELED = 4,
}

export enum TeamPackageStatus {
  ACTIVE = 1,
  PAUSED = 2,
  REFUNDED = 6,
  CANCELED = 8,
}

export enum PostStatus {
  DRAFT = 1,
  PUBLISHED = 2,
  DELETED = 3,
  SCHEDULED = 4,
  REMINDER = 5,
}

export enum FileType {
  IMAGE = 1,
  VIDEO = 2,
  TEXT = 3,
}

export enum CopilotRole {
  USER = 0,
  SYSTEM = 1,
  ASSISTANT = 2,
  TOOL = 3,
}

export enum NotificationType {
  INVITATION = 1,
  USERTAGGED = 2,
  PostDraftWasCreated = 3,
  PostWasPublished = 4,
  PostWasScheduled = 5,
  ScheduledPostWasPublished = 6,
  ExamplePostCreated = 7,
  UserJoinedTheBrand = 8,
  SystemNotification = 9,
  ReminderPostReadyForPublishing = 10,
}

export enum OrganizationType {
  COMPANY = 1,
  PERSONAL = 2,
}

export enum BillingPeriod {
  MONTHLY = 1,
  ANNUALLY = 2,
}

export enum OrganizationUserRole {
  OWNER = 1,
  MEMBER = 2,
  VIEWER = 3,
}

export enum ContentLanguages {
  ENGLISH = 1,
  // GEORGIAN = 2,
  PORTUGUESE = 3,
  SPANISH = 4,
  POLISH = 5,
  CHINESE = 6,
  JAPANESE = 7,
  GERMAN = 8,
  FRENCH = 9,
  DUTCH = 10,
  TURKISH = 11,
  ITALIAN = 12,
  SWEDISH = 13,
}

export enum CopilotType {
  DEFAULT = 1,
  POST = 2,
  ORGANIZATIONCREATIONCOMPANY = 3,
  ORGANIZATIONCREATIONPERSONAL = 4,
}

export enum CopilotPurpose {
  REGULAR = 0,
  POSTCREATION = 1,
  BRAND = 2,
  BRANDCREATION = 3,
}

export enum SocialNetworkType {
  MASTERCONTENT = 0,
  FACEBOOK = 1,
  INSTAGRAM = 2,
  LINKEDIN = 3,
  TWITTER = 4,
  PINTEREST = 5,
  // TIKTOK = 6,
  SLACK = 7,
  DISCORD = 8,
  TELEGRAM = 9,
  REDDIT = 10,
}
export const SocialNetworkTypeName = {
  [SocialNetworkType.FACEBOOK]: "Facebook",
  [SocialNetworkType.INSTAGRAM]: "Instagram",
  [SocialNetworkType.TWITTER]: "X (Twitter)",
  [SocialNetworkType.LINKEDIN]: "Linkedin",
  [SocialNetworkType.PINTEREST]: "Pinterest",
  [SocialNetworkType.SLACK]: "Slack",
  [SocialNetworkType.DISCORD]: "Discord",
  [SocialNetworkType.TELEGRAM]: "Telegram",
  [SocialNetworkType.REDDIT]: "Reddit",
};

export enum SocialNetworkCharacterLimit {
  FACEBOOK = 63206,
  INSTAGRAM = 2200,
  LINKEDIN = 3000,
  TWITTER = 280,
  PINTEREST = 500,
  REDDIT = 40000,
}

export enum ImageGenerationStyle {
  Photorealistic = 1,
  FacePortrait = 2,
  PopArt = 3,
  Cartoon = 4,
  SplatterPaint = 5,
  BlogIllustration = 6,
  MinimalLineArt = 7,
  Neon = 8,
  AnimatedCharacter = 9,
  Painting = 10,
  NONE = 0,
}

export enum ImageGenerationAspectRatio {
  "21:9" = "21:9",
  "16:9" = "16:9",
  "4:3" = "4:3",
  "1:1" = "1:1",
  "3:4" = "3:4",
  "9:16" = "9:16",
  "9:21" = "9:21",
}

export enum TemplateTokenType {
  TEXT = 1,
  IMAGE = 2,
}

export enum CopilotAgentIdentifier {
  General = 1,
  Copywriter = 2,
  GraphicDesigner = 3,
  BrandStrategist = 4,
  PromptEngineer = 5,
  DataAnalyst = 6,
  Researcher = 7,
  RAG = 8,
}

export const CopilotAgentName = {
  [CopilotAgentIdentifier.General]: "Velin",
  [CopilotAgentIdentifier.Copywriter]: "Copia",
  [CopilotAgentIdentifier.GraphicDesigner]: "Sivo",
  [CopilotAgentIdentifier.BrandStrategist]: "Breni",
  [CopilotAgentIdentifier.PromptEngineer]: "Proma",
  [CopilotAgentIdentifier.DataAnalyst]: "Datix",
  [CopilotAgentIdentifier.Researcher]: "Renix",
  [CopilotAgentIdentifier.RAG]: "Archie",
};

export const CopilotAgentRole = {
  [CopilotAgentIdentifier.General]: "Manager",
  [CopilotAgentIdentifier.Copywriter]: "Writer",
  [CopilotAgentIdentifier.GraphicDesigner]: "Designer",
  [CopilotAgentIdentifier.BrandStrategist]: "Strategist",
  [CopilotAgentIdentifier.PromptEngineer]: "Prompt Engineer",
  [CopilotAgentIdentifier.DataAnalyst]: "Data Analyst",
  [CopilotAgentIdentifier.Researcher]: "Researcher",
  [CopilotAgentIdentifier.RAG]: "Memory",
};

export enum CopilotChatRole {
  User,
  System,
  Assistant,
  Tool,
}

export enum CopilotMessageType {
  CopilotMessage = 1,
  UserMessage = 2,
  WebsiteScraping = 3,
  BrandCreation = 4,
  TextVersions = 5,
  ContentCalendarOutline = 6,
  OneMonthPlan = 7,
  Budget = 8,
  StackAndServices = 9,
  TeamCapabilityAssessment = 10,
}

export enum AgentLoadingState {
  Default = 0,
  Researching = 1,
  LookingForRag = 2,
  WritingCopy = 3,
  GettingSuggestions = 4,
  SavingIntoRag = 5,
  Copia = 6,
  Sivo = 7,
  Breni = 8,
  Velin = 9,
  Aseta = 10,
  ContentCalendar = 11,
  OneMonthPlan = 12,
  WebsiteScraping = 13,
  GeneratingBrand = 14,
}

export const AgentLoadingStateValues = {
  [AgentLoadingState.Default]: "Default",
  [AgentLoadingState.Researching]: "Researching",
  [AgentLoadingState.LookingForRag]: "Looking for RAG",
  [AgentLoadingState.WritingCopy]: "Writing Copy",
  [AgentLoadingState.GettingSuggestions]: "Getting Suggestions",
  [AgentLoadingState.SavingIntoRag]: "Saving into RAG",
  [AgentLoadingState.Copia]: CopilotAgentIdentifier.Copywriter,
  [AgentLoadingState.Sivo]: CopilotAgentIdentifier.GraphicDesigner,
  [AgentLoadingState.Breni]: CopilotAgentIdentifier.BrandStrategist,
  [AgentLoadingState.Velin]: CopilotAgentIdentifier.General,
  [AgentLoadingState.Aseta]: CopilotAgentIdentifier.RAG,
  [AgentLoadingState.ContentCalendar]: "Writing Content Calendar",
  [AgentLoadingState.OneMonthPlan]: "Writing One Month Plan",
  [AgentLoadingState.WebsiteScraping]: "Scraping Website",
  [AgentLoadingState.GeneratingBrand]: "Generating Brand",
};
