import {
  faMagicWandSparkles,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import SInput from "../../../design-system/SInput";
import { useCreationStore } from "../../../store/creationStore";
import { useHelperInputHelperFunctions } from "../../creation/Tabs/Media/HelperInput/useHelperInputHelperFunctions";
import { useCreationLibraryHelperFunctions } from "../../creation/Tabs/Media/hooks/useCreationLibraryHelperFunctions";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ISingleFIleVM } from "../../../utils/interfaces/IAssets";
import {
  ImageGenerationAspectRatio,
  ImageGenerationStyle,
} from "../../../utils/enums/Enums";
import SRadio from "../../../design-system/SRadio";
import SButton from "../../../design-system/SButton";
import STextarea from "../../../design-system/STextarea";
import { useTextareaHeight } from "../hooks/useTextareaHeight";

export const AssetModalSearch = ({
  setInputValue,
  inputValue,
  setSelected,
  activeAspectRatio,
  activeStyleTag,
  setGifSearch,
  gifSearch,
  setType,
  type,
}: {
  setInputValue: (value: string) => void;
  inputValue: string;
  setSelected: React.Dispatch<React.SetStateAction<ISingleFIleVM | null>>;
  activeAspectRatio: ImageGenerationAspectRatio;
  activeStyleTag: ImageGenerationStyle;
  setGifSearch: (value: string) => void;
  gifSearch: string;
  setType: Dispatch<SetStateAction<"Gifs" | "Clips">>;
  type: "Gifs" | "Clips";
}) => {
  const { textareaRef } = useTextareaHeight(inputValue);
  const { handleSearchStocks } = useHelperInputHelperFunctions();
  const { handleGenerateImage } = useCreationLibraryHelperFunctions();
  const [loading, setIsLoading] = useState(false);
  const { librarySearchValue, setLibrarySearchValue, assetsTab } =
    useCreationStore();

  useEffect(() => {
    if (assetsTab === "stocks") {
      handleSearchStocks("");
    }
  }, [assetsTab]);

  const determineValue = () => {
    if (assetsTab === "assets") {
      return librarySearchValue;
    }
    if (assetsTab === "generator" || assetsTab === "stocks") {
      return inputValue;
    }
    if (assetsTab === "gifs") {
      return gifSearch;
    }
    return "";
  };
  const handleGenerate = () => {
    setIsLoading(true);
    handleGenerateImage({
      prompt: inputValue,
      style: activeStyleTag,
      aspectRatio: activeAspectRatio,
    })
      .then((res) => {
        setSelected(res.data);
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <>
      {assetsTab === "generator" ? (
        <STextarea
          ref={textareaRef}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === "Enter" && !e.shiftKey && inputValue.length > 0) {
              e.preventDefault();
              handleGenerate();
            }
          }}
          value={inputValue}
          className="w-full mt-2.5"
          textareaClassname="bg-gray-100 border border-gray-200 outline-none focus:border-brand-400 w-full rounded-md focus:ring-0 focus:outline-none pr-[140px] min-h-[128px] max-h-[350px]"
          placeholder="Describe your image..."
          rightAdditionalContent={
            <SButton
              className="absolute top-3 right-0"
              isLoading={loading}
              onClick={handleGenerate}
              lIcon={faMagicWandSparkles}
              type="secondaryColor"
              size="sm"
            >
              Generate
            </SButton>
          }
        />
      ) : (
        <SInput
          onChange={(e) => {
            if (assetsTab === "assets") {
              setLibrarySearchValue(e.target.value);
            }
            if (assetsTab === "stocks") {
              setInputValue(e.target.value);
            }
            if (assetsTab === "gifs") {
              setGifSearch(e.target.value);
            }
          }}
          onKeyDown={(e) => {
            if (assetsTab === "stocks" && e.code === "Enter") {
              handleSearchStocks(inputValue);
            }
          }}
          value={determineValue()}
          className="w-full mt-2.5"
          inputClassName="bg-gray-100 border border-gray-200 focus:border-brand-400 w-full rounded-md focus:ring-0 focus:outline-none"
          placeholder={
            assetsTab === "stocks"
              ? "Search for stock images..."
              : assetsTab === "assets"
                ? "Search for assets..."
                : assetsTab === "gifs"
                  ? "Search in KLIPY.com"
                  : "Search for assets..."
          }
          icon={assetsTab === "assets" ? faSearch : undefined}
          rightAdditionalContent={
            assetsTab === "gifs" && (
              <div className="flex gap-2 items-center">
                <div
                  className="flex items-center gap-1 bg-base-white border border-gray-200 rounded-[8px] p-[4px] cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setType("Gifs");
                  }}
                >
                  <SRadio size="sm" checked={type === "Gifs"} />
                  <p>GIFs</p>
                </div>
                <div
                  className="flex items-center gap-1 bg-base-white border border-gray-200 rounded-[8px] p-[4px] cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setType("Clips");
                  }}
                >
                  <SRadio size="sm" checked={type === "Clips"} />
                  <p>Clips</p>
                </div>
              </div>
            )
          }
        />
      )}
    </>
  );
};
