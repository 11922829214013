import { CopilotAgentIdentifier } from "../../../utils/enums/Enums";
//@ts-ignore
import Breni from "../../../assets/images/agents/breni.png";
//@ts-ignore
import Velin from "../../../assets/images/agents/velin.png";
//@ts-ignore
import Copia from "../../../assets/images/agents/copia.png";
//@ts-ignore
import Sivo from "../../../assets/images/agents/sivo.png";
//@ts-ignore
import Archie from "../../../assets/images/agents/archie.png";
// //@ts-ignore
// import Datix from "../../assets/images/agents/datix.png";
// //@ts-ignore
// import Proma from "../../assets/images/agents/proma.png";
// //@ts-ignore
// import Renix from "../../assets/images/agents/renix.png";

export const getSelectedAgents = (agentIds: number[]) => {
  const agents: any = {
    [CopilotAgentIdentifier.General]: Velin,
    [CopilotAgentIdentifier.BrandStrategist]: Breni,
    [CopilotAgentIdentifier.Copywriter]: Copia,
    [CopilotAgentIdentifier.GraphicDesigner]: Sivo,
    [CopilotAgentIdentifier.RAG]: Archie,
    // [CopilotAgentIdentifier.PromptEngineer]: Proma,
    // [CopilotAgentIdentifier.DataAnalyst]: Datix,
    // [CopilotAgentIdentifier.Researcher]: Renix,
  };
  return agentIds.map((id) => agents[id as CopilotAgentIdentifier]);
};
