import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { GroupChatImages } from "./GroupChatImages";
import { faHashtag } from "@fortawesome/pro-regular-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";

export interface AdditionalButtonProps {
  icon: FontAwesomeIconProps["icon"];
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export interface AgentSidebarButtonProps {
  name: string;
  icon?: FontAwesomeIconProps["icon"];
  chatId: string;
  setSelectedChatId: (chatId: string) => void;
  images?: string[];
  selectedChatId: string | null;
  className?: string;
  onClick?: () => void;
  additionalButton?: AdditionalButtonProps;
  hasHashtag?: boolean;
  role?: string;
  hidden?: boolean;
}

export const AgentSidebarButton = ({
  name,
  icon,
  images,
  chatId,
  selectedChatId,
  setSelectedChatId,
  className,
  onClick,
  additionalButton,
  hasHashtag,
  role,
  hidden = false,
}: AgentSidebarButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      {!hidden && (
        <div
          onClick={() => {
            if (selectedChatId === chatId) return;
            if (location.pathname !== "/") {
              navigate("/");
            }
            setSelectedChatId(chatId);
            onClick && onClick();
          }}
          className={`${className} [&>div:nth-child(2)]:hover:opacity-100 flex w-full cursor-pointer  ${selectedChatId === chatId ? "bg-gray-100" : "hover:bg-gray-50"} transition-all px-3 py-[10px] items-center justify-between rounded-lg`}
        >
          <div
            className={`flex w-full items-center ${hasHashtag ? "gap-4" : "gap-3"}`}
          >
            {icon && (
              <FontAwesomeIcon icon={icon} className="text-gray-700 w-6" />
            )}
            {images && (
              <GroupChatImages images={images} name={name} variant="sidebar" />
            )}
            <div className="flex w-full items-center gap-1">
              {hasHashtag && (
                <FontAwesomeIcon
                  className="text-gray-700 text-sm-m"
                  icon={faHashtag}
                />
              )}
              <p className="text-sm-m w-[calc(100%-15px)] truncate text-gray-700">
                {name || "Chat Session"}{" "}
                {role && <p className="text-xs-m text-gray-400">{role}</p>}
              </p>
            </div>
          </div>
          <div
            onClick={(e) => additionalButton?.onClick(e)}
            className="opacity-0 transition-all"
          >
            {additionalButton && (
              <FontAwesomeIcon
                icon={additionalButton.icon}
                size="sm"
                className="text-gray-700 hover:text-gray-900 cursor-pointer"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
