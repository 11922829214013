import {
  faCreditCard,
  faQuestionCircle,
  faBell,
  faCommentSmile,
  faSparkles,
} from "@fortawesome/pro-regular-svg-icons";

import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { getQuery } from "../../hooks/functions/queries";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetUser } from "../../hooks/useUserInfo";
import { useGetNotifications } from "../../api/notifications/get";
import { useState, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import AccountHead from "../header/AccountHead";
import SSmallMenu from "../../design-system/SSmallMenu";

import { OrganizationUserRole } from "../../utils/enums/Enums";
import { useGetWorkspaces } from "../../api/workspaces/get";
import { useGetCurrentWorkspace } from "../../api/workspaces/current/get";
import { usePutSwitchTeam } from "../../api/workspaces/swich/put";
import { useContentStore } from "../../store/contentStore";
import { useLibraryStore } from "../../store/libraryStore";
import LettersAvatar from "../utils/LettersAvatar";
import SRadio from "../../design-system/SRadio";
import { useHandleOutsideClick } from "../../hooks/useHandleOutsideClick";

const WorkspaceSettingsHeader = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const subsection = getQuery("subsection");
  const pathname = useLocation().pathname;
  const { data: workspaces } = useGetWorkspaces();
  const { data: currentWorkspace } = useGetCurrentWorkspace();
  const { mutateAsync: switchWorkspace, isPending } = usePutSwitchTeam();
  const { setPosts, setPage } = useContentStore();
  const {
    setFoldersAndFiles,
    setPage: setLibraryPage,
    setParentFolder,
  } = useLibraryStore();

  const [loadingId, setLoadingId] = useState<number | null>(null);
  const [isWorkspaceMenuOpen, setIsWorkspaceMenuOpen] = useState(false);

  const { data: notifications } = useGetNotifications({
    page: 1,
    pageSize: 10,
  });
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const { data: userInfo } = useGetUser();
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.clear();
    queryClient.resetQueries();
    navigate("/login");
  };

  const handleWorkspaceSwitch = async (workspaceId: number) => {
    setLoadingId(workspaceId);
    try {
      await switchWorkspace({ teamId: workspaceId });
      setPosts([]);
      setPage(1);
      setFoldersAndFiles([]);
      setLibraryPage(1);
      setParentFolder(null);
      setIsWorkspaceMenuOpen(false);
    } finally {
      setLoadingId(null);
    }
  };

  const shouldHighlightProfile = () => {
    if (subsection) {
      return (
        subsection === "notifications" ||
        subsection === "account" ||
        subsection === "billing"
      );
    }
    return (
      pathname.split("/")[1] === "notifications" ||
      pathname.split("/")[1] === "account" ||
      pathname.split("/")[1] === "billing"
    );
  };

  const workspaceMenuRef = useRef<HTMLDivElement>(null);

  useHandleOutsideClick({
    ref: workspaceMenuRef,
    callBack: () => setIsWorkspaceMenuOpen(false),
  });

  return (
    <div className="flex justify-between items-center w-full bg-white border border-gray-200 rounded-xl p-2 mb-3">
      <div className="relative" ref={workspaceMenuRef}>
        <div
          onClick={() => setIsWorkspaceMenuOpen(!isWorkspaceMenuOpen)}
          className="flex items-center gap-2 cursor-pointer p-1 hover:bg-gray-50 rounded-md"
        >
          <LettersAvatar text={currentWorkspace?.data.name || ""} />
          <span className="text-sm-m text-gray-900">
            {currentWorkspace?.data.name}
          </span>
        </div>
        {isWorkspaceMenuOpen && (
          <div className="absolute top-full left-0 mt-1 w-[380px] bg-white border border-gray-200 rounded-lg shadow-xs z-50">
            <div className="px-5 flex flex-col w-full max-h-[300px] overflow-auto">
              {workspaces?.data.map((workspace, index) => (
                <div
                  key={workspace.id}
                  onClick={() => handleWorkspaceSwitch(workspace.id)}
                  className={`${
                    index !== workspaces.data.length - 1
                      ? "border-b"
                      : "border-b-0"
                  } border-b-gray-200 py-5 flex items-center justify-between gap-[6px] cursor-pointer`}
                >
                  <div className="flex gap-3 items-center">
                    <LettersAvatar text={workspace.name} />
                    <span className="text-sm-sb text-gray-700 max-w-[300px] w-full">
                      {workspace.name}
                    </span>
                  </div>
                  <SRadio
                    size="sm"
                    disabled={isPending}
                    checked={workspace.id === currentWorkspace?.data.id}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="flex items-center max-md:gap-[10px] relative">
        <div className="relative">
          {notifications?.data.notifications.find((item) => !item.isRead) && (
            <div className="absolute top-0 right-0 w-2.5 h-2.5 flex justify-center items-center bg-orange-500 rounded-full" />
          )}
          <img
            id="header-profile-image"
            onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
            src={
              userInfo?.data.data.photoUrl ??
              "https://cdn.storiai.com/avatar.svg"
            }
            alt="avatar"
            className={`cursor-pointer w-[36px] h-[36px] object-cover rounded-full`}
          />
        </div>
        {isProfileMenuOpen && (
          <SSmallMenu
            isOpen={isProfileMenuOpen}
            className="top-[50px] !-left-[200px] w-[80px] z-100"
            setIsOpen={(p) => setIsProfileMenuOpen(p)}
            excludedId="header-profile-image"
            data={[
              [
                {
                  text: userInfo && <AccountHead data={userInfo.data.data} />,
                  handleClick: () => navigate("/account"),
                  isHighlighted: shouldHighlightProfile(),
                },
                {
                  text: (
                    <div
                      className="flex items-center relative w-[4px] h-[4px] text-gray-500 py-[10px]"
                      onClick={() => navigate("/notifications")}
                    >
                      {notifications?.data.notifications.find(
                        (item) => !item.isRead
                      ) && (
                        <div className="absolute -top-2 -left-3 w-2 h-2 flex justify-center items-center bg-orange-500 rounded-full" />
                      )}
                      <p className="text-sm-m capitalize text-gray-700">
                        Notifications
                      </p>
                    </div>
                  ),
                  icon: faBell,
                  handleClick: () => navigate("/notifications"),
                  isHighlighted: shouldHighlightProfile(),
                },
                ...(userInfo?.data.data.userRole === OrganizationUserRole.VIEWER
                  ? []
                  : [
                      {
                        text: "Billing",
                        icon: faCreditCard,
                        handleClick: () =>
                          navigate("/workspace?subsection=billing"),
                        isHighlighted: shouldHighlightProfile(),
                      },
                    ]),
              ],
              [
                {
                  text: "What's New",
                  icon: faSparkles,
                  handleClick: () =>
                    window.open("https://stori.canny.io/changelog", "_blank"),
                },
                {
                  text: "Discord",
                  icon: faCommentSmile,
                  handleClick: () =>
                    window.open("https://discord.gg/34SQh4cmMk", "_blank"),
                },
                {
                  text: "Support",
                  icon: faQuestionCircle,
                  handleClick: () =>
                    window.open("https://storiai.com/contact", "_blank"),
                },
              ],
              [
                {
                  text: "Logout",
                  icon: faArrowRightFromBracket,
                  handleClick: () => handleLogout(),
                  color: "text-red-500",
                },
              ],
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default WorkspaceSettingsHeader;
