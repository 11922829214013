import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AgentChatTextarea } from "./AgentChatTextarea";
import SButton from "../../design-system/SButton";
import {
  faChevronLeft,
  faChevronRight,
  faEllipsisVertical,
} from "@fortawesome/pro-regular-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import { useAgentChat } from "./hooks/useAgentChat";
import AIMessage from "../creation/Tabs/Brief/AIMessage";
import {
  CopilotAgentIdentifier,
  CopilotMessageType,
  CopilotPurpose,
  CopilotRole,
} from "../../utils/enums/Enums";
import ToolMessage from "../creation/Tabs/Brief/ToolMessage";
import MessageLoader from "../creation/Tabs/Brief/MessageLoader";
import { useCreateNewSession } from "./hooks/useCreateNewSession";
import UserMessage from "../creation/Tabs/Brief/UserMessage";
import { useCreationStore } from "../../store/creationStore";
import { useSaveChanges } from "./hooks/useSaveChanges";
import { useBrandSession } from "./hooks/useBrandSession";
import { ChatTitle } from "./ChatTitle";
import { useContentStore } from "../../store/contentStore";
import PreviewHeader from "./preview/PreviewHeader";
import PreviewContent from "./preview/PreviewContent";
import { AgentSessions } from "./AgentSessions";
import { CalendarContentOutline } from "./CalendarContentOutline";
import { GroupChatImages } from "./GroupChatImages";
import { getPurposeImages } from "./functions/getPurposeImages";
import SuggestedPrompts from "../creation/SuggestedPrompts";
import { Tooltip } from "react-tooltip";
import { OneMonthPlanMessages } from "./OneMonthPlanMessages";
import { useAgentsHub } from "./hooks/useAgentsHub";
import { useTextareaHeight } from "./hooks/useTextareaHeight";

export interface AgentChatProps {
  setExpandedSingleVideoModal: Dispatch<SetStateAction<boolean>>;
  setExpandedSingleImageModal: Dispatch<SetStateAction<string | null>>;
}

export const AgentChat = ({
  setExpandedSingleVideoModal,
  setExpandedSingleImageModal,
}: AgentChatProps) => {
  const [value, setValue] = useState("");
  const { isSaving, handleBlur } = useSaveChanges();

  const {
    isPreviewOpen,
    setIsPreviewOpen,
    chatAgents,
    selectedCopilotPurpose,
    selectedAgent,
    postId,
    selectedTab,
    selectedChatId,
    isPostFetching,
  } = useCreationStore();
  const { posts } = useContentStore();
  const currentPost = posts.find((p) => p.id === postId);

  const {
    briefMessages,
    handleGetStory,
    sendMessage,
    files,
    setFiles,
    isLoading,
    selectFile,
    disable,
  } = useAgentChat();

  const { isCopilotSessionFetching, isCopilotChatFetching, isPending } =
    useCreateNewSession();
  useBrandSession();
  useAgentsHub();

  const shouldHavePreview = () => {
    if (selectedCopilotPurpose === CopilotPurpose.POSTCREATION) return true;
    if (selectedCopilotPurpose === CopilotPurpose.BRAND) return true;
    return false;
  };

  useEffect(() => {
    if (shouldHavePreview()) {
      setIsPreviewOpen(true);
    } else {
      setIsPreviewOpen(false);
    }
  }, [briefMessages.length, selectedCopilotPurpose, setIsPreviewOpen]);
  const { textareaRef } = useTextareaHeight(value);

  return (
    <div className="w-full flex relative">
      <div
        className={`bg-base-white border border-gray-200 overflow-hidden rounded-xl relative flex ${
          isPreviewOpen
            ? ` md:w-[60%] ${selectedCopilotPurpose === CopilotPurpose.BRAND ? "md:w-[60]" : "md:w-[65.2%]"} w-full`
            : "w-full"
        } transition-all duration-300 ease-in-out h-full`}
      >
        <div
          className={`flex w-full flex-col justify-between h-full rounded-xl`}
        >
          <div
            className={`bg-gray-50 pl-5 ${
              selectedCopilotPurpose === CopilotPurpose.POSTCREATION
                ? "md:h-[98px] h-[90px]"
                : "md:h-[64px] h-[56px]"
            } flex justify-between items-center w-full rounded-tl-xl p-3.5 border-b border-gray-100`}
          >
            <ChatTitle
              selectedCopilotPurpose={selectedCopilotPurpose}
              selectedAgent={selectedAgent || CopilotAgentIdentifier.General}
              chatAgents={chatAgents}
              postName={currentPost?.name}
            />
            <div className="flex items-center self-start gap-2">
              {selectedCopilotPurpose !== CopilotPurpose.REGULAR && (
                <GroupChatImages
                  images={
                    getPurposeImages({
                      selectedAgent,
                      selectedCopilotPurpose,
                    }) || [""]
                  }
                  name={"General"}
                  variant="chat"
                />
              )}
              {shouldHavePreview() && (
                <SButton
                  onClick={() => setIsPreviewOpen(!isPreviewOpen)}
                  type="secondaryGray"
                  size="sm"
                  icon={isPreviewOpen ? faChevronRight : faChevronLeft}
                  data-tooltip-id="preview-button"
                  data-tooltip-content={
                    isPreviewOpen ? "Hide preview" : "Show preview"
                  }
                />
              )}
              <SButton
                type="secondaryGray"
                size="sm"
                icon={faEllipsisVertical}
                data-tooltip-id="settings-button"
                data-tooltip-content="Settings"
              />
              <Tooltip id="preview-button" place="bottom" />
            </div>
          </div>
          {selectedTab === 0 && (
            <>
              <div
                id="copilotPostBriefChat"
                className="w-full flex flex-col gap-6 h-full py-4 px-6 overflow-y-auto"
              >
                {(briefMessages.length === 0 ||
                  isLoading ||
                  isCopilotChatFetching ||
                  isCopilotSessionFetching ||
                  isPostFetching ||
                  isPending) && (
                  <>
                    <MessageLoader />
                  </>
                )}
                {!isLoading &&
                  !isPostFetching &&
                  !isCopilotChatFetching &&
                  !isCopilotSessionFetching &&
                  !isPending &&
                  briefMessages.map((item, i) => (
                    <>
                      {item.role === CopilotRole.TOOL && (
                        <ToolMessage msg={item} index={i} />
                      )}
                      {item.type ===
                        CopilotMessageType.ContentCalendarOutline &&
                        item.data && (
                          <CalendarContentOutline
                            item={item}
                            message={item.data}
                          />
                        )}
                      {item.type === CopilotMessageType.OneMonthPlan &&
                        item.data && (
                          <OneMonthPlanMessages
                            item={item}
                            message={item.data}
                          />
                        )}
                      {item.role === CopilotRole.ASSISTANT && (
                        <AIMessage
                          handleGetStory={handleGetStory}
                          messages={briefMessages}
                          index={i}
                          sendMessage={sendMessage}
                          msg={item}
                        />
                      )}
                      {item.role === CopilotRole.USER && (
                        <UserMessage index={i} msg={item} />
                      )}
                    </>
                  ))}
              </div>
            </>
          )}
          {selectedTab === 1 && (
            <div className="py-4 px-6 w-full h-full overflow-y-auto">
              <AgentSessions />
            </div>
          )}
          {selectedTab === 0 && (
            <AgentChatTextarea
              files={files}
              setFiles={setFiles}
              disable={disable}
              value={value}
              setValue={setValue}
              sendMessage={sendMessage}
              selectFile={selectFile}
              setExpandedSingleImageModal={setExpandedSingleImageModal}
              setExpandedSingleVideoModal={setExpandedSingleVideoModal}
              textareaRef={textareaRef}
            />
          )}
        </div>
      </div>
      <AnimatePresence>
        {isPreviewOpen && (
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.225, ease: "easeInOut" }}
            className={`border rounded-xl border-gray-200 z-[100] flex flex-col h-full fixed md:absolute top-0 w-full transition-[width] duration-200 ease-in-out ${selectedCopilotPurpose === CopilotPurpose.BRAND ? "md:w-[39.2%]" : "md:w-[34%]"} right-0 bg-white overflow-y-auto max-md:mt-[67px]`}
          >
            <PreviewHeader
              selectedCopilotPurpose={selectedCopilotPurpose}
              isSaving={isSaving}
              postName={currentPost?.name}
            />
            <PreviewContent
              selectedCopilotPurpose={selectedCopilotPurpose}
              handleBlur={handleBlur}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
