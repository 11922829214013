import { useEffect, useState } from "react";
import { useSpeechRecognition } from "react-speech-recognition";

export const useTextareaVoice = (setValue: (value: string) => void) => {
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const [lastState, setLastState] = useState("");

  useEffect(() => {
    if (listening) setValue(transcript);
  }, [transcript]);

  return { transcript, resetTranscript, listening, lastState, setLastState };
};
