import {
  faCalendar,
  faEdit,
  faGear,
  faImage,
  faMegaphone,
  faPaintbrush,
  faRefresh,
  faShareNodes,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faGear as faGearSolid,
  faShareNodes as faShareNodesSolid,
  faCalendar as faCalendarSolid,
  faPaintbrush as faPaintbrushSolid,
  faImage as faImageSolid,
  faMegaphone as faMegaphoneSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { AgentSidebarButton } from "./AgentSidebarButton";

import { useCreationStore } from "../../store/creationStore";
import { useContentStore } from "../../store/contentStore";
import { useSessionHelperFunctions } from "./hooks/useSessionHelperFunctions";
import { ISinglePostVM } from "../../utils/interfaces/IPost";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CopilotAgentIdentifier,
  CopilotAgentRole,
  CopilotPurpose,
} from "../../utils/enums/Enums";

import { getSelectedAgents } from "./functions/getSelectedAgents";
import { useDefaultSidebarValues } from "./hooks/useDefaultSidebarValues";
import { getQuery } from "../../hooks/functions/queries";
import { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { staggerSidebarItemsData } from "./utils/staggerSidebarItemsData";

export interface AgentSidebarProps {
  setSelectedChatId: (id: string) => void;
  selectedChatId: string | null;
  setDeleteConfirmation: (value: null | ISinglePostVM) => void;
}

export const AgentSidebar = ({
  setSelectedChatId,
  selectedChatId,
  setDeleteConfirmation,
}: AgentSidebarProps) => {
  const {
    selectedCopilotPurpose,
    setSelectedCopilotPurpose,
    setSelectedAgent,
    setSelectedTab,
  } = useCreationStore();
  const { hideIfNoBrand, currentOrganization } = useDefaultSidebarValues();
  const { handleCreateNewPost, isPending } = useSessionHelperFunctions();
  const navigate = useNavigate();
  const location = useLocation();
  const subsection = getQuery("subsection");

  useEffect(() => {
    const isSettingsPath =
      location.pathname.split("/")[1] === "notifications" ||
      location.pathname.split("/")[1] === "account" ||
      location.pathname.split("/")[1] === "billing" ||
      location.pathname.split("/")[1] === "workspace";

    if (subsection) {
      if (
        subsection === "notifications" ||
        subsection === "account" ||
        subsection === "billing"
      ) {
        setSelectedChatId("settings");
      }
    } else if (isSettingsPath) {
      setSelectedChatId("settings");
    }
  }, [subsection, location.pathname, setSelectedChatId]);

  const { container, item } = staggerSidebarItemsData();

  return (
    <div className="bg-base-white py-[16px] h-[calc(100dvh-89px)] border border-gray-200 w-[188px] max-md:w-full max-md:h-full max-md:mt-0 rounded-xl flex flex-col gap-4 mt-[12px]">
      <motion.div
        variants={container}
        initial="hidden"
        animate="visible"
        className="flex flex-col gap-4 h-full px-[12px] overflow-auto"
      >
        <motion.div variants={item} className="flex flex-col gap-1">
          <AnimatePresence>
            {currentOrganization?.data.id &&
              selectedCopilotPurpose === CopilotPurpose.BRANDCREATION && (
                <motion.div
                  variants={item}
                  className="flex flex-col pb-5"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0 }}
                  transition={{
                    duration: 0.1,
                    scale: {
                      type: "spring",
                      damping: 15,
                    },
                  }}
                >
                  <AgentSidebarButton
                    setSelectedChatId={setSelectedChatId}
                    selectedChatId={selectedChatId}
                    name="Create Brand"
                    hasHashtag={true}
                    chatId={"brand-temp"}
                  />
                </motion.div>
              )}
          </AnimatePresence>
          <motion.p
            variants={item}
            className="text-sm-b pb-1 text-gray-600 px-2"
          >
            Channels
          </motion.p>
          <AnimatePresence>
            {!hideIfNoBrand && (
              <motion.div variants={item}>
                <AgentSidebarButton
                  setSelectedChatId={setSelectedChatId}
                  selectedChatId={selectedChatId}
                  hidden={hideIfNoBrand}
                  name="General"
                  onClick={async () => {
                    setSelectedAgent(null);
                    setSelectedTab(0);
                    setSelectedCopilotPurpose(CopilotPurpose.POSTCREATION);
                  }}
                  hasHashtag={true}
                  chatId={"general"}
                  additionalButton={{
                    icon: isPending ? faRefresh : faEdit,
                    onClick: async (
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => {
                      e.stopPropagation();
                      setSelectedTab(0);
                      await handleCreateNewPost();
                      setSelectedChatId("general");
                    },
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <motion.div variants={item}>
            <AgentSidebarButton
              setSelectedChatId={setSelectedChatId}
              selectedChatId={selectedChatId}
              onClick={() => {
                setSelectedAgent(null);
                if (!currentOrganization) {
                  setSelectedCopilotPurpose(CopilotPurpose.BRANDCREATION);
                } else {
                  setSelectedCopilotPurpose(CopilotPurpose.BRAND);
                }
                setSelectedTab(0);
              }}
              name="Brand"
              hasHashtag={true}
              chatId={"brand"}
            />
          </motion.div>
        </motion.div>
        <motion.div variants={item} className="flex flex-col gap-1 pt-2">
          {!hideIfNoBrand && (
            <motion.p
              variants={item}
              className="text-sm-b pb-1 text-gray-600 px-2"
            >
              Messages
            </motion.p>
          )}
          <AnimatePresence>
            {!hideIfNoBrand && (
              <motion.div variants={item}>
                <AgentSidebarButton
                  setSelectedChatId={setSelectedChatId}
                  selectedChatId={selectedChatId}
                  hidden={hideIfNoBrand}
                  onClick={() => {
                    setSelectedAgent(CopilotAgentIdentifier.General);
                    setSelectedCopilotPurpose(CopilotPurpose.REGULAR);
                    setSelectedTab(0);
                  }}
                  name="Velin"
                  images={getSelectedAgents([CopilotAgentIdentifier.General])}
                  chatId={"velin"}
                  role={CopilotAgentRole[CopilotAgentIdentifier.General]}
                />
                <AgentSidebarButton
                  setSelectedChatId={setSelectedChatId}
                  selectedChatId={selectedChatId}
                  hidden={hideIfNoBrand}
                  name="Breni"
                  onClick={() => {
                    setSelectedAgent(CopilotAgentIdentifier.BrandStrategist);
                    setSelectedCopilotPurpose(CopilotPurpose.REGULAR);
                    setSelectedTab(0);
                  }}
                  images={getSelectedAgents([
                    CopilotAgentIdentifier.BrandStrategist,
                  ])}
                  chatId={"breni"}
                  role={
                    CopilotAgentRole[CopilotAgentIdentifier.BrandStrategist]
                  }
                />
                <AgentSidebarButton
                  setSelectedChatId={setSelectedChatId}
                  selectedChatId={selectedChatId}
                  hidden={hideIfNoBrand}
                  name="Copia"
                  onClick={() => {
                    setSelectedAgent(CopilotAgentIdentifier.Copywriter);
                    setSelectedCopilotPurpose(CopilotPurpose.REGULAR);
                    setSelectedTab(0);
                  }}
                  images={getSelectedAgents([
                    CopilotAgentIdentifier.Copywriter,
                  ])}
                  chatId={"copia"}
                  role={CopilotAgentRole[CopilotAgentIdentifier.Copywriter]}
                />
                <AgentSidebarButton
                  setSelectedChatId={setSelectedChatId}
                  selectedChatId={selectedChatId}
                  hidden={hideIfNoBrand}
                  name="Sivo"
                  onClick={() => {
                    setSelectedAgent(CopilotAgentIdentifier.GraphicDesigner);
                    setSelectedCopilotPurpose(CopilotPurpose.REGULAR);
                    setSelectedTab(0);
                  }}
                  images={getSelectedAgents([
                    CopilotAgentIdentifier.GraphicDesigner,
                  ])}
                  chatId={"sivo"}
                  role={
                    CopilotAgentRole[CopilotAgentIdentifier.GraphicDesigner]
                  }
                />
                <AgentSidebarButton
                  setSelectedChatId={setSelectedChatId}
                  selectedChatId={selectedChatId}
                  hidden={hideIfNoBrand}
                  name="Archie"
                  onClick={() => {
                    setSelectedAgent(CopilotAgentIdentifier.RAG);
                    setSelectedCopilotPurpose(CopilotPurpose.REGULAR);
                    setSelectedTab(0);
                  }}
                  images={getSelectedAgents([CopilotAgentIdentifier.RAG])}
                  chatId={"archie"}
                  role={CopilotAgentRole[CopilotAgentIdentifier.RAG]}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </motion.div>
      <motion.div variants={item} className="flex flex-col gap-1 px-[12px]">
        <AgentSidebarButton
          setSelectedChatId={setSelectedChatId}
          selectedChatId={selectedChatId}
          name="Brandbook"
          onClick={() => {
            navigate("/brand?subsection=brandbook");
            setSelectedTab(0);
          }}
          icon={
            selectedChatId === "brandbook" ? faPaintbrushSolid : faPaintbrush
          }
          chatId={"brandbook"}
        />
        <AgentSidebarButton
          setSelectedChatId={setSelectedChatId}
          selectedChatId={selectedChatId}
          name="Content"
          onClick={() => {
            navigate("/content/?subsection=all");
            setSelectedTab(0);
            setSelectedAgent(null);
            setSelectedCopilotPurpose(null);
          }}
          icon={selectedChatId === "content" ? faMegaphoneSolid : faMegaphone}
          chatId={"content"}
        />
        <AgentSidebarButton
          setSelectedChatId={setSelectedChatId}
          selectedChatId={selectedChatId}
          name="Calendar"
          onClick={() => {
            navigate("/calendar");
            setSelectedTab(0);
          }}
          icon={selectedChatId === "calendar" ? faCalendarSolid : faCalendar}
          chatId={"calendar"}
        />
        <AgentSidebarButton
          setSelectedChatId={setSelectedChatId}
          selectedChatId={selectedChatId}
          name="Assets"
          onClick={() => {
            navigate("/assets");
          }}
          icon={selectedChatId === "assets" ? faImageSolid : faImage}
          chatId={"assets"}
        />
        <AgentSidebarButton
          setSelectedChatId={setSelectedChatId}
          selectedChatId={selectedChatId}
          name="Settings"
          onClick={() => {
            navigate("/workspace");
            setSelectedTab(0);
          }}
          icon={selectedChatId === "settings" ? faGearSolid : faGear}
          chatId={"settings"}
        />
      </motion.div>
    </div>
  );
};
