import { useEffect, useState } from "react";
import { useContentStore } from "../../../store/contentStore";
import { useCreationHelperFunctions } from "../../creation/hooks/useCreationHelperFunctions";
import { useAccountStore } from "../../../store/accountStore";
import { useGetWorkspaceUsage } from "../../../api/workspaces/limits/get";
import { useSetDefaultSection } from "./useSetDefaultSection";
import { useCreationStore } from "../../../store/creationStore";
// import { useGetPostIdeas } from "../../../api/brand/postIdeas/get";
import { useGetWindowWidth } from "../../../hooks/useGetWindowWidth";
import { useSubscriptionActions } from "../../../hooks/useSubscriptionActions";
import { useNavigate } from "react-router-dom";

export const useContent = () => {
  const { currentPlan } = useSubscriptionActions();
  const { windowWidth } = useGetWindowWidth();
  const { handleOpenPost } = useCreationHelperFunctions();
  const { personalInfo } = useAccountStore();
  const { data: workspaceUsage } = useGetWorkspaceUsage();
  const { setIsPostOpen, setSelectedChatId } = useCreationStore();
  // const { data: postIdeas, isFetching: isPostIdeasFetching } =
  //   useGetPostIdeas();
  const [
    isWhichSocialNetworksSelectorOpen,
    setIsWhichSocialNetworksSelectorOpen,
  ] = useState(false);
  const navigate = useNavigate();

  const { setPosts, setPage, searchValue, setSelected, setSearchValue } =
    useContentStore();
  useSetDefaultSection({ setPage, setPosts, setSelected });

  useEffect(() => {
    setPosts([]);
    setPage(1);
  }, [searchValue]);

  return {
    currentPlan,
    windowWidth,
    handleOpenPost,
    personalInfo,
    workspaceUsage,
    setIsPostOpen,
    // postIdeas,
    // isPostIdeasFetching,
    isWhichSocialNetworksSelectorOpen,
    setIsWhichSocialNetworksSelectorOpen,
    searchValue,
    setSearchValue,
    navigate,
    setSelectedChatId,
  };
};
