import React, { useState } from "react";
import SBadge from "../../../../../../design-system/SBadge";
import {
  ImageGenerationAspectRatio,
  ImageGenerationStyle,
} from "../../../../../../utils/enums/Enums";
import { useHelperInputHelperFunctions } from "../useHelperInputHelperFunctions";
import { AspectRatioVisuals } from "./AspectRatioVisuals";
import { StylePreviewBadge } from "./StylePreviewBadge";

function GenerationActions({
  activeStyleTag,
  setActiveStyleTag,
  activeAspectRatio,
  setActiveAspectRatio,
}: {
  activeStyleTag: ImageGenerationStyle;
  setActiveStyleTag: React.Dispatch<React.SetStateAction<ImageGenerationStyle>>;
  activeAspectRatio: ImageGenerationAspectRatio;
  setActiveAspectRatio: React.Dispatch<
    React.SetStateAction<ImageGenerationAspectRatio>
  >;
}) {
  const {
    imageGenerationStyles,
    imageGenerationAspectRatios,
    getGenerationStyleNameById,
  } = useHelperInputHelperFunctions();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-4 overflow-x-auto px-2 pb-4 pt-4">
        {imageGenerationStyles.map((styleId) => (
          <StylePreviewBadge
            key={styleId}
            styleId={styleId}
            styleName={getGenerationStyleNameById(styleId)}
            isActive={activeStyleTag === styleId}
            onClick={() => {
              if (activeStyleTag === styleId) {
                setActiveStyleTag(ImageGenerationStyle.NONE);
              } else {
                setActiveStyleTag(styleId);
              }
            }}
          />
        ))}
      </div>
      <div className="flex flex-wrap gap-[8px]">
        {imageGenerationAspectRatios.map((aspectRatio) => (
          <SBadge
            hasBackground={false}
            onClick={() => {
              setActiveAspectRatio(aspectRatio);
            }}
            className={`${aspectRatio === activeAspectRatio && "bg-gray-600 border-gray-600  !text-base-white"} bg-gray-50 cursor-pointer`}
            size="lg"
            key={aspectRatio}
          >
            <AspectRatioVisuals
              aspectRatio={aspectRatio}
              activeAspectRatio={activeAspectRatio}
            />
            {aspectRatio}
          </SBadge>
        ))}
      </div>
    </div>
  );
}

export default GenerationActions;
