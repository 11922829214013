import { useEffect, useState } from "react";
import { useBrandStore } from "../../../store/brandStore";
import TextsSection, { IBrandSectionTextsProps } from "../TextsSection";
import { toast } from "../../../hooks/toast";
import { usePutBrand } from "../../../api/brand/put";
import _ from "lodash";
import {
  faBrainCircuit,
  faNotesMedical,
} from "@fortawesome/pro-regular-svg-icons";

export const Benefits = () => {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  const [functionalBenefits, setFunctionalBenefits] = useState<
    IBrandSectionTextsProps[]
  >([]);
  const [emotionalBenefits, setEmotionalBenefits] = useState<
    IBrandSectionTextsProps[]
  >([]);

  useEffect(() => {
    if (currentBrand?.brandingData?.benefits) {
      // Handle functional benefits
      let tmpFunctionalBenefits: IBrandSectionTextsProps[] = [];
      currentBrand.brandingData.benefits.functional.forEach((item, index) => {
        tmpFunctionalBenefits.push({
          isTitleEditable: false,
          key: `functional-benefit-${index}`,
          content: item,
          showBorder:
            index !== currentBrand.brandingData.benefits.functional.length - 1,
          handleChangeText: (text: string) => {
            if (
              currentBrand &&
              currentBrand.brandingData.benefits.functional[index] !== text
            ) {
              let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
              tmpBrandingData.benefits.functional[index] = text;
              updateBrand({
                brandId: currentBrand.id,
                brandingData: tmpBrandingData,
              })
                .then(() => {
                  toast("Functional benefit updated successfully", "success");
                })
                .catch((err) => {
                  toast(
                    `Failed to update functional benefit, ${err.response.data.message}`,
                    "error"
                  );
                });
            }
          },
        });
      });
      setFunctionalBenefits(tmpFunctionalBenefits);

      // Handle emotional benefits
      let tmpEmotionalBenefits: IBrandSectionTextsProps[] = [];
      currentBrand.brandingData.benefits.emotional.forEach((item, index) => {
        tmpEmotionalBenefits.push({
          isTitleEditable: false,
          key: `emotional-benefit-${index}`,
          content: item,
          showBorder:
            index !== currentBrand.brandingData.benefits.emotional.length - 1,
          handleChangeText: (text: string) => {
            if (
              currentBrand &&
              currentBrand.brandingData.benefits.emotional[index] !== text
            ) {
              let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
              tmpBrandingData.benefits.emotional[index] = text;
              updateBrand({
                brandId: currentBrand.id,
                brandingData: tmpBrandingData,
              })
                .then(() => {
                  toast("Emotional benefit updated successfully", "success");
                })
                .catch((err) => {
                  toast(
                    `Failed to update emotional benefit, ${err.response.data.message}`,
                    "error"
                  );
                });
            }
          },
        });
      });
      setEmotionalBenefits(tmpEmotionalBenefits);
    }
  }, [currentBrand]);

  return (
    <>
      <TextsSection
        fieldName="Functional Benefits"
        loadingCount={3}
        title="Functional Benefits"
        subtitle=""
        isLoading={!currentBrand?.brandingData?.benefits}
        texts={functionalBenefits}
      />
      <TextsSection
        fieldName="Emotional Benefits"
        loadingCount={3}
        title="Emotional Benefits"
        subtitle=""
        isLoading={!currentBrand?.brandingData?.benefits}
        texts={emotionalBenefits}
      />
    </>
  );
};
