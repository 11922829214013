import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { getSelectedAgents } from "./functions/getSelectedAgents";
import { GroupChatImages } from "./GroupChatImages";
import {
  CopilotAgentIdentifier,
  CopilotPurpose,
} from "../../utils/enums/Enums";
import { useCreationStore } from "../../store/creationStore";
import { useGetAllPosts } from "../../api/posts/getAll";
import { RecentsSkeleton } from "./RecentsSkeleton";
import { isVideo } from "../Library/hooks/getFileType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";

export const AgentSessions = () => {
  const { data: posts, isFetching } = useGetAllPosts({ page: 1, pageSize: 5 });
  const {
    setSelectedAgent,
    setSelectedCopilotPurpose,
    setPostId,
    setSelectedTab,
    postId: currentPostId,
    setIsPreviewOpen,
  } = useCreationStore();

  const getPostsLength = () => {
    if (!posts) return 0;
    if (posts.data.posts.length > 5) return 5;
    return posts.data.posts.length;
  };

  return (
    <div className="w-full h-fit border border-gray-200 bg-gray-50 rounded-xl flex flex-col">
      {!isFetching &&
        posts?.data.posts
          .map((post, index) => (
            <div
              onClick={() => {
                setSelectedAgent(null);
                setSelectedCopilotPurpose(CopilotPurpose.POSTCREATION);
                setPostId(post.id);
                setSelectedTab(0);
                if (
                  post.socialNetworkPosts[0]?.text ||
                  post.socialNetworkPosts[0]?.files?.length > 0
                ) {
                  setIsPreviewOpen(true);
                }
              }}
              className={`relative group w-full max-h-[88px] hover:bg-gray-100 transition-all cursor-pointer 
                ${index === 0 ? "rounded-t-xl" : ""} 
                ${index !== getPostsLength() - 1 ? "border-b border-gray-200" : "rounded-b-xl"} 
                ${currentPostId === post.id && "bg-gray-200"} 
                pl-4 pr-1 py-6 flex items-center justify-between max-md:flex-col max-md:max-h-fit max-md:gap-4`}
              key={post.id}
            >
              <div className="flex items-center gap-3">
                <GroupChatImages
                  images={getSelectedAgents([
                    CopilotAgentIdentifier.General,
                    CopilotAgentIdentifier.Copywriter,
                    CopilotAgentIdentifier.BrandStrategist,
                  ])}
                  variant="chat"
                  name={post.name}
                />
                <div>
                  <p className="text-gray-900 text-sm-sb">
                    {post.name ? post.name : "Creation Session"}
                  </p>
                  <p className="text-gray-600 text-sm">
                    {formatDistanceToNowStrict(
                      parseISO(post.created.toString())
                    )}{" "}
                    ago
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                {post.socialNetworkPosts[0].files.length > 0 && (
                  <div className="w-[100px] h-20 bg-gray-100 relative rounded-xl">
                    {post.socialNetworkPosts[0].files.length > 1 && (
                      <p className="text-base-white z-20 p-2 text-sm-m absolute left-0 top-0 flex items-center rounded-xl justify-center w-full h-full bg-opacity-40 bg-gray-900">
                        +{post.socialNetworkPosts[0].files.length - 1}
                      </p>
                    )}
                    {!isVideo(
                      post.socialNetworkPosts[0].files[0].url || ""
                    ) && (
                      <img
                        src={
                          post.socialNetworkPosts[0].files[0].thumbnailMediumUrl
                        }
                        alt="post thumbnail"
                        className="w-full h-full object-cover rounded-xl"
                      />
                    )}
                    {isVideo(post.socialNetworkPosts[0].files[0].url || "") && (
                      <video
                        src={post.socialNetworkPosts[0].files[0].url || ""}
                        className="w-full h-full object-cover rounded-xl"
                        controls={false}
                      >
                        <source src={post.socialNetworkPosts[0].files[0].url} />
                      </video>
                    )}
                    {isVideo(post.socialNetworkPosts[0].files[0].url || "") && (
                      <div className="absolute rounded-xl top-0 left-0 w-full h-full bg-gray-900 bg-opacity-40 flex items-center justify-center">
                        <FontAwesomeIcon
                          className="text-gray-100 opacity-50 text-2xl"
                          icon={faPlay}
                        />
                      </div>
                    )}
                  </div>
                )}
                {post.socialNetworkPosts[0].text && (
                  <div className="w-[170px] h-20 bg-gray-100 rounded-xl">
                    <p className="text-gray-700 p-2 text-xs-m">
                      {post.socialNetworkPosts[0].text
                        .split(" ")
                        .slice(0, 8)
                        .join(" ")}
                      ...
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))
          .splice(0, 5)}
      {!isFetching && getPostsLength() === 0 && (
        <div className="w-full h-full flex flex-col gap-2 py-4 items-center justify-center">
          <p className="text-gray-900 text-md-m">No sessions found</p>
          <p className="text-gray-500 text-sm-m">
            Chat with our agents to get started
          </p>
        </div>
      )}
      {isFetching && (
        <div className="w-full h-full flex flex-col gap-2 items-center justify-center">
          <RecentsSkeleton />
          <RecentsSkeleton />
          <RecentsSkeleton />
        </div>
      )}
    </div>
  );
};
