import { ICopilotMessage } from "../../../../utils/copilot-base/ICopilotBase";
import { AnimatePresence, motion } from "framer-motion";
import { useThoughtStatus } from "./hooks/useThoughtStatus";
import Markdown from "react-markdown";
import { useCreationStore } from "../../../../store/creationStore";
import SButton from "../../../../design-system/SButton";
import { faCheck, faCopy } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { getSelectedAgents } from "../../../newContent/functions/getSelectedAgents";
// @ts-ignore
import StoriLogo from "../../../../assets/images/logos/stori-s-only.svg";
import { LoadingAvatar } from "../../../newContent/LoadingAvatar";

function AIMessage({
  handleGetStory,
  index,
  msg,
  messages,
  sendMessage,
}: {
  messages: ICopilotMessage[];
  index: number;
  sendMessage: (t: string) => void;
  handleGetStory: (str: string) => void;
  msg: ICopilotMessage;
}) {
  const { thoughts } = useThoughtStatus(msg);
  const { briefMessages } = useCreationStore();
  const [isCopied, setIsCopied] = useState(false);
  const isLastMessage = index === briefMessages.length - 1;
  const { copilotAgentLoading } = useCreationStore();
  const socketImage = (): string | undefined => {
    if (copilotAgentLoading === null) return;
    return getSelectedAgents([copilotAgentLoading])?.[0];
  };
  return (
    <>
      <div
        className={`h-fit flex gap-[12px] relative max-md:gap-[4px] ${
          isLastMessage ? "mb-20" : ""
        }`}
      >
        {(msg.agentImage || socketImage()) && (
          <img
            src={msg.agentImage || socketImage()}
            alt={`${msg.agentName}-avatar`}
            className={`min-w-[40px] h-[40px] max-md:min-w-[30px] max-md:h-[30px] aspect-square object-cover rounded-full cursor-pointer`}
          />
        )}
        {!msg.agentImage && !socketImage() && (
          <LoadingAvatar
            children={
              <img
                src={StoriLogo}
                className="w-4 h-4 absolute left-1/2 -translate-x-1/2 -translate-y-[175%]"
                alt="Thinking"
              />
            }
          />
        )}
        <div className="flex flex-col gap-0.5">
          {thoughts === null && (
            <p className="text-xs-sb text-gray-500">
              {msg.agentName ||
                briefMessages[briefMessages.length - 3].agentName}
            </p>
          )}
          <div
            className={` flex flex-col justify-center items-start border rounded-[8px] py-[10px] rounded-tl-[0] max-w-[456px] relative group
            ${!msg.content && !msg.message ? "w-fit bg-gray-transparent border-transparent" : "bg-gray-50 border-gray-200 w-full px-[14px]"}`}
          >
            {thoughts === null && (msg.content || msg.message) && (
              <SButton
                onClick={(e) => {
                  const messageGroup = e.currentTarget.closest(".group");
                  const preElement =
                    messageGroup?.querySelector(".copilot-message");
                  const textToCopy = preElement?.textContent || "";
                  navigator.clipboard.writeText(textToCopy);
                  setIsCopied(true);
                  setTimeout(() => {
                    setIsCopied(false);
                  }, 1000);
                }}
                icon={isCopied ? faCheck : faCopy}
                type="secondaryGray"
                size="sm"
                className="absolute -right-12 bottom-auto opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              />
            )}
            {!msg.content && !msg.message ? (
              <div className="">
                <AnimatePresence mode="wait">
                  {thoughts !== null && (
                    <motion.p
                      key={thoughts}
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: 20, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className="text-md text-gray-600 bg-gradient-to-r from-gray-400 via-gray-300 to-gray-400 bg-[length:200%_100%] animate-[gradient_2s_linear_infinite] bg-clip-text text-transparent"
                    >
                      {thoughts}
                    </motion.p>
                  )}
                </AnimatePresence>
              </div>
            ) : (
              <>
                {msg.content &&
                  (msg.content.Type === "CopilotMessage" ||
                    msg.content?.Type === "TextVersions") &&
                  msg.content.Message.length > 0 && (
                    <div className="whitespace-pre-wrap break-words text-md text-gray-900 max-md:text-sm copilot-message">
                      <Markdown>{msg.content.Message.trim()}</Markdown>
                    </div>
                  )}
                {msg.message && !msg.content?.Message && (
                  <div className="whitespace-pre-wrap break-words text-md text-gray-900 max-md:text-sm copilot-message">
                    <Markdown>{msg.message.trim()}</Markdown>
                  </div>
                )}
                {/*{msg.content && msg.content.Type === "Brief" && (*/}
                {/*  <BriefSelector*/}
                {/*    handleGetStory={handleGetStory}*/}
                {/*    index={index}*/}
                {/*    messages={messages}*/}
                {/*    sendMessage={sendMessage}*/}
                {/*    content={msg.content}*/}
                {/*  />*/}
                {/*)}*/}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AIMessage;
