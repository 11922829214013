import PageHeading from "../PageHeading";
import { LibraryGridCard } from "./LibraryGridCard";
import { renderItems } from "./functions/renderItems";
import { useFolderHistory } from "./hooks/useFolderHistory";
import { LibrarySkeleton } from "./LibrarySkeleton";
import { useSelectableItems } from "../../hooks/useSelectableItems";
import { LibrarySelectNavbar } from "./LibrarySelectNavbar";
import { handleDoubleClick } from "./functions/handleDoubleClick";
import { useRef, useState } from "react";
import { useUploadAssets } from "./hooks/useUploadAssets";
import { LibraryCreateFolderModal } from "./LibraryCreateFolderModal";
import { LibraryLoadingFilesModal } from "./LibraryLoadingFilesModal";
import { LibraryLoadingFile } from "./LibraryLoadingFile";
import { LibraryDropdown } from "./LibraryDropdown";
// @ts-ignore
import DropboxChooser from "react-dropbox-chooser";
import { useLibraryDropbox } from "./hooks/useLibraryDropbox";
import { EmptyState } from "./EmptyState";
import SBreadcrumbs from "../../design-system/SBreadcrumbs";
import { toast } from "../../hooks/toast";
import ExpandedImageModal from "../utils/ExpandedImageModal";
import { useGridCardActions } from "./hooks/useGridCardActions";
import { ConfirmDelete } from "./ConfirmDelete";
import { LibraryAssetRelocation } from "./LibraryAssetRelocation";
import { faX } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HorizontalFolders } from "./HorizontalFolders";
import { FolderSkeletons } from "./FolderSekeletons";
import { handleSelectClick } from "./functions/handleSelectClick";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { deleteFileOrFolder } from "./functions/deleteFileOrFolder";
import { renameFileOrFolder } from "./functions/renameFileOrFolder";
import { useGoBack } from "./hooks/useGoBack";
import { useGetWindowWidth } from "../../hooks/useGetWindowWidth";
import { AssetTypeFilter } from "./filters/AssetTypeFilter";
import { AssetSourceFilter } from "./filters/AssetSourceFilter";
import { useLibraryStore } from "../../store/libraryStore";

export interface IExpandedImage {
  source: string;
  name: string;
  extension: string;
  pathInStorage: string;
  id: number;
}

export default function LibraryView() {
  const [searchValue, setSearchValue] = useState("");
  const { onGoBack } = useGoBack();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    folderHistory,
    foldersAndFiles,
    setPage,
    setFoldersAndFiles,
    setFolderHistory,
    setParentFolder,
    isFetching,
    parentFolderId,
    defaultFolderId,
    fileType,
    setFileType,
    setSource,
    source,
  } = useFolderHistory({ wrapperRef, searchValue });
  const [clickTimeout, setClickTimeout] = useState<number | null>(null);
  const items = renderItems(foldersAndFiles);

  const { selectFile, createFolder, isPending, files, createFile, setFiles } =
    useUploadAssets({
      parentFolderId,
    });
  const { uploadErrors } = useLibraryStore();
  const { selectedItems, handleSelectItem, setSelectedItems } =
    useSelectableItems(items);

  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");

  const { dropboxRef, onDropboxSelectSubmited, dropboxFiles, setDropboxFiles } =
    useLibraryDropbox({
      createFile,
      parentFolderId,
      isPending,
    });

  const {
    handleDownload,
    updateFile,
    deleteFile,
    deleteFolder,
    setOpenConfirmation,
    openConfirmation,
    handleDelete,
    setMoveIntoModal,
    moveIntoModal,
    handleMoveInto,
    handleMoveOut,
    openRename,
    setOpenRename,
    value,
    setValue,
    updateFolder,
    handleFolderDownload,
    handleRename,
    deleteAsset,
    renameAsset,
    moveAssets,
  } = useGridCardActions();
  const [expandedImage, setExpandedImage] = useState<IExpandedImage | null>(
    null
  );
  const [hideFolders, setHideFolders] = useState(false);
  const [hideFiles, setHideFiles] = useState(false);

  const hasFile =
    items.length > 0 && items.some((item) => item.extension !== undefined);
  const hasFolder =
    items.length > 0 && items.some((item) => item.extension === undefined);

  const { windowWidth } = useGetWindowWidth();
  const showEmptyState = () => {
    if (isFetching) return false;
    if (items.length === 0) return true;
    if (source.generatedOnly || source.uploadedOnly || fileType) {
      if (!items.find((item) => item.cardType !== "Folder")) return true;
    }
    return false;
  };
  return (
    <div className="w-full px-4 pt-4 h-fit pb-[50px] max-md:pb-[12px]">
      {openConfirmation && (
        <ConfirmDelete
          title="Are you sure you want to delete this asset?"
          setOpenModal={() => setOpenConfirmation(false)}
          openModal={openConfirmation}
          onCancel={() => setOpenConfirmation(false)}
          onConfirm={() =>
            deleteFileOrFolder({
              deleteAsset,
              deleteFolder,
              deleteFile,
              setOpenConfirmation,
              setExpandedImage,
            })
          }
        />
      )}
      {openRename && (
        <LibraryCreateFolderModal
          action="Rename"
          type={renameAsset?.type === "Folder" ? "Folder" : "File"}
          openModal={openRename}
          setOpenModal={() => setOpenRename(false)}
          name={value}
          setName={(e) => setValue(e.target!.value)}
          onSave={() =>
            renameFileOrFolder({
              renameAsset,
              updateFile,
              updateFolder,
              value,
              setOpenRename,
              setValue,
            })
          }
        />
      )}
      {moveIntoModal && (
        <LibraryAssetRelocation
          setMoveIntoModal={setMoveIntoModal}
          setOpenModal={setOpenModal}
          moveAssets={moveAssets}
          moveIntoModal={moveIntoModal}
          handleMoveOut={handleMoveOut}
          defaultFolderId={defaultFolderId}
        />
      )}
      <LibraryLoadingFilesModal
        setDropboxFiles={setDropboxFiles}
        setFiles={setFiles}
        isPending={isPending}
      >
        {files?.map((file) => {
          return (
            <LibraryLoadingFile
              url={URL.createObjectURL(file)}
              type={file.type}
              name={file.name}
              size={file.size}
              isPending={isPending}
              isSuccess={uploadErrors === null}
            />
          );
        })}
        {dropboxFiles?.map((file: any) => {
          return (
            <LibraryLoadingFile
              url={file.thumbnailLink}
              type="image/jpeg"
              icon={file.icon}
              name={file.name}
              size={file.bytes}
              isPending={isPending}
              isSuccess={uploadErrors === null}
            />
          );
        })}
      </LibraryLoadingFilesModal>
      {expandedImage && (
        <ExpandedImageModal
          isOpen={!!expandedImage}
          onClose={setExpandedImage}
          expandedImage={expandedImage}
          source={expandedImage.source}
          shouldDelete={true}
        />
      )}
      {openModal && (
        <LibraryCreateFolderModal
          openModal={openModal}
          action="Create"
          type="Folder"
          setOpenModal={() => setOpenModal(false)}
          name={name}
          setName={(e) => setName(e.target!.value)}
          onSave={() => {
            createFolder({ name: name, parentFolderId })
              .then(() => {
                setOpenModal(false);
                setName("");
                toast("Folder created successfully", "success");
              })
              .catch((err) => {
                toast(err.response.data.message, "error");
              });
          }}
        />
      )}
      <PageHeading
        title="Brand Assets"
        subtitle={"Upload and manage your marketing materials."}
        withInput={true}
        inputProps={{
          placeholder: "Search...",
          value: searchValue,
          onChange: (e) => {
            setSearchValue(e.target.value);
            setFoldersAndFiles([]);
            setPage(1);
          },
          rightAdditionalContent: (
            <FontAwesomeIcon
              className={`${searchValue.length > 0 ? "block" : "hidden"}`}
              onClick={() => setSearchValue("")}
              size="xs"
              icon={faX}
            />
          ),
        }}
        rightAdditionalContent={
          <>
            <LibraryDropdown
              handleFiles={() => selectFile({ multiple: true })}
              handleFolder={() => setOpenModal(true)}
              handleDropbox={() => dropboxRef.current?.click()}
            />
            <DropboxChooser
              appKey="js3i72s02ssirnl"
              success={(files: any) => {
                onDropboxSelectSubmited(files);
              }}
              extensions={[
                ".jpg",
                ".jpeg",
                ".png",
                ".mp4",
                ".mov",
                ".avi",
                ".wmv",
                ".flv",
                ".webm",
                ".pdf",
                ".docx",
                ".doc",
                ".xlsx",
                ".xls",
                ".txt",
              ]}
              multiselect={true}
            >
              <input ref={dropboxRef} className="hidden" />
            </DropboxChooser>
          </>
        }
      />
      <div className="flex w-full bg-gray-25 mt-[20px]">
        <SBreadcrumbs
          needHome={false}
          needGoBack={folderHistory.length >= 2}
          onGoBack={onGoBack}
          pages={folderHistory}
        />
      </div>
      <div className="w-full my-[16px]">
        <LibrarySelectNavbar
          className={`${selectedItems.length >= 1 ? "opacity-100" : "opacity-0 pointer-events-none absolute"} transition-all`}
          setSelectedItems={setSelectedItems}
          selectedItems={selectedItems}
          handleMoveInto={handleMoveInto}
        />
        <div
          className={`flex gap-3 items-center justify-start w-full ${selectedItems.length === 0 ? "opacity-100" : "opacity-0 pointer-events-none absolute"} transition-all py-[1px]`}
        >
          {selectedItems.length === 0 && (
            <>
              <AssetTypeFilter fileType={fileType} setFileType={setFileType} />
              <AssetSourceFilter source={source} setSource={setSource} />
            </>
          )}
        </div>
      </div>
      <div>
        <div
          ref={wrapperRef}
          className={`w-full max-md:h-[calc(100vh-230px)] ${folderHistory.length >= 2 ? "h-[calc(100vh-273.5px)]" : "h-[calc(100vh-258px)]"} overflow-y-auto bg-white rounded-xl border border-gray-200 px-4 pb-4`}
        >
          <div
            onClick={() => {
              setHideFolders((val) => !val);
              setSelectedItems([]);
            }}
            className="flex w-fit items-center gap-2 pb-3 mt-5"
          >
            <h1 className="text-md-m select-none cursor-default w-fit text-gray-900">
              {hasFolder && "Folders"}
            </h1>
            {hasFolder && (
              <FontAwesomeIcon
                className={`${hideFolders ? "-rotate-90" : "rotate-0"} transition-all text-gray-900 text-sm`}
                icon={faChevronDown}
              />
            )}
          </div>
          {items.find((item) => item.cardType === "Folder") && (
            <div className="grid grid-cols-1 md2:grid-cols-[repeat(auto-fill,_minmax(275px,_1fr))] gap-4 max-md:pb-[5px] mb-5">
              {items.length > 0 &&
                !hideFolders &&
                items.map(
                  (item, index) =>
                    item.cardType === "Folder" && (
                      <HorizontalFolders
                        handleMoveInto={handleMoveInto}
                        handleDelete={handleDelete}
                        windowWidth={windowWidth}
                        atLeastOneSelected={selectedItems.length >= 1}
                        handleDownload={handleDownload}
                        handleFolderDownload={handleFolderDownload}
                        handleMoveOut={handleMoveOut}
                        handleRename={handleRename}
                        rawTitle={item.rawTitle}
                        setOpenConfirmation={setOpenConfirmation}
                        parentFolderId={
                          folderHistory.length > 2
                            ? folderHistory[folderHistory.length - 2].id
                            : defaultFolderId
                        }
                        isRoot={folderHistory.length === 1}
                        setPage={setPage}
                        setFoldersAndFiles={setFoldersAndFiles}
                        isSelected={selectedItems.some(
                          (selectedItem) =>
                            selectedItem.id === item.id &&
                            selectedItem.isFolder === !item.extension
                        )}
                        filesCount={item.filesCount}
                        onClick={(e) =>
                          handleSelectClick({
                            e,
                            handleSelectItem,
                            item,
                            selectedItems,
                            items,
                            folders: true,
                          })
                        }
                        onDoubleClick={() => {
                          handleDoubleClick({
                            pathInStorage: item.pathInStorage,
                            setFoldersAndFiles,
                            setParentFolder,
                            setFolderHistory,
                            setPage,
                            clickTimeout,
                            setClickTimeout,
                            setSelectedItems,
                            cardTitle: item.cardTitle,
                            cardType: item.cardType,
                            id: item.id,
                            extension: item.extension,
                            setExpandedImage: setExpandedImage,
                            imageSrc: item.imageSrc,
                          });
                        }}
                        key={index}
                        {...item}
                      />
                    )
                )}
            </div>
          )}
          {isFetching && !hideFolders && (
            <div className="grid grid-cols-1 md2:grid-cols-[repeat(auto-fill,_minmax(275px,_1fr))] gap-4 max-md:pb-[5px] mb-5">
              <FolderSkeletons />
              <FolderSkeletons />
              <FolderSkeletons />
              <FolderSkeletons />
            </div>
          )}
          <div
            onClick={() => {
              setHideFiles((val) => !val);
              setSelectedItems([]);
            }}
            className="flex w-fit items-center gap-2 pb-3"
          >
            <h1 className="text-md-m cursor-default select-none w-fit text-gray-900">
              {hasFile && "Files"}
            </h1>
            {hasFile && (
              <FontAwesomeIcon
                className={`${hideFiles ? "-rotate-90" : "rotate-0"} transition-all text-gray-900 text-sm`}
                icon={faChevronDown}
              />
            )}
          </div>
          <div className="grid grid-cols-1 md2:grid-cols-[repeat(auto-fill,_minmax(275px,_1fr))] gap-4 max-md:pb-[50px]">
            {items.length > 0 &&
              !hideFiles &&
              items.map(
                (item, index: number) =>
                  item.cardType !== "Folder" && (
                    <LibraryGridCard
                      handleMoveInto={handleMoveInto}
                      handleDelete={handleDelete}
                      windowWidth={windowWidth}
                      handleDownload={handleDownload}
                      handleFolderDownload={handleFolderDownload}
                      handleMoveOut={handleMoveOut}
                      atLeastOneSelected={selectedItems.length >= 1}
                      handleRename={handleRename}
                      rawTitle={item.rawTitle}
                      parentFolderId={
                        folderHistory.length > 2
                          ? folderHistory[folderHistory.length - 2].id
                          : defaultFolderId
                      }
                      isRoot={folderHistory.length === 1}
                      setOpenConfirmation={setOpenConfirmation}
                      isSelected={selectedItems.some(
                        (selectedItem) =>
                          selectedItem.id === item.id &&
                          selectedItem.isFolder === !item.extension
                      )}
                      filesCount={item.filesCount}
                      onClick={(e) =>
                        handleSelectClick({
                          e,
                          handleSelectItem,
                          item,
                          selectedItems,
                          items,
                        })
                      }
                      onDoubleClick={() => {
                        handleDoubleClick({
                          pathInStorage: item.pathInStorage,
                          setPage,
                          setFoldersAndFiles,
                          clickTimeout,
                          setClickTimeout,
                          setSelectedItems,
                          cardTitle: item.cardTitle,
                          cardType: item.cardType,
                          setFolderHistory,
                          setParentFolder,
                          id: item.id,
                          extension: item.extension,
                          setExpandedImage: setExpandedImage,
                          imageSrc: item.imageSrc,
                        });
                      }}
                      key={index}
                      {...item}
                    />
                  )
              )}
            {isFetching && !hideFiles && (
              <>
                <LibrarySkeleton />
                <LibrarySkeleton />
                <LibrarySkeleton />
                <LibrarySkeleton />
              </>
            )}
          </div>

          {showEmptyState() && (
            <EmptyState
              searchValue={searchValue}
              className="pb-[150px] relative max-sm:mt-[10px] md:relative"
              title="No Assets Found"
              subtitle="Change folders or create one"
              actionButton={
                <LibraryDropdown
                  handleFiles={() => selectFile({ multiple: true })}
                  handleFolder={() => setOpenModal(true)}
                  handleDropbox={() => dropboxRef.current?.click()}
                  className="translate-x-[50%]"
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
