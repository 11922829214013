import {
  faChevronRight,
  faComments,
  faFolderPlus,
  faShare,
} from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../../../design-system/SButton";
import { useCreationStore } from "../../../../store/creationStore";
import { CommentsButton } from "../comments/CommentsButton";
import CommentsModal from "../../../creation/CommentsModal";

export const PreviewActions = () => {
  const { setIsScheduleModalOpen, isCommentsModalOpen } = useCreationStore();
  return (
    <div className="flex flex-col gap-4 relative ">
      {isCommentsModalOpen && <CommentsModal />}
      <CommentsButton />
      <div className="w-full h-[95x] rounded-xl flex items-center justify-between">
        <SButton type="secondaryGray" size="sm" lIcon={faFolderPlus}>
          Add to campaign
        </SButton>
        <SButton
          onClick={() => setIsScheduleModalOpen(true)}
          type="secondaryGray"
          size="sm"
          lIcon={faShare}
        >
          Share
        </SButton>
      </div>
    </div>
  );
};
