import { useEffect, useState } from "react";
import { useBrandStore } from "../../../store/brandStore";
import TextsSection, { IBrandSectionTextsProps } from "../TextsSection";
import { toast } from "../../../hooks/toast";
import { usePutBrand } from "../../../api/brand/put";
import _ from "lodash";
import { faFaceFrown } from "@fortawesome/pro-regular-svg-icons";

export const Frustrations = () => {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  const [frustrations, setFrustrations] = useState<IBrandSectionTextsProps[]>(
    []
  );

  useEffect(() => {
    if (
      currentBrand?.brandingData?.frustrations &&
      currentBrand.brandingData.frustrations.length > 0
    ) {
      let tmpFrustrations: IBrandSectionTextsProps[] = [];
      currentBrand.brandingData.frustrations.forEach((item, index) => {
        tmpFrustrations.push({
          isTitleEditable: false,
          key: `frustration-${index}`,
          content: item,
          showBorder:
            index !== currentBrand.brandingData.frustrations.length - 1,
          handleChangeText: (text: string) => {
            if (
              currentBrand &&
              currentBrand.brandingData.frustrations[index] !== text
            ) {
              let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
              tmpBrandingData.frustrations[index] = text;
              updateBrand({
                brandId: currentBrand.id,
                brandingData: tmpBrandingData,
              })
                .then(() => {
                  toast("Frustration updated successfully", "success");
                })
                .catch((err) => {
                  toast(
                    `Failed to update frustration, ${err.response.data.message}`,
                    "error"
                  );
                });
            }
          },
        });
      });
      setFrustrations(tmpFrustrations);
    }
  }, [currentBrand]);

  return (
    <TextsSection
      fieldName="Frustrations"
      loadingCount={3}
      title="Frustrations"
      subtitle=""
      isLoading={!currentBrand?.brandingData?.frustrations}
      texts={frustrations}
    />
  );
};
