import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ICopilotSessionVM } from "../../../utils/interfaces/ICopilot";
import { CopilotPurpose, CopilotType } from "../../../utils/enums/Enums";

export const usePostCopilotSession = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<ICopilotSessionVM>,
    any,
    { purposeType: CopilotPurpose; postId?: number; type?: CopilotType }
  >({
    mutationFn: ({
      purposeType = CopilotPurpose.POSTCREATION,
      postId,
      type,
    }) => {
      let url = `/Copilot?purposeType=${purposeType}`;
      if (postId !== undefined) {
        url += `&postId=${postId}`;
      }
      if (type !== undefined) {
        url += `&type=${type}`;
      }
      return makeRequest.post(url);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["useGetCopilotSessions"] });
    },
  });
};
