import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Signin from "./pages/Signin";
import Dashboard from "./pages/Dashboard";
import PublicBrand from "./pages/PublicBrand";
import ProtectedRoute from "./components/navigation/ProtectedRouter";
import PublicRouter from "./components/navigation/PublicRouter";
import Signup from "./pages/Signup";
import InvitationRedirectPage from "./pages/InvitationRedirectPage";
import ForgotPassword from "./pages/ForgotPassword";
import ForgottedPasswordReset from "./pages/ForgottedPasswordReset";
import Content from "./pages/Content";
import Notifications from "./pages/Notifications";
import Account from "./pages/Account";
import Billing from "./pages/Billing";
import Library from "./pages/Library";
import UpdatePlan from "./pages/UpdatePlan";
import Brand from "./pages/brand/Brand";
import Calendar from "./pages/Calendar";
import SidebarWrapper from "./components/newSidebar/SidebarWrapper";
import Workspace from "./pages/Workspace";
import ErrorBoundary from "./components/navigation/ErrorBoundary";
import GoogleExternalSignInCallback from "./pages/GoogleExternalSignInCallback";
import CreateBrand from "./pages/CreateBrand";
import { useGetUser } from "./hooks/useUserInfo";
import Creation from "./pages/Creation";
import { useCreationStore } from "./store/creationStore";
import { useQueryClient } from "@tanstack/react-query";
import { useContentStore } from "./store/contentStore";
import { useCopilotStore } from "./store/copilotStore";
import CopilotModal from "./pages/CopilotModal";
import NeedBrandPageProvider from "./pages/NeedBrandPageProvider";
import { GlobalInviteRedirectPage } from "./pages/GlobalInviteRedirectPage";
import NotificationProvider from "./components/notifications/NotificationProvider";
import Integrations from "./pages/Integrations";
import EmailConfirm from "./pages/EmailConfirm";
import UpdateEmailConfirm from "./pages/UpdateEmailConfirm";
import SetupIntentSuccessLoadingPage from "./pages/SetupIntentSuccessLoadingPage";
import CanvaSignin from "./pages/CanvaSignIn";
import CanvaRedirect from "./pages/CanvaRedirect";
import TermsConfirmation from "./pages/TermsConfirmation";
import posthog from "posthog-js";
import { useLibraryStore } from "./store/libraryStore";
import useGetCat from "./hooks/useGetCat";
import { getQuery } from "./hooks/functions/queries";
import WhichSocialNetworksSelector from "./components/utils/WhichSocialNetworksSelector";
import { useBrandStore } from "./store/brandStore";
import NewContent from "./pages/NewContent";
import { useOrganizationSockets } from "./components/brand/hooks/useOrganizationSockets";
import { Chats } from "./pages/Chats";

declare global {
  interface Window {
    clarity?: (command: string, ...args: any[]) => void;
    _hsq?: any[];
    Canny?: (command: string, data: any) => void;
  }
}

function App() {
  const { data: userInfo } = useGetUser();
  const { isCopilotOpen } = useCopilotStore();


  useEffect(() => {
    if (userInfo) {
      const { email, id, name, photoUrl } = userInfo.data.data;
      if (window.clarity) {
        window.clarity("identify", email);
      }
      if (window._hsq) {
        window._hsq.push(["identify", { email, id }]);
      }
      if (window.Canny) {
        window.Canny("identify", {
          appID: "656781e41d8425f53089f8a6",
          user: { email, name, id, avatarURL: photoUrl },
        });
      }
      if (
        posthog &&
        !window.location.host.includes("localhost") &&
        !window.location.host.includes("develop.storiai.com")
      ) {
        // @ts-ignore
        posthog.init(process.env.REACT_APP_POSTHOG, {
          api_host: "https://us.i.posthog.com",
          person_profiles: "identified_only",
        });
        posthog.setPersonProperties(
          { email, id, name, photoUrl },
          { initial_url: "/" }
        );
      }
    }
  }, [userInfo]);

  const [
    isWhichSocialNetworksSelectorOpen,
    setIsWhichSocialNetworksSelectorOpen,
  ] = useState(false);
  useGetCat({ setIsWhichSocialNetworksSelectorOpen });

  useOrganizationSockets();

  return (
    <>
      {isWhichSocialNetworksSelectorOpen && (
        <WhichSocialNetworksSelector
          saveAllOnClose={true}
          isOpen={isWhichSocialNetworksSelectorOpen}
          onClose={() => setIsWhichSocialNetworksSelectorOpen(false)}
        />
      )}
      {isCopilotOpen && <CopilotModal />}
      {/* {isPostOpen && (
        <Creation
          onClose={() => {
            setPosts([]);
            setPage(1);
            setFoldersAndFiles([]);
            setLibraryPage(1);
            setSelectedItems([]);
            queryClient
              .invalidateQueries({ queryKey: ["useGetAllCreation"] })
              .then((res) => {
                setIsPostOpen(false);
              });
            queryClient.invalidateQueries({ queryKey: ["useGetCalendarData"] });
          }}
          isOpen={isPostOpen}
        />
      )} */}
      <Routes>
        {/* <Route
          path="/"
          element={
            <ProtectedRoute>
              <SidebarWrapper>
                <NotificationProvider>
                  <Dashboard />
                </NotificationProvider>
              </SidebarWrapper>
            </ProtectedRoute>
          }
        /> */}

        <Route
          path="/external-signin-callback"
          element={
            <PublicRouter>
              <ErrorBoundary>
                <GoogleExternalSignInCallback />
              </ErrorBoundary>
            </PublicRouter>
          }
        />
        <Route
          path="/brand"
          element={
            <ProtectedRoute>
              <SidebarWrapper>
                <NeedBrandPageProvider page="brand">
                  <Brand />
                </NeedBrandPageProvider>
              </SidebarWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/integrations"
          element={
            <ProtectedRoute>
              <SidebarWrapper>
                <Integrations />
              </SidebarWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/workspace"
          element={
            <ProtectedRoute>
              <SidebarWrapper>
                <Workspace />
              </SidebarWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-brand"
          element={
            <ProtectedRoute>
              <SidebarWrapper>
                <CreateBrand />
              </SidebarWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/assets"
          element={
            <ProtectedRoute>
              <SidebarWrapper>
                <NeedBrandPageProvider page="assets">
                  <Library />
                </NeedBrandPageProvider>
              </SidebarWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SidebarWrapper>
                <Chats />
              </SidebarWrapper>
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/hub"
          element={
            <ProtectedRoute>
              <SidebarWrapper>
                <NeedBrandPageProvider page="content">
                  <NewContent />
                </NeedBrandPageProvider>
              </SidebarWrapper>
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/content"
          element={
            <ProtectedRoute>
              <SidebarWrapper>
                <NewContent />
              </SidebarWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/calendar"
          element={
            <ProtectedRoute>
              <SidebarWrapper>
                <NeedBrandPageProvider page="calendar">
                  <Calendar />
                </NeedBrandPageProvider>
              </SidebarWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute>
              <SidebarWrapper>
                <Account />
              </SidebarWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/SetupIntentSuccess"
          element={
            <ProtectedRoute>
              <SetupIntentSuccessLoadingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <ProtectedRoute>
              <SidebarWrapper>
                <Notifications />
              </SidebarWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/update-plan"
          element={
            <ProtectedRoute>
              <SidebarWrapper>
                <UpdatePlan />
              </SidebarWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/public-brand/:id"
          element={
            <PublicRouter isRestricted={false}>
              <PublicBrand />
            </PublicRouter>
          }
        />
        <Route
          path="/terms-of-service-confirmation"
          element={
            <PublicRouter>
              <TermsConfirmation />
            </PublicRouter>
          }
        />
        <Route
          path="/canva-login"
          element={
            <PublicRouter>
              <CanvaSignin />
            </PublicRouter>
          }
        />
        <Route
          path="/canva-redirect"
          element={
            <PublicRouter isRestricted={false}>
              <CanvaRedirect />
            </PublicRouter>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRouter>
              <Signin />
            </PublicRouter>
          }
        />
        <Route
          path="/signup"
          element={<Navigate to={`/login${window.location.search}`} replace />}
        />
        <Route
          path="/invite"
          element={
            <PublicRouter isRestricted={false}>
              <InvitationRedirectPage />
            </PublicRouter>
          }
        />
        <Route
          path="/globalInvite"
          element={
            <PublicRouter isRestricted={false}>
              <GlobalInviteRedirectPage />
            </PublicRouter>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRouter>
              <ForgotPassword />
            </PublicRouter>
          }
        />
        <Route
          path="/forgot-password-confirmation"
          element={
            <PublicRouter>
              <ForgottedPasswordReset />
            </PublicRouter>
          }
        />
        <Route
          path="/user/confirmEmail"
          element={
            <ProtectedRoute>
              <UpdateEmailConfirm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/confirm-email"
          element={
            <ProtectedRoute>
              <EmailConfirm />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
