import { useGetWorkspaceUsage } from "../../../../api/workspaces/limits/get";
import SButton from "../../../../design-system/SButton";
import { useAccountStore } from "../../../../store/accountStore";
import { OrganizationUserRole } from "../../../../utils/enums/Enums";
import { ISinglePostVM } from "../../../../utils/interfaces/IPost";
import { EmptyState } from "../../../Library/EmptyState";
import ContentSkeleton from "../../../Skeletons/ContentSkeleton";
import { ViewerRestrictTooltip } from "../../../utils/ViewerRestrictTooltip";
import NewPostCard from "../../NewPostCard";

function PreviewPosts({
  posts,
  isLoading,
  handleOpenPost,
  handleCreateNew,
  searchValue,
  totalCount,
  selected,
}: {
  handleOpenPost: (p: ISinglePostVM) => void;
  isLoading: boolean;
  posts: ISinglePostVM[];
  handleCreateNew: () => void;
  searchValue: string;
  totalCount: number;
  selected?: number;
}) {
  const { personalInfo } = useAccountStore();
  const { data: workspaceUsage } = useGetWorkspaceUsage();
  return (
    <>
      {posts.length === 0 &&
        (searchValue.length !== 0 || totalCount > 0) &&
        !isLoading && (
          <div className="relative h-full flex justify-center items-end">
            <EmptyState
              className="!md:absolute md:!translate-y-[30%] md:!top-[30%]"
              title={"No Posts Found "}
              subtitle={"Create post and get started"}
              searchValue={searchValue}
              actionButton={
                <SButton
                  className="min-w-[104px]"
                  onClick={() => {
                    handleCreateNew();
                  }}
                  disabled={
                    personalInfo?.userRole === OrganizationUserRole.VIEWER
                  }
                >
                  Create new
                  <ViewerRestrictTooltip />
                </SButton>
              }
            />
          </div>
        )}
      <div className={`mb-[40px]`}>
        {posts.length > 0 && (
          <div className="grid grid-cols-1 grid-rows-1 gap-4">
            {posts.map((item, i) => (
              <NewPostCard post={item} key={i} />
            ))}
          </div>
        )}
        {isLoading && (
          <>
            <ContentSkeleton />
          </>
        )}
      </div>
    </>
  );
}

export default PreviewPosts;
