import { Dispatch, SetStateAction } from "react";
import SModalBase from "../../../design-system/SModalBase";
import { IBrandTaskVM } from "../../../utils/interfaces/IBrand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullseye,
  faCalendar,
  faTableLayout,
  faTasks,
} from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import SBadge from "../../../design-system/SBadge";

export const BrandTaskModal = ({
  isOpen,
  onClose,
  brandTask,
}: {
  isOpen: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  brandTask: IBrandTaskVM | null;
}) => {
  return (
    <SModalBase
      dialogPanelClassName="max-w-[800px]"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="w-full flex flex-col gap-4">
        <div className="flex justify-start gap-2 items-center">
          <FontAwesomeIcon
            className="text-brand-400 text-2xl"
            icon={faCalendar}
          />
          <p className="text-xl-sb text-gray-900">
            Week {brandTask?.weekNumber} Planning
          </p>
        </div>
        <div className="flex flex-col gap-4 w-full bg-gray-50 shadow-lg rounded-lg p-4">
          <div className="flex items-center gap-2">
            <FontAwesomeIcon
              className="text-brand-400 text-xl"
              icon={faTableLayout}
            />
            <p className="text-lg-m text-gray-800">Content to Create</p>
          </div>
          <div className="grid h-[200px] grid-cols-3 gap-4">
            {brandTask?.contentToCreate.map((content) => (
              <div className="flex h-full border border-gray-200 rounded-lg p-4 flex-col gap-2">
                <p className="text-sm-m text-gray-700">
                  {content.contentTitle}
                </p>
                <p className="text-sm text-gray-600">
                  {content.descriptionMemo}
                </p>
                <SBadge
                  size="sm"
                  color="violet"
                  className="rounded-lg mt-auto text-sm"
                >
                  {content.recommendedPlatform}
                </SBadge>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full flex items-start justify-center gap-4">
          <div className="flex flex-col gap-4 w-1/2 bg-gray-50 shadow-lg rounded-lg p-4">
            <div className="flex items-center gap-2">
              <FontAwesomeIcon
                className="text-brand-400 text-xl"
                icon={faBullseye}
              />
              <p className="text-lg-m text-gray-800">Objectives</p>
            </div>
            {brandTask?.objectives.map((objective, index) => (
              <div className="flex items-start gap-2">
                <FontAwesomeIcon
                  className="text-brand-400 translate-y-[5px] text-[8px]"
                  icon={faCircle}
                />
                <p className="text-sm text-gray-600">
                  {typeof objective === "string"
                    ? objective
                    : objective.description}
                </p>
              </div>
            ))}
          </div>
          <div className="w-1/2 flex flex-col gap-4 bg-gray-50 shadow-lg rounded-lg p-4">
            <div className="flex items-center gap-2">
              <FontAwesomeIcon
                className="text-brand-400 text-xl"
                icon={faTasks}
              />
              <p className="text-lg-m text-gray-800">Key Tasks</p>
            </div>
            {brandTask?.keyTasks.map((keyTask, index) => (
              <div className="flex items-start gap-2">
                <FontAwesomeIcon
                  className="text-brand-400 translate-y-[5px] text-[8px]"
                  icon={faCircle}
                />
                <p className="text-sm text-gray-600">
                  {typeof keyTask === "string" ? keyTask : keyTask.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </SModalBase>
  );
};
