"use client";

import React, { useRef, useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { faGear as faSolidGear, faX } from "@fortawesome/pro-solid-svg-icons";
import { faGear } from "@fortawesome/pro-regular-svg-icons";
import BrandSwitcher from "../newSidebar/BrandSwitcher";
import Navigation from "../newSidebar/Navigation";
// @ts-ignore
import logo from "../../assets/images/logos/stori-full.svg";
import SButton from "../../design-system/SButton";
import { useNavigate } from "react-router-dom";
import { useSubscriptionActions } from "../../hooks/useSubscriptionActions";
import { useGetCurrentPackage } from "../../api/subscriptions/current/get";
import { BillingInfo } from "../billing/BillingInfo";
import { TeamPackageStatus } from "../../utils/enums/Enums";
import NavigationGroupItem from "../newSidebar/NavigationGroupItem";
import { AgentSidebar } from "../newContent/AgentSidebar";
import { useCreationStore } from "../../store/creationStore";

export default function MobileDrawer({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
}: {
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (open: boolean) => void;
}) {
  const { data: currentPlan } = useGetCurrentPackage();
  const ref = useRef<HTMLDivElement>(null);
  const [isBrandSelectorOpen, setIsBrandSelectorOpen] = useState(false);
  const {
    selectedChatId,
    setSelectedChatId,
    setDeleteConfirmation,
    setIsPreviewOpen,
  } = useCreationStore();
  const navigate = useNavigate();

  return (
    <Dialog
      open={isMobileMenuOpen}
      onClose={setIsMobileMenuOpen}
      className="relative z-[9999]"
    >
      <DialogBackdrop
        transition
        onClick={() => setIsMobileMenuOpen(false)}
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />
      <div ref={ref} className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0">
          <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
            <DialogPanel
              transition
              className="pointer-events-auto w-[280px] transform transition duration-500 ease-in-out data-[closed]:-translate-x-full"
            >
              <div className="flex h-full flex-col bg-white shadow-xl">
                <div className="px-4 py-4 border-b border-gray-200">
                  <div className="flex items-center justify-between">
                    <img
                      src={logo}
                      alt="logo"
                      onClick={() => {
                        navigate("/");
                        setIsMobileMenuOpen(false);
                        setIsPreviewOpen(false);
                      }}
                      className="h-6 cursor-pointer"
                    />
                    <SButton
                      icon={faX}
                      type="secondaryGray"
                      onClick={() => setIsMobileMenuOpen(false)}
                    />
                  </div>
                </div>

                <div className="flex-1 overflow-y-auto">
                  <div className="px-4 py-2">
                    <BrandSwitcher
                      isBrandSelectorOpen={isBrandSelectorOpen}
                      setIsBrandSelectorOpen={setIsBrandSelectorOpen}
                      setIsMobileMenuOpen={setIsMobileMenuOpen}
                    />
                  </div>

                  <div className="px-4 py-2">
                    <AgentSidebar
                      setSelectedChatId={setSelectedChatId}
                      selectedChatId={selectedChatId}
                      setDeleteConfirmation={setDeleteConfirmation}
                    />
                  </div>

                  {currentPlan?.data.status !== TeamPackageStatus.ACTIVE && (
                    <div className="px-4 py-2">
                      <BillingInfo className="relative" />
                    </div>
                  )}
                </div>

                <div className="border-t border-gray-200 px-4 py-4">
                  <NavigationGroupItem
                    title="Settings"
                    pathTitle="workspace"
                    icon={faGear}
                    solidIcon={faSolidGear}
                    path="/workspace"
                    handleClick={() => setIsMobileMenuOpen(false)}
                  />
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
