import React, { useEffect, useState } from "react";
//@ts-ignore
import bgSvg from "../../../../../assets/images/creation-background.png";
import { useGetFiles } from "../../../../../api/library/files/get";
import { useCreationStore } from "../../../../../store/creationStore";
import { ISingleFIleVM } from "../../../../../utils/interfaces/IAssets";
import SButton from "../../../../../design-system/SButton";
import { faAdd } from "@fortawesome/pro-regular-svg-icons";
import { useCreationLibraryHelperFunctions } from "../hooks/useCreationLibraryHelperFunctions";
import ExpandedImageModal from "../../../../utils/ExpandedImageModal";
import SModalBase from "../../../../../design-system/SModalBase";

function GeneratedSelector({
  selected,
  setSelected,
}: {
  selected: ISingleFIleVM | null;
  setSelected: React.Dispatch<React.SetStateAction<ISingleFIleVM | null>>;
}) {
  const {
    postData,
    setOpenAssetsGeneratedImageModal,
    openAssetsGeneratedImageModal,
  } = useCreationStore();
  const { data: files, isFetching } = useGetFiles({
    page: 1,
    pageSize: 30,
    generatedOnly: true,
    postId: postData?.id,
  });
  const { insertMediaForAllSocialNetworkType, isMediaAlreadyInPost } =
    useCreationLibraryHelperFunctions();

  const [expandedImage, setExpandedImage] = useState<string | null>(null);

  return (
    <>
      {expandedImage && (
        <ExpandedImageModal
          isOpen={!!expandedImage}
          onClose={() => setExpandedImage(null)}
          source={expandedImage}
        />
      )}
      <SModalBase
        isOpen={openAssetsGeneratedImageModal}
        onClose={setOpenAssetsGeneratedImageModal}
        dialogPanelClassName="w-[900px] max-w-[900px]"
      >
        <div className="mx-[16px] rounded-[8px] overflow-hidden">
          <div
            style={{
              boxShadow: "2px 4px 12px 0px rgba(0, 0, 0, 0.04) inset",
              backgroundImage: `url(${bgSvg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="w-full h-[495px] py-[24px] px-[32px] flex justify-center items-center gap-[32px] max-md:flex-col max-md:p-[12px] max-md:gap-[12px]"
          >
            <div className="w-[100%] shadow-md rounded-[12px] overflow-hidden h-full relative bg-graycool-25">
              <img
                src={selected?.thumbnailLargeUrl || selected?.url}
                alt="generated-image"
                className="w-full h-full object-cover cursor-pointer"
                onClick={() => setExpandedImage(selected!.url)}
              />
              <div className="absolute top-[16px] right-[16px] flex gap-[8px]">
                <SButton
                  onClick={() => {
                    if (selected && !isMediaAlreadyInPost(selected))
                      if (selected)
                        insertMediaForAllSocialNetworkType(
                          selected,
                          false,
                          true
                        );
                  }}
                  type="secondaryGray"
                  size="md"
                  lIcon={faAdd}
                >
                  Add to post
                </SButton>
              </div>
            </div>
            <div className="flex flex-col gap-[8px] min-w-[96px] overflow-auto h-full hide__scroll max-md:flex-row">
              {!isFetching ? (
                files?.data.files.map((item) => (
                  <img
                    onClick={() => {
                      if (selected?.id !== item.id) setSelected(item);
                    }}
                    key={item.id}
                    className={`
              ${selected?.id === item.id && "border-[4px] p-[4px] border-brand-600"}
              rounded-[12px] cursor-pointer object-cover transition-all !aspect-square w-[96px] h-[96px]
            `}
                    src={item.thumbnailSmallUrl || item.url}
                    alt=""
                  />
                ))
              ) : (
                <>
                  <div className="animate-pulse w-full min-h-[96px] bg-gray-200" />
                  <div className="animate-pulse w-full min-h-[96px] bg-gray-200" />
                  <div className="animate-pulse w-full min-h-[96px] bg-gray-200" />
                </>
              )}
            </div>
          </div>
        </div>
      </SModalBase>
    </>
  );
}

export default GeneratedSelector;
