import { useEffect, useState } from "react";
import { useBrandStore } from "../../../store/brandStore";
import TextsSection, { IBrandSectionTextsProps } from "../TextsSection";
import { toast } from "../../../hooks/toast";
import { usePutBrand } from "../../../api/brand/put";
import _ from "lodash";
import { faBox } from "@fortawesome/pro-regular-svg-icons";

export const ProductService = () => {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  const [productService, setProductService] = useState<
    IBrandSectionTextsProps[]
  >([]);
  useEffect(() => {
    if (
      currentBrand?.brandingData?.productServiceDescription &&
      currentBrand?.brandingData?.productServiceDescription.length > 0
    ) {
      let tmpProductService: IBrandSectionTextsProps[] = [];
      currentBrand.brandingData.productServiceDescription.forEach(
        (item, index) => {
          tmpProductService.push({
            isTitleEditable: true,
            key: `product-service-${index}`,
            content: item.description,
            title: item.productName,
            showBorder:
              index !==
              currentBrand.brandingData.productServiceDescription.length - 1,
            handleChangeText: (text: string) => {
              if (
                currentBrand &&
                currentBrand?.brandingData?.productServiceDescription[index]
                  .description !== text
              ) {
                let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
                tmpBrandingData.productServiceDescription[index].description =
                  text;
                updateBrand({
                  brandId: currentBrand.id,
                  brandingData: tmpBrandingData,
                })
                  .then(() => {
                    toast(
                      "Product/Service description updated successfully",
                      "success"
                    );
                  })
                  .catch((err) => {
                    toast(
                      `Failed to update product/service description, ${err.response.data.message}`,
                      "error"
                    );
                  });
              }
            },
            handleChangeTitle: ({
              text,
              sKey,
            }: {
              text: string;
              sKey: string;
            }) => {
              if (
                currentBrand &&
                currentBrand?.brandingData?.targetAudience[
                  Number(sKey[sKey.length - 1])
                ].audienceName !== text
              ) {
                let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
                tmpBrandingData.targetAudience[
                  Number(sKey[sKey.length - 1])
                ].audienceName = text;
                updateBrand({
                  brandId: currentBrand.id,
                  brandingData: tmpBrandingData,
                })
                  .then(() => {
                    toast(
                      "Product/Service name updated successfully",
                      "success"
                    );
                  })
                  .catch((err) => {
                    toast(
                      `Failed to update product/service name, ${err.response.data.message}`,
                      "error"
                    );
                  });
              }
            },
          });
        }
      );
      setProductService(tmpProductService);
    }
  }, [currentBrand]);

  return (
    <>
      {" "}
      <TextsSection
        fieldName="Audience"
        loadingCount={3}
        title="Product"
        subtitle=""
        isLoading={!currentBrand?.brandingData?.productServiceDescription}
        texts={productService}
      />
    </>
  );
};
