import { ReactNode } from "react";

export const LoadingAvatar = ({ children }: { children: ReactNode }) => {
  const strokeWidth = 2.25;
  const size = 40;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  return (
    <div className="relative">
      <svg width={size} height={size} className="rotate-[-90deg]">
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={circumference}
          strokeLinecap="round"
          className="animation-progress"
        />
      </svg>
      {children}
      <style>{`
        .animation-progress {
          stroke: url(#gradient);
          animation: infiniteProgress 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
          transform-origin: center;
        }

        @keyframes infiniteProgress {
          0% {
            stroke-dashoffset: ${circumference};
          }

          100% {
            stroke-dashoffset: -${circumference};
          }
        }
      `}</style>
      <svg width="0" height="0">
        <defs className="text-brand-50">
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#8f58e9" /> {/* Cyan 400 */}
            <stop offset="50%" stopColor="#b293f2  " /> {/* Brand 500 */}
            <stop offset="100%" stopColor="#ffffff" /> {/* Pink 500 */}
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
