/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import SModalBase from "../design-system/SModalBase";
import { useCopilotStore } from "../store/copilotStore";
import Head from "../components/copilot/Head";
import Sessions from "../components/copilot/Sessions";
import CopilotChat from "../components/copilot/CopilotChat";
import { useGetCopilotSession } from "../api/copilot/session/get";
import { CopilotPurpose } from "../utils/enums/Enums";
import { useGetCopilotChat } from "../api/copilot/chat/get";
import { useFillChat } from "../components/copilot/hooks/useFillChat";
import { useCopilotHelper } from "../components/copilot/hooks/useCopilotHelper";
import SpeechRecognition from "react-speech-recognition";

function CopilotModal() {
  const {
    isCopilotOpen,
    messages,
    setIsCopilotOpen,
    setActiveSession,
    activeSession,
    reset,
  } = useCopilotStore();
  useFillChat();
  useEffect(() => {
    document.getElementById("copilotChat")?.scrollTo({
      top: 1000000,
      left: 0,
      behavior: "smooth",
    });
  }, [messages]);

  return (
    <SModalBase
      showX={false}
      dialogPanelClassName={
        "w-full !max-w-[1000px] min-h-[70dvh] h-full max-h-[800px] !p-0 rounded-[12px] overflow-hidden"
      }
      isOpen={isCopilotOpen}
      onClose={async () => {
        reset();
        await SpeechRecognition.stopListening();
        setIsCopilotOpen(false);
      }}
    >
      <Head />
      <div className="flex h-[calc(100%-56px)]">
        <Sessions />
        <CopilotChat />
      </div>
    </SModalBase>
  );
}

export default CopilotModal;
