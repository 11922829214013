import React, { useEffect, useState } from "react";
import TextsSection, { IBrandSectionTextsProps } from "../TextsSection";
import _ from "lodash";
import { useBrandStore } from "../../../store/brandStore";
import { usePutBrand } from "../../../api/brand/put";
import { toast } from "../../../hooks/toast";
import { faUsers } from "@fortawesome/pro-regular-svg-icons";

function Audience() {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  const [audience, setAudience] = useState<IBrandSectionTextsProps[]>([]);

  useEffect(() => {
    if (
      currentBrand?.brandingData?.targetAudience &&
      currentBrand?.brandingData?.targetAudience.length > 0
    ) {
      let tmpAudience: IBrandSectionTextsProps[] = [];
      currentBrand.brandingData.targetAudience.forEach((item, index) => {
        tmpAudience.push({
          isTitleEditable: true,
          key: `audience-${index}`,
          content: item.description,
          title: item.audienceName,
          showBorder:
            index !== currentBrand.brandingData.targetAudience.length - 1,
          handleChangeText: (text: string) => {
            if (
              currentBrand &&
              currentBrand?.brandingData?.targetAudience[index].description !==
                text
            ) {
              let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
              tmpBrandingData.targetAudience[index].description = text;
              updateBrand({
                brandId: currentBrand.id,
                brandingData: tmpBrandingData,
              })
                .then(() => {
                  toast("Audience description updated successfully", "success");
                })
                .catch((err) => {
                  toast(
                    `Failed to update audience description, ${err.response.data.message}`,
                    "error"
                  );
                });
            }
          },
          handleChangeTitle: ({
            text,
            sKey,
          }: {
            text: string;
            sKey: string;
          }) => {
            if (
              currentBrand &&
              currentBrand?.brandingData?.targetAudience[
                Number(sKey[sKey.length - 1])
              ].audienceName !== text
            ) {
              let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
              tmpBrandingData.targetAudience[
                Number(sKey[sKey.length - 1])
              ].audienceName = text;
              updateBrand({
                brandId: currentBrand.id,
                brandingData: tmpBrandingData,
              })
                .then(() => {
                  toast("Audience name updated successfully", "success");
                })
                .catch((err) => {
                  toast(
                    `Failed to update audience name, ${err.response.data.message}`,
                    "error"
                  );
                });
            }
          },
        });
      });
      setAudience(tmpAudience);
    }
  }, [currentBrand]);

  return (
    <>
      <TextsSection
        fieldName="Audience"
        loadingCount={3}
        title="Audience"
        subtitle=""
        isLoading={!currentBrand?.brandingData?.targetAudience}
        texts={audience}
      />
    </>
  );
}

export default Audience;
