import { useEffect, useState } from "react";
import Audience from "../../components/brand/sections/Audience";
import MainInfo from "../../components/brand/sections/MainInfo";
import CreateCustomFieldButton from "./customFields/CreateCustomFieldButton";
import CreateCustomFieldModal from "./customFields/CreateCustomFieldModal";
import {
  BrandingSectionName,
  ISingleCustomFieldVM,
} from "../../utils/interfaces/IBrand";
import { useBrandStore } from "../../store/brandStore";
import CustomField from "./customFields/CustomField";
import { BrandConfirmRegenerateSections } from "../../components/brand/BrandConfirmRegenerateSections";
import { useBrandHelperFunctions } from "../../components/brand/hooks/useBrandHelperFunctions";
import { useAccountStore } from "../../store/accountStore";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import BrandCreatingLoader from "./BrandLoader";
import Archetype from "../../components/brand/sections/Archetype";
import { Benefits } from "../../components/brand/sections/Benefits";
import CoreValues from "../../components/brand/sections/CoreValues";
import { Desires } from "../../components/brand/sections/Desires";
import { Frustrations } from "../../components/brand/sections/Frustrations";
import MissionStatement from "../../components/brand/sections/MissionStatement";
import { PainPoints } from "../../components/brand/sections/PainPoints";
import { ProductService } from "../../components/brand/sections/ProductService";
import { RecommendedChannels } from "../../components/brand/sections/RecommendedChannels";
import { RelevantTopics } from "../../components/brand/sections/RelevantTopics";
import { SuggestedKeyMessages } from "../../components/brand/sections/SuggestedKeyMessages";
import VisionStatement from "../../components/brand/sections/VisionStatement";

function BrandBook({
  onRegenerateBrandOpen,
}: {
  onRegenerateBrandOpen: () => void;
}) {
  const {
    isCustomFieldModalOpen,
    setIsCustomFieldModalOpen,
    activeField,
    setActiveField,
  } = useBrandStore();
  const [customFields, setCustomFields] = useState<ISingleCustomFieldVM[]>([]);
  const { currentBrand } = useBrandStore();

  useEffect(() => {
    if (currentBrand) {
      setCustomFields(currentBrand.customFields);
    }
  }, [currentBrand]);

  // const firstHalf = customFields.slice(0, half);
  // const secondHalf = customFields.slice(half);

  const [confirmRegenerate, setConfirmRegenerate] = useState<
    BrandingSectionName | false
  >(false);
  const { handleRegenerateSection } = useBrandHelperFunctions();
  const { personalInfo } = useAccountStore();
  return (
    <>
      {/* <CreateCustomFieldModal
        isOpen={isCustomFieldModalOpen}
        onClose={() => {
          setIsCustomFieldModalOpen(false);
          setActiveField(undefined);
        }}
        field={activeField}
      /> */}
      {confirmRegenerate !== false && (
        <BrandConfirmRegenerateSections
          openModal={confirmRegenerate}
          setOpenModal={setConfirmRegenerate}
          onConfirm={() => {
            confirmRegenerate && handleRegenerateSection(confirmRegenerate);
            setConfirmRegenerate(false);
          }}
        />
      )}
      <div className="mt-[20px] mb-[24px] pr-[16px] max-md:pr-0 relative">
        {!currentBrand?.isBrandDataGenerated && (
          <BrandCreatingLoader onRegenerateBrandOpen={onRegenerateBrandOpen} />
        )}
        <div className="flex gap-[16px] max-md:flex-col">
          <div className="flex flex-col gap-[16px] min-w-[50%] max-w-[50%] max-md:flex-col max-md:min-w-full max-md:w-full">
            <MainInfo isOnSidebar={false} />
            {/* {firstHalf.map((field) => (
              <CustomField
                setActiveField={setActiveField}
                field={field}
                setIsCustomFieldModalOpen={setIsCustomFieldModalOpen}
              />
            ))}
            {secondHalf.length > firstHalf.length && (
              <CreateCustomFieldButton
                openModal={() => {
                  if (!currentBrand?.isBrandDataGenerated) return;
                  setIsCustomFieldModalOpen(true);
                }}
              />
            )} */}
            <Audience />
            <PainPoints />
            <Benefits />
            <Frustrations />
            <RecommendedChannels />
          </div>
          <div className="flex flex-col gap-[16px] min-w-[50%] max-w-[50%] max-md:flex-col max-md:min-w-full max-md:w-full">
            <ProductService />
            <CoreValues />
            <Archetype />
            <MissionStatement />
            <VisionStatement />
            <RelevantTopics />
            <SuggestedKeyMessages />

            <Desires />
            {/* {secondHalf.map((field, index) => (
              <CustomField
                field={field}
                setActiveField={setActiveField}
                setIsCustomFieldModalOpen={setIsCustomFieldModalOpen}
              />
            ))}
            {secondHalf.length <= firstHalf.length &&
              personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
                <CreateCustomFieldButton
                  openModal={() => {
                    if (
                      !currentBrand?.isBrandDataGenerated ||
                      currentBrand?.hasErrors
                    )
                      return;
                    setIsCustomFieldModalOpen(true);
                  }}
                />
              )} */}
          </div>
        </div>
      </div>
    </>
  );
}

export default BrandBook;
