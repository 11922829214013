import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useGetCurrentBrand } from "../../../../api/brand/current/get";
import { useCreationStore } from "../../../../store/creationStore";
import { SocialNetworkType } from "../../../../utils/enums/Enums";
import {
  faDiscord,
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faReddit,
  faSlack,
  faTelegram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

export const useScheduleHelperFunctions = () => {
  const { data: currentBrand } = useGetCurrentBrand();
  const { selectedSocialNetworks } = useCreationStore();
  const { postData } = useCreationStore();

  const availableNetworks = () => {
    return postData?.socialNetworkPosts
      .map((item) => ({
        id: item.id,
        socialNetworkType: item.socialNetworkType,
        ayrshareSocialAccountId: item.ayrshareSocialAccountId || 0,
      }))
      .filter((item) =>
        currentBrand?.data.activeSocialNetworks.includes(item.socialNetworkType)
      );
  };
  const getSocialNetworkLogo = (
    socialNetwork: SocialNetworkType
  ): { icon: IconProp; color: string } => {
    if (socialNetwork === SocialNetworkType.FACEBOOK)
      return { icon: faFacebook, color: "#0866FF" };
    if (socialNetwork === SocialNetworkType.INSTAGRAM)
      return { icon: faInstagram, color: "#D62976" };
    if (socialNetwork === SocialNetworkType.TWITTER)
      return { icon: faXTwitter, color: "#000000" };
    if (socialNetwork === SocialNetworkType.LINKEDIN)
      return { icon: faLinkedin, color: "#0077B5" };
    if (socialNetwork === SocialNetworkType.PINTEREST)
      return { icon: faPinterest, color: "#E60023" };
    if (socialNetwork === SocialNetworkType.SLACK)
      return { icon: faSlack, color: "#4A154B" };
    if (socialNetwork === SocialNetworkType.DISCORD)
      return { icon: faDiscord, color: "#5865F2" };
    if (socialNetwork === SocialNetworkType.TELEGRAM)
      return { icon: faTelegram, color: "#0088cc" };
    if (socialNetwork === SocialNetworkType.REDDIT)
      return { icon: faReddit, color: "#FF4500" };
    return { icon: faFacebook, color: "#0866FF" };
  };

  const getSelectedSocialNetworks = (): SocialNetworkType[] => {
    return selectedSocialNetworks.map((item) => item.socialNetworkType);
  };

  return {
    getSocialNetworkLogo,
    getSelectedSocialNetworks,
    availableNetworks,
  };
};
