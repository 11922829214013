import {
  faClose,
  faHeart,
  faThumbsUp,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCreationStore } from "../../../store/creationStore";
//@ts-ignore
import Jason from "../../../assets/images/agents/jason.png";
import ImagesGrid from "../../creation/Tabs/Media/ImagesGrid";
import { SocialNetworkType } from "../../../utils/enums/Enums";
import { useEffect, useState } from "react";
import { useSaveChanges } from "../hooks/useSaveChanges";
import { SaveStatus } from "../../shared/SaveStatus";
import { useGetCurrentBrand } from "../../../api/brand/current/get";

export const AgentSchedulePreview = ({
  selectedSocialNetwork,
}: {
  selectedSocialNetwork: SocialNetworkType;
}) => {
  const { setIsScheduleModalOpen } = useCreationStore();
  const { postData } = useCreationStore();
  const [value, setValue] = useState("");
  const { handleBlur, isSaving } = useSaveChanges();
  const { data: currentBrand } = useGetCurrentBrand();

  useEffect(() => {
    if (postData) {
      const text = postData?.socialNetworkPosts?.find(
        (item) => item.socialNetworkType === selectedSocialNetwork
      )?.text;
      setValue(text || postData.socialNetworkPosts[0].text || "");
    }
  }, [postData, selectedSocialNetwork]);

  const getSocialNetworkPostId = () => {
    return postData?.socialNetworkPosts?.find(
      (item) => item.socialNetworkType === selectedSocialNetwork
    )?.id;
  };

  return (
    <div className="rounded-xl ">
      <div className="flex items-center justify-between px-6 py-2">
        <p className="text-gray-600 text-md-sb">Preview</p>
        <div className="flex items-center gap-1">
          <SaveStatus isSaving={isSaving} />
          <FontAwesomeIcon
            onClick={() => setIsScheduleModalOpen(false)}
            className="text-gray-400 cursor-pointer text-md-b"
            icon={faClose}
          />
        </div>
      </div>
      <hr className="w-full bg-gray-200 mb-4" />
      <div className="w-full gap-3 px-4">
        <div className="w-full flex flex-col rounded-t-xl rounded-b-xl shadow-sm">
          <div className="w-full flex items-center gap-2 px-4 py-2 border rounded-t-xl border-gray-200">
            <img
              className="w-8 object-contain h-8 aspect-square rounded-full border-2 border-gray-200"
              alt="social network avatar"
              src={
                currentBrand?.data.secondLogo
                  ? currentBrand?.data.secondLogo
                  : currentBrand?.data.logo
              }
            />
            <p className="text-gray-900 text-sm-sb">
              {currentBrand?.data.brandingData.brandName}
            </p>
          </div>
          <textarea
            placeholder="Your content goes here..."
            value={value.replace(/\*/g, "").replace(/#{2,}/g, "")}
            onChange={(e) => setValue(e.target.value)}
            onBlur={(e) =>
              handleBlur(
                value.replace(/\*/g, "").replace(/#{2,}/g, ""),
                getSocialNetworkPostId()
              )
            }
            className="w-full border-x pt-4 border-gray-200 focus:border-gray-200 text-sm text-gray-700 h-[230px] px-4 border-0 resize-none outline-none focus-visible:outline-[0] focus:ring-0"
          />
          {postData && (
            <div className="border-x w-full h-[173px] border-gray-200">
              <ImagesGrid
                rounded={false}
                images={postData?.socialNetworkPosts[0].files.map((item) =>
                  item.templateOutputUrl ? item.templateOutputUrl : item.url!
                )}
              />
            </div>
          )}
          <div className="w-full items-center justify-between flex px-6 py-4 border border-gray-200 rounded-b-xl">
            <div className="flex items-center gap-2">
              <FontAwesomeIcon
                className="text-gray-500 text-xs"
                icon={faThumbsUp}
              />
              <FontAwesomeIcon
                className="text-gray-500 text-xs"
                icon={faHeart}
              />
            </div>
            <div className="flex items-center gap-2">
              <p className="text-gray-500 text-xs">12 comments</p>
              <p className="text-gray-500 text-xs">2 shares</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
