import { useEffect, useState } from "react";
import { useBrandStore } from "../../../store/brandStore";
import TextsSection, { IBrandSectionTextsProps } from "../TextsSection";
import { toast } from "../../../hooks/toast";
import { usePutBrand } from "../../../api/brand/put";
import _ from "lodash";
import { faFaceSmile } from "@fortawesome/pro-regular-svg-icons";

export const Desires = () => {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  const [desires, setDesires] = useState<IBrandSectionTextsProps[]>([]);

  useEffect(() => {
    if (
      currentBrand?.brandingData?.desires &&
      currentBrand.brandingData.desires.length > 0
    ) {
      let tmpDesires: IBrandSectionTextsProps[] = [];
      currentBrand.brandingData.desires.forEach((item, index) => {
        tmpDesires.push({
          isTitleEditable: false,
          key: `desire-${index}`,
          content: item,
          showBorder: index !== currentBrand.brandingData.desires.length - 1,
          handleChangeText: (text: string) => {
            if (
              currentBrand &&
              currentBrand.brandingData.desires[index] !== text
            ) {
              let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
              tmpBrandingData.desires[index] = text;
              updateBrand({
                brandId: currentBrand.id,
                brandingData: tmpBrandingData,
              })
                .then(() => {
                  toast("Desire updated successfully", "success");
                })
                .catch((err) => {
                  toast(
                    `Failed to update desire, ${err.response.data.message}`,
                    "error"
                  );
                });
            }
          },
        });
      });
      setDesires(tmpDesires);
    }
  }, [currentBrand]);

  return (
    <TextsSection
      fieldName="Desires"
      loadingCount={3}
      title="Desires"
      subtitle=""
      isLoading={!currentBrand?.brandingData?.desires}
      texts={desires}
    />
  );
};
