import React from "react";

function MessageLoader() {
  return (
    <div
      role="status"
      className="w-full animate-pulse relative flex gap-[12px] max-w-[70%]"
    >
      <div className="h-[40px] w-[40px] min-h-[40px] min-w-[40px] bg-gray-300 rounded-full" />
      <div className="h-[70px] gap-[4px] flex flex-col px-[12px] py-[12px] w-full bg-gray-300  rounded-tl-0 rounded-tr-[8px] rounded-br-[8px] rounded-bl-[8px]">
        <div className="h-[10px] w-full bg-gray-200 rounded-full" />
        <div className="h-[10px] w-[70%] bg-gray-200 rounded-full" />
        <div className="h-[10px] w-[30%] bg-gray-200 rounded-full" />
      </div>
    </div>
  );
}

export default MessageLoader;
