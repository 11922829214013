import PageHeading from "../components/PageHeading";
import PostList from "../components/content/PostList";
import { ISinglePostVM } from "../utils/interfaces/IPost";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-regular-svg-icons";
import { tabItems } from "../components/content/utils/tabItems";
import WhichSocialNetworksSelector from "../components/utils/WhichSocialNetworksSelector";
import { ContentIdea } from "../components/content/ContentIdea";
import { OrganizationUserRole, TeamPackageStatus } from "../utils/enums/Enums";
import { EmptyContentState } from "../components/creation/EmptyState/EmptyContentState";
import { SortPosts } from "../components/content/SortPosts";
import { ContentIdeaSkeleton } from "../components/content/ContentIdeaSkeleton";
import { useContent } from "../components/content/hooks/useContent";
import { useContentCount } from "../components/content/hooks/useContentCount";
import { useContentScroll } from "../components/content/hooks/useConntentScroll";
import NewPostList from "../components/newContent/NewPostList";
import { useEffect } from "react";

function NewContent() {
  const {
    posts,
    setPosts,
    setPage,
    isFetching,
    calculateTotalCount,
    totalCount,
  } = useContentScroll();
  const {
    currentPlan,
    windowWidth,
    handleOpenPost,
    personalInfo,
    workspaceUsage,
    isWhichSocialNetworksSelectorOpen,
    setIsWhichSocialNetworksSelectorOpen,
    setIsPostOpen,
    searchValue,
    setSearchValue,
    navigate,
    setSelectedChatId,
  } = useContent();
  const { selected, setSelected, selectedSort, setSelectedSort } =
    useContentCount();
  useEffect(() => {
    setSelectedChatId("content");
  }, []);
  return (
    <>
      {isWhichSocialNetworksSelectorOpen && (
        <WhichSocialNetworksSelector
          saveAllOnClose={true}
          isOpen={isWhichSocialNetworksSelectorOpen}
          onClose={() => {
            setIsWhichSocialNetworksSelectorOpen(false);
            setIsPostOpen(true);
          }}
        />
      )}
      <div className="w-full px-4 pt-4 h-fit pb-[50px] rounded-[10px]">
        <PageHeading
          className="mb-[20px]"
          title="Content"
          subtitle="Manage your posts and content."
          withInput={true}
          inputProps={{
            placeholder: "Search...",
            value: searchValue,
            onChange: (e) => setSearchValue(e.target.value),
            rightAdditionalContent: (
              <div
                className={`${searchValue.length > 0 ? "block" : "hidden"}`}
                onClick={() => setSearchValue("")}
              >
                <FontAwesomeIcon
                  icon={faX}
                  fontSize={"14px"}
                  className="text-gray-700 cursor-pointer"
                />
              </div>
            ),
          }}
          tabsProps={{
            items: tabItems,
            selected: selected,
            setSelected: (i) => {
              if (i === selected) return;
              const index = i === 4 ? 3 : i;
              setPosts([]);
              setPage(1);
              setSelected(index as number);
              navigate(
                "/content/?subsection=" +
                  tabItems[index as number]?.text?.toLocaleLowerCase()
              );
            },
            rItems: (
              <SortPosts
                className="whitespace-nowrap max-sm:text-sm"
                selectedSort={selectedSort}
                setSelectedSort={setSelectedSort}
              />
            ),
          }}
        />
        {totalCount === 0 && !isFetching && searchValue.length === 0 && (
          <EmptyContentState
            setIsWhichSocialNetworksSelectorOpen={
              setIsWhichSocialNetworksSelectorOpen
            }
          />
        )}
        <NewPostList
          handleOpenPost={(p: ISinglePostVM) => {
            handleOpenPost({
              postId: p.id,
              onSocialNetworkSelectorOpen: () =>
                setIsWhichSocialNetworksSelectorOpen(true),
            });
          }}
          handleCreateNew={() =>
            handleOpenPost({
              onSocialNetworkSelectorOpen: () =>
                setIsWhichSocialNetworksSelectorOpen(true),
            })
          }
          posts={posts}
          totalCount={totalCount || 0}
          searchValue={searchValue}
          isLoading={isFetching}
          selected={selected}
        />
      </div>
    </>
  );
}

export default NewContent;
