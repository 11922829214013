import { useCreationStore } from "../../../store/creationStore";
import { CopilotPurpose } from "../../../utils/enums/Enums";
import { PreviewActions } from "./actions/PreviewActions";
import { BrandPreview } from "./brand/BrandPreview";
import { PostContent } from "./content/PostContent";
import PreviewPostsList from "./PreviewPostsList";

interface PreviewContentProps {
  selectedCopilotPurpose: CopilotPurpose | null;
  handleBlur: (value: string) => void;
}

const PreviewContent = ({
  selectedCopilotPurpose,
  handleBlur,
}: PreviewContentProps) => {
  const { selectedChatId, selectedPreviewTab } = useCreationStore();
  return (
    <div className="flex flex-col justify-between gap-4 p-4 w-full h-full">
      {selectedChatId === "general" && selectedPreviewTab === 0 && (
        <PostContent handleBlur={handleBlur} />
      )}
      {selectedChatId === "general" && selectedPreviewTab === 1 && (
        <PreviewPostsList />
      )}
      {selectedChatId === "general" && selectedPreviewTab === 0 && (
        <PreviewActions />
      )}
      {selectedChatId === "brand" && <BrandPreview />}
    </div>
  );
};

export default PreviewContent;
