import { faSparkles, faGif, faImage } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SButton from "../../../design-system/SButton";
import { LibraryDropdown } from "../../Library/LibraryDropdown";
import { useCreationStore } from "../../../store/creationStore";

export interface IShortcutButtonsProps {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  dropboxRef: React.RefObject<HTMLInputElement>;
  selectFile: (options: { multiple: boolean }) => void;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

export const SwitcherButtons = ({
  selectFile,
  setOpenModal,
  dropboxRef,
  setInputValue,
}: IShortcutButtonsProps) => {
  const { assetsTab, setAssetsTab } = useCreationStore();
  return (
    <div className="w-full px-4 md2:px-0 whitespace-nowrap grid grid-cols-2 md:grid-cols-5 gap-4 items-center">
      <SButton
        onClick={() => {
          setAssetsTab("assets");
        }}
        size="xl"
        className={`text-lg w-full ${assetsTab === "assets" ? "bg-gray-100 hover:!bg-gray-200" : ""}`}
        type="secondaryGray"
      >
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon icon={faImage} className="text-[18px]" />
          <p>Assets</p>
        </div>
      </SButton>
      <SButton
        onClick={() => {
          setAssetsTab("generator");
          setInputValue("");
        }}
        size="xl"
        className={`text-lg w-full ${assetsTab === "generator" ? "bg-gray-100 hover:!bg-gray-200" : ""}`}
        type={"secondaryGray"}
      >
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon icon={faSparkles} className="text-[18px]" />
          <p>Generate</p>
        </div>
      </SButton>
      <SButton
        onClick={() => {
          setAssetsTab("gifs");
        }}
        size="xl"
        className={`text-lg w-full ${assetsTab === "gifs" ? "bg-gray-100 hover:!bg-gray-200" : ""}`}
        type={"secondaryGray"}
      > 
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon icon={faGif} className="text-[18px]" />
          <p>
            GIFs <span className="hidden xl:inline">& Clips</span>
          </p>
        </div>
      </SButton>
      <SButton
        onClick={() => {
          setAssetsTab("stocks");
          setInputValue("");
        }}
        size="xl"
        className={`text-lg w-full ${assetsTab === "stocks" ? "bg-gray-100 hover:!bg-gray-200" : ""}`}
        type={"secondaryGray"}
      >
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon icon={faImage} className="text-[18px]" />
          <p>
            <span className="hidden xl:inline">Stock </span>Photos
          </p>
        </div>
      </SButton>
      <LibraryDropdown
        btnClassName="w-full md:w-fit col-span-2 md:col-span-1 relative ml-auto z-[10]"
        handleFiles={() => selectFile({ multiple: true })}
        handleFolder={() => setOpenModal(true)}
        handleDropbox={() => dropboxRef.current?.click()}
        isContent={true}
      />
    </div>
  );
};
