import { faX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogPanel,
  DialogPanelProps,
  DialogProps,
} from "@headlessui/react";
import React, { useEffect } from "react";

interface ISModalBase extends DialogPanelProps {
  dialogOptions?: Omit<DialogProps, "onClose">;
  isOpen: boolean;
  onClose: (b: boolean) => void;
  dialogPanelClassName?: string;
  children: React.ReactNode;
  showX?: boolean;
  className?: string;
  id?: string;
  additionalModal?: React.ReactNode;
}

function SModalBase({
  dialogOptions,
  className,
  isOpen,
  dialogPanelClassName,
  onClose,
  id,
  children,
  additionalModal,
  showX = true,
}: ISModalBase) {
  return (
    <Dialog
      id={id}
      open={isOpen}
      onClose={() => onClose(false)}
      className={`${className} relative z-[101]`}
      {...dialogOptions}
    >
      <div className="bg-[rgba(0,0,0,0.4)] fixed inset-0 flex w-screen gap-3 items-center justify-center p-4">
        <DialogPanel className="flex items-center gap-3">
          <div
            className={`${dialogPanelClassName} rounded-[12px] relative max-w-[600px] bg-base-white shadow-xl p-[24px]`}
          >
            {showX && (
              <FontAwesomeIcon
                onClick={() => onClose(false)}
                icon={faX}
                fontSize={"14px"}
                className="text-gray-400 z-[101] absolute top-[24px] right-[24px] cursor-pointer"
              />
            )}
            {children}
          </div>
          {additionalModal && (
            <div
              className={`${dialogPanelClassName} rounded-[12px] relative max-w-[400px] bg-base-white shadow-xl p-[24px]`}
            >
              {additionalModal}
            </div>
          )}
        </DialogPanel>
      </div>
    </Dialog>
  );
}

export default SModalBase;
