export interface GroupChatImagesProps {
  images: string[];
  name: string;
  variant?: "sidebar" | "chat";
}

export const GroupChatImages = ({
  images,
  name,
  variant = "sidebar",
}: GroupChatImagesProps) => {
  return (
    <>
      {images.length === 1 && (
        <img
          className={`${variant === "sidebar" ? "w-[30px] h-[30px]" : "w-[30px] h-[30px] mr-2"} object-cover border border-gray-300 rounded-full aspect-square`}
          src={images[0]}
          alt={`${name} Chat`}
        />
      )}

      {images.length === 2 && (
        <div className="relative mr-2 w-[100px] h-[30px] flex items-center">
          <img
            className={`w-[30px] h-[30px] z-10 absolute top-0 right-0 object-cover border border-gray-300 rounded-full aspect-square`}
            src={images[0]}
            alt={`${name} Chat`}
          />
          <img
            className={`w-[30px] h-[30px] z-0 absolute top-0 right-5 object-cover border border-gray-300 rounded-full aspect-square`}
            src={images[1]}
            alt={`${name} Chat`}
          />
        </div>
      )}
      {images.length === 3 && (
        <div className="relative mr-2 min-w-[60px] h-[30px] flex items-center">
          <img
            className={`w-[30px] h-[30px] z-20 absolute top-0 right-0 object-cover border border-gray-300 rounded-full aspect-square`}
            src={images[0]}
            alt={`${name} Chat`}
          />
          <img
            className={`w-[30px] h-[30px] z-10 absolute top-0 right-5 object-cover border border-gray-300 rounded-full aspect-square`}
            src={images[1]}
            alt={`${name} Chat`}
          />
          <img
            className={`w-[30px] h-[30px] z-0 absolute top-0 right-10 object-cover border border-gray-300 rounded-full aspect-square`}
            src={images[2]}
            alt={`${name} Chat`}
          />
        </div>
      )}
    </>
  );
};
