import {
  faArrowRightArrowLeft,
  faChevronDown,
  faPlus,
  faRefresh,
} from "@fortawesome/pro-regular-svg-icons";
import React, { useRef } from "react";
import { useGetCurrentBrand } from "../../api/brand/current/get";
import SButton from "../../design-system/SButton";
import { useNavigate } from "react-router-dom";
import { toast } from "../../hooks/toast";
import { useGetWorkspaceUsage } from "../../api/workspaces/limits/get";
import BrandSelector from "./BrandSelector";
import { useHandleOutsideClick } from "../../hooks/useHandleOutsideClick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePutSwitchBrand } from "../../api/brand/switch/put";
import { CopilotPurpose, OrganizationType } from "../../utils/enums/Enums";
import { useCreationStore } from "../../store/creationStore";

function BrandSwitcher({
  setIsBrandSelectorOpen,
  isBrandSelectorOpen,
  setIsMobileMenuOpen,
}: {
  setIsBrandSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isBrandSelectorOpen: boolean;
  setIsMobileMenuOpen?: (open: boolean) => void;
}) {
  const navigate = useNavigate();
  const { mutateAsync: switchBrand, isPending } = usePutSwitchBrand();
  const { data: workspaceUsage } = useGetWorkspaceUsage();
  const { data: currentBrand, isError, isLoading } = useGetCurrentBrand();
  const { setSelectedChatId, setSelectedAgent, setSelectedCopilotPurpose } =
    useCreationStore();

  const wrapperRef = useRef<any>();
  useHandleOutsideClick({
    callBack: () => setIsBrandSelectorOpen(false),
    ref: wrapperRef,
    excludedIds: ["sidebar-brand-switcher"],
  });

  return (
    <div
      ref={wrapperRef}
      className={`md:flex md:flex-col md:items-center md:justify-center max-md:bg-gray-50 max-md:border max-md:border-gray-200 max-md:w-full max-md:rounded-[8px] max-md:py-1 md:py-[4px] rounded-full relative transition-all`}
    >
      {!isError && !isLoading && !isPending && currentBrand ? (
        <div className="relative">
          <div
            onClick={() => setIsBrandSelectorOpen((prev) => !prev)}
            className={`flex justify-between items-center w-full cursor-pointer border gap-[4px] rounded-full border-transparent bg-transparent md:justify-center`}
          >
            <div className="flex max-md:gap-[10px] min-w-[40px] max-h-[40px] relative items-center md:justify-center">
              {currentBrand?.data.secondLogo ? (
                <img
                  className={`w-[40px] h-[40px] border border-gray-200 rounded-[8px] object-contain`}
                  src={currentBrand?.data.secondLogo}
                  alt="brand-logo"
                />
              ) : currentBrand.data.organizationType ===
                OrganizationType.PERSONAL ? (
                <img
                  className={`w-[40px] h-[40px] border border-gray-200 rounded-[8px] object-contain`}
                  src={currentBrand?.data.logo}
                  alt="brand-logo"
                />
              ) : (
                <div className="aspect-square w-[40px] h-[40px] flex p-1 bg-gray-50 rounded-[8px] border border-gray-200 items-center justify-center text-sm-sb">
                  {currentBrand?.data.brandingData?.brandName
                    .slice(0, 2)
                    .toUpperCase()}
                </div>
              )}
              <div className="md:hidden">
                <p className="text-xs text-gray-400">
                  {currentBrand.data.organizationType ===
                    OrganizationType.COMPANY && "Company"}
                  {currentBrand.data.organizationType ===
                    OrganizationType.PERSONAL && "Personal"}
                </p>
                <p
                  className="w-[80px] max-sm:w-full max-sm:text-wrap truncate text-sm-sb text-gray-700"
                  title={currentBrand.data.brandingData?.brandName}
                >
                  {currentBrand.data.brandingData?.brandName}
                </p>
              </div>
            </div>
            {/* <FontAwesomeIcon
              icon={faArrowRightArrowLeft}
              className={`text-gray-400 text-sm max-md:pr-2 md:aspect-square md:text-xs md:absolute md:right-0.5 md:bottom-0 md:bg-white md:rounded-full md:p-1 md:border md:border-gray-100`}
            /> */}
          </div>
          {isBrandSelectorOpen && (
            <BrandSelector
              switchBrand={switchBrand}
              isPending={isPending}
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              setIsBrandSelectorOpen={setIsBrandSelectorOpen}
              currentBrand={currentBrand}
            />
          )}
        </div>
      ) : (
        !isError && (
          <div className="aspect-square w-[40px] h-[40px] mb-0.5 flex p-1 bg-gray-50 border border-gray-200 rounded-[8px] items-center justify-center text-sm-sb">
            <FontAwesomeIcon
              icon={faRefresh}
              spin
              className="text-gray-400 text-sm"
            />
          </div>
        )
      )}
      {!isLoading && isError && (
        <SButton
          type="secondaryColor"
          className="w-full"
          lIcon={faPlus}
          onClick={() => {
            if (workspaceUsage && workspaceUsage.data?.canUserAddBrands) {
              setSelectedChatId("brand");
              navigate("/");
              setSelectedAgent(null);
              setSelectedCopilotPurpose(CopilotPurpose.BRANDCREATION);
            } else {
              toast(
                "You have reached the limit of brands you can create, upgrade plan or contact us",
                "error"
              );
            }
            setIsMobileMenuOpen && setIsMobileMenuOpen(false);
          }}
        >
          <span className="md:hidden">Create Brand</span>
        </SButton>
      )}
    </div>
  );
}

export default BrandSwitcher;
