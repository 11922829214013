import { useEffect } from "react";
import { useSignalR } from "../../../socket-services/useSignalR";
import { usePutSwitchBrand } from "../../../api/brand/switch/put";

interface INewOrganizationCreated {
  organizationId: number;
}

export const useOrganizationSockets = () => {
  const { mutateAsync: switchBrand, isPending } = usePutSwitchBrand();
  const { connected, subscribeToEvent, sendEvent, disconnect } = useSignalR(
    `${process.env.REACT_APP_API_ENDPOINT}hubs/general`
  );
  useEffect(() => {
    if (connected) {
      subscribeToEvent(
        "NewOrganizationCreated",
        (res: INewOrganizationCreated) => {
          switchBrand({
            id: res.organizationId,
          }).then((res) => {
            console.log("Brand Changed");
          });
        }
      );
    }
  }, [connected]);
};
