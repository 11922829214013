import { useDeleteChildBrand } from "../../../api/brand/childBrands/delete";
import { usePostChildBrand } from "../../../api/brand/childBrands/post";
import { useGetCurrentBrand } from "../../../api/brand/current/get";
import { useGetAllBrands } from "../../../api/brand/getAll";
import { useDeleteParentBrand } from "../../../api/brand/parentBrands/delete";
import { usePostParentBrand } from "../../../api/brand/parentBrands/post";
import { useBrandStore } from "../../../store/brandStore";
import { OrganizationType } from "../../../utils/enums/Enums";

export interface BrandOption {
  readonly label: string;
  readonly value: number;
  readonly avatar: string;
}

export const useConnectBrandsHelpers = () => {
  const { data: brands } = useGetAllBrands({ includeAllWorkspaces: false });
  const { data: currentBrand } = useGetCurrentBrand();
  const { mutateAsync: connectPersonal } = usePostChildBrand();
  const { mutateAsync: disconnectPersonal } = useDeleteChildBrand();
  const { mutateAsync: connectParent } = usePostParentBrand();
  const { mutateAsync: disconnectParent } = useDeleteParentBrand();
  const {
    connectBrandIds,
    disconnectBrandIds,
    setConnectBrandIds,
    setDisconnectBrandIds,
  } = useBrandStore();

  const getPersonalBrands = (): BrandOption[] => {
    return (
      brands?.data
        .filter((brand) => brand.organizationType === OrganizationType.PERSONAL)
        .map((brand) => ({
          label: brand.brandingData?.brandName,
          value: brand.id,
          avatar: brand.logo,
        })) || []
    );
  };

  const getCompanyBrands = (): BrandOption[] => {
    return (
      brands?.data
        .filter((brand) => brand.organizationType === OrganizationType.COMPANY)
        .map((brand) => ({
          label: brand.brandingData?.brandName,
          value: brand.id,
          avatar: brand.logo,
        })) || []
    );
  };

  const addConnectBrandIds = (ids: number[]) => {
    let savedIds: number[] = [];
    if (currentBrand?.data.organizationType === OrganizationType.COMPANY) {
      savedIds = currentBrand?.data.subOrganizations.map((sub) => sub.id);
    }
    if (currentBrand?.data.organizationType === OrganizationType.PERSONAL) {
      savedIds = currentBrand?.data.parentOrganizations.map((sub) => sub.id);
    }

    const filteredIds = ids.filter((id) => !savedIds?.includes(id));
    setConnectBrandIds(filteredIds);
  };

  const addDisconnectBrandIds = (ids: number[]) => {
    let savedIds: number[] = [];
    if (currentBrand?.data.organizationType === OrganizationType.COMPANY) {
      savedIds = currentBrand?.data.subOrganizations.map((sub) => sub.id);
    }
    if (currentBrand?.data.organizationType === OrganizationType.PERSONAL) {
      savedIds = currentBrand?.data.parentOrganizations.map((sub) => sub.id);
    }
    const filteredIds = savedIds?.filter((id) => !ids.includes(id));
    if (!filteredIds) return;
    setDisconnectBrandIds(filteredIds);
  };

  const savePersonalBrandConnections = async () => {
    if (!currentBrand) return;
    let res;
    if (connectBrandIds.length > 0) {
      res = await connectPersonal({
        id: currentBrand?.data.id,
        subOrganizationIds: connectBrandIds,
      });
    }
    if (disconnectBrandIds.length > 0) {
      res = await disconnectPersonal({
        id: currentBrand?.data.id,
        subOrganizationIds: disconnectBrandIds,
      });
    }
    return res;
  };

  const saveCompanyBrandConnections = async () => {
    if (!currentBrand) return;
    let res;
    if (connectBrandIds.length > 0) {
      res = await connectParent({
        id: currentBrand?.data.id,
        parentOrganizationIds: connectBrandIds,
      });
    }
    if (disconnectBrandIds.length > 0) {
      res = await disconnectParent({
        id: currentBrand?.data.id,
        parentOrganizationIds: disconnectBrandIds,
      });
    }
    return res;
  };

  const saveBrandConnections = async () => {
    let res;
    if (currentBrand?.data.organizationType === OrganizationType.COMPANY) {
      res = await savePersonalBrandConnections();
    } else {
      res = await saveCompanyBrandConnections();
    }
    return res;
  };

  return {
    getPersonalBrands,
    getCompanyBrands,
    currentBrand,
    savePersonalBrandConnections,
    saveCompanyBrandConnections,
    addConnectBrandIds,
    addDisconnectBrandIds,
    saveBrandConnections,
  };
};
