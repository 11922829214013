import {
  faBarsSort,
  faCalendarPen,
  faChevronDown,
  faCloudArrowUp,
} from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../design-system/SButton";
import SSmallMenu from "../../design-system/SSmallMenu";
import { useState } from "react";
import { useContentStore } from "../../store/contentStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetWindowWidth } from "../../hooks/useGetWindowWidth";

export interface ISortPostsProps {
  selectedSort: "modified" | "created";
  setSelectedSort: (value: "modified" | "created") => void;
  className?: string;
}

export const SortPosts = ({
  selectedSort,
  setSelectedSort,
  className,
}: ISortPostsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setPosts, setPage, setSortByModifiedDate, sortByModifiedDate } =
    useContentStore();

  const handleOptionClick = (
    option: "modified" | "created",
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    if (selectedSort === option) return;
    setSelectedSort(option);
    setSortByModifiedDate(option === "modified" ? true : false);
    setPage(1);
    setPosts([]);
  };
  const menuItems = [
    {
      text: "Created Date",
      icon: faCloudArrowUp,
      color: selectedSort === "created" ? "text-brand-500" : "",
      handleClick: (e: React.MouseEvent<HTMLDivElement>) =>
        handleOptionClick("created", e),
    },
    {
      text: (
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faCalendarPen} />
          Modified Date
        </div>
      ),

      color: selectedSort === "modified" ? "text-brand-500" : "",
      handleClick: (e: React.MouseEvent<HTMLDivElement>) =>
        handleOptionClick("modified", e),
    },
  ];
  const { windowWidth } = useGetWindowWidth();
  return (
    <SButton
      id="sort-content-opener"
      onClick={() => setIsOpen((val) => !val)}
      type="secondaryGray"
      rIcon={faChevronDown}
      size={windowWidth <= 680 ? "sm" : "md"}
      lIcon={faBarsSort}
      className={`relative z-20 ${className}`}
    >
      <p>Sort By</p>
      {isOpen && (
        <SSmallMenu
          excludedId="sort-content-opener"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          className="whitespace-nowrap right-[0px] left-auto"
          data={[menuItems]}
        />
      )}
    </SButton>
  );
};
