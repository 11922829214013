import { useState } from "react";
import Audience from "../../../brand/sections/Audience";
import MainInfo from "../../../brand/sections/MainInfo";
import {
  BrandingSectionName,
  ISingleCustomFieldVM,
} from "../../../../utils/interfaces/IBrand";
import { BrandConfirmRegenerateSections } from "../../../brand/BrandConfirmRegenerateSections";
import { useBrandHelperFunctions } from "../../../brand/hooks/useBrandHelperFunctions";
import { useGetCurrentBrand } from "../../../../api/brand/current/get";
import CreateCustomFieldModal from "../../../../pages/brand/customFields/CreateCustomFieldModal";
import CreateCustomFieldButton from "../../../../pages/brand/customFields/CreateCustomFieldButton";
import { ProductService } from "../../../brand/sections/ProductService";
import { PainPoints } from "../../../brand/sections/PainPoints";
import { Benefits } from "../../../brand/sections/Benefits";
import { Frustrations } from "../../../brand/sections/Frustrations";
import { Desires } from "../../../brand/sections/Desires";
import { RecommendedChannels } from "../../../brand/sections/RecommendedChannels";
import { RelevantTopics } from "../../../brand/sections/RelevantTopics";
import Archetype from "../../../brand/sections/Archetype";
import { SuggestedKeyMessages } from "../../../brand/sections/SuggestedKeyMessages";
import MissionStatement from "../../../brand/sections/MissionStatement";
import VisionStatement from "../../../brand/sections/VisionStatement";
import CoreValues from "../../../brand/sections/CoreValues";

export const BrandPreview = () => {
  const [confirmRegenerate, setConfirmRegenerate] = useState<
    BrandingSectionName | false
  >(false);
  const { handleRegenerateSection } = useBrandHelperFunctions();
  const { data: currentBrand } = useGetCurrentBrand();
  const [activeField, setActiveField] = useState<
    ISingleCustomFieldVM | undefined
  >();
  const [isCustomFieldModalOpen, setIsCustomFieldModalOpen] = useState(false);

  return (
    <>
      <CreateCustomFieldModal
        isOpen={isCustomFieldModalOpen}
        onClose={(p) => {
          setIsCustomFieldModalOpen(p);
          setActiveField(undefined);
        }}
        field={activeField}
      />
      {confirmRegenerate !== false && (
        <BrandConfirmRegenerateSections
          openModal={confirmRegenerate}
          setOpenModal={setConfirmRegenerate}
          onConfirm={() => {
            confirmRegenerate && handleRegenerateSection(confirmRegenerate);
            setConfirmRegenerate(false);
          }}
        />
      )}
      <div className="w-full flex flex-col gap-4 h-[430px] flex-grow overflow-auto">
        <MainInfo isOnSidebar />
        <ProductService />
        <Audience />
        <PainPoints />
        <Benefits />
        <Frustrations />
        <Desires />
        <RecommendedChannels />
        <RelevantTopics />
        <Archetype />
        <SuggestedKeyMessages />
        <MissionStatement />
        <VisionStatement />
        <CoreValues />
        {/* {currentBrand?.data.customFields.map((field) => {
          return (
            <CustomField
              setActiveField={setActiveField}
              field={field}
              setIsCustomFieldModalOpen={setIsCustomFieldModalOpen}
            />
          );
        })} */}
        {/* <CreateCustomFieldButton
          openModal={() => {
            if (
              !currentBrand?.data.isBrandDataGenerated ||
              currentBrand?.data.hasErrors
            )
              return;
            setIsCustomFieldModalOpen(true);
          }}
        /> */}
      </div>
    </>
  );
};
