import React, { useEffect, useState } from "react";
import TextsSection, { IBrandSectionTextsProps } from "../TextsSection";
import _ from "lodash";
import { useBrandStore } from "../../../store/brandStore";
import { usePutBrand } from "../../../api/brand/put";
import { toast } from "../../../hooks/toast";
import { faFingerprint } from "@fortawesome/pro-regular-svg-icons";

function Archetype() {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  const [archetype, setArchetype] = useState<IBrandSectionTextsProps[]>([]);

  useEffect(() => {
    if (currentBrand?.brandingData?.brandArchetype) {
      let tmpArchetype: IBrandSectionTextsProps[] = [];
      tmpArchetype.push({
        isTitleEditable: true,
        key: `brand-archetype`,
        content: currentBrand.brandingData.brandArchetype.paragraph,
        title: currentBrand.brandingData.brandArchetype.subHeadline,
        showBorder: false,
        handleChangeText: (text: string) => {
          if (currentBrand?.brandingData?.brandArchetype.paragraph !== text) {
            let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
            tmpBrandingData.brandArchetype.paragraph = text;
            updateBrand({
              brandId: currentBrand.id,
              brandingData: tmpBrandingData,
            })
              .then(() => {
                toast("Brand archetype updated successfully", "success");
              })
              .catch((err) => {
                toast(
                  `Failed to update brand archetype, ${err.response.data.message}`,
                  "error"
                );
              });
          }
        },
        handleChangeTitle: ({ text, sKey }: { text: string; sKey: string }) => {
          if (currentBrand?.brandingData?.brandArchetype.subHeadline !== text) {
            let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
            tmpBrandingData.brandArchetype.subHeadline = text;
            updateBrand({
              brandId: currentBrand.id,
              brandingData: tmpBrandingData,
            })
              .then(() => {
                toast("Brand archetype updated successfully", "success");
              })
              .catch((err) => {
                toast(
                  `Failed to update brand archetype, ${err.response.data.message}`,
                  "error"
                );
              });
          }
        },
      });
      setArchetype(tmpArchetype);
    }
  }, [currentBrand]);

  return (
    <>
      <TextsSection
        fieldName="Brand Archetype"
        loadingCount={1}
        title="Brand Archetype"
        subtitle=""
        isLoading={!currentBrand?.brandingData?.brandArchetype}
        texts={archetype}
      />
    </>
  );
}

export default Archetype;
