import { useEffect, useState } from "react";
import { useBrandStore } from "../../../store/brandStore";
import TextsSection, { IBrandSectionTextsProps } from "../TextsSection";
import { toast } from "../../../hooks/toast";
import { usePutBrand } from "../../../api/brand/put";
import _ from "lodash";
import { faBullseye } from "@fortawesome/pro-regular-svg-icons";

export const PainPoints = () => {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  const [painPoints, setPainPoints] = useState<IBrandSectionTextsProps[]>([]);

  useEffect(() => {
    if (
      currentBrand?.brandingData?.painPoints &&
      currentBrand.brandingData.painPoints.length > 0
    ) {
      let tmpPainPoints: IBrandSectionTextsProps[] = [];
      currentBrand.brandingData.painPoints.forEach((item, index) => {
        tmpPainPoints.push({
          isTitleEditable: false,
          key: `pain-point-${index}`,
          content: item,
          showBorder: index !== currentBrand.brandingData.painPoints.length - 1,
          handleChangeText: (text: string) => {
            if (
              currentBrand &&
              currentBrand.brandingData.painPoints[index] !== text
            ) {
              let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
              tmpBrandingData.painPoints[index] = text;
              updateBrand({
                brandId: currentBrand.id,
                brandingData: tmpBrandingData,
              })
                .then(() => {
                  toast("Pain point updated successfully", "success");
                })
                .catch((err) => {
                  toast(
                    `Failed to update pain point, ${err.response.data.message}`,
                    "error"
                  );
                });
            }
          },
        });
      });
      setPainPoints(tmpPainPoints);
    }
  }, [currentBrand]);

  return (
    <TextsSection
      fieldName="Pain Points"
      loadingCount={3}
      title="Pain Points"
      subtitle=""
      isLoading={!currentBrand?.brandingData?.painPoints}
      texts={painPoints}
    />
  );
};
