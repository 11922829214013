import {
  faChevronDown,
  faChevronRight,
  faComments,
} from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../../../design-system/SButton";
import { useCreationStore } from "../../../../store/creationStore";

export const CommentsButton = ({ className }: { className?: string }) => {
  const { setIsCommentsModalOpen, isCommentsModalOpen, postData } =
    useCreationStore();
  return (
    <div
      onClick={() => setIsCommentsModalOpen(!isCommentsModalOpen)}
      className={`w-full flex cursor-pointer bg-gray-25 border border-gray-200 px-3 py-2 items-center justify-between ${className} ${!isCommentsModalOpen ? "rounded-b-xl" : "rounded-xl"}`}
    >
      <div className="flex items-center gap-2">
        <SButton type="secondaryGray" size="sm" icon={faComments} />
        <p className="text-sm-sb text-gray-700">Comments</p>
        {postData && (
          <p className="text-xs leading-none h-3 w-3 p-3 rounded-full bg-gray-50 border border-gray-200 flex items-center justify-center text-gray-700">
            {postData?.comments?.length}
          </p>
        )}
      </div>
      <SButton
        type="tertiaryGray"
        icon={isCommentsModalOpen ? faChevronDown : faChevronRight}
      />
    </div>
  );
};
