import { useEffect, useState } from "react";
import {
  CopilotAgentIdentifier,
  CopilotAgentName,
} from "../../../utils/enums/Enums";
import getCaretCoordinates from "textarea-caret";

export const useAgentsSuggestion = ({
  value,
  chatAgents,
  textareaRef,
}: {
  value: string;
  chatAgents: CopilotAgentIdentifier[];
  textareaRef: React.RefObject<HTMLTextAreaElement>;
}) => {
  const [mentionId, setMentionId] = useState<CopilotAgentIdentifier | null>(
    null
  );

  const [mentionPosition, setMentionPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!value.includes("@") || mentionId === null) return;
      const id = mentionId;
      if (e.key === "ArrowUp") {
        if (id === 1) {
          setMentionId(chatAgents.length);
        } else {
          setMentionId(id - 1);
        }
      }
      if (e.key === "ArrowDown") {
        if (id === chatAgents.length) {
          setMentionId(1);
        } else {
          setMentionId(id + 1);
        }
      }
    };
    textareaRef.current?.addEventListener("keydown", handleKeyDown);
    return () => {
      textareaRef.current?.removeEventListener("keydown", handleKeyDown);
    };
  }, [value, mentionId, chatAgents]);

  const getChatAgents = () => {
    return chatAgents.map((agent) => ({
      id: agent,
      display: CopilotAgentName[agent],
    }));
  };

  const handlePositionUpdate = () => {
    const textarea = textareaRef.current;
    if (textarea && mentionId !== null) {
      const atIndex = textarea.selectionStart - 1;
      if (textarea.value[atIndex] !== "@") return;
      const caretCoords = getCaretCoordinates(textarea, atIndex);
      const textareaRect = textarea.getBoundingClientRect();

      setMentionPosition({
        top: caretCoords.top + textareaRect.top,
        left: caretCoords.left + textareaRect.left,
      });
    } else {
      setMentionPosition({ top: 0, left: 0 });
    }
  };

  useEffect(() => {
    handlePositionUpdate();
    if (
      textareaRef.current?.value[textareaRef.current?.selectionStart - 1] !==
      "@"
    ) {
      setMentionId(null);
    }
  }, [value, mentionId]);

  return { getChatAgents, mentionId, setMentionId, mentionPosition };
};
