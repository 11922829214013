import { useEffect, useState } from "react";
import { useCreationStore } from "../../../../../store/creationStore";
import { useGetCopilotChat } from "../../../../../api/copilot/chat/get";
import { useGetCopilotSession } from "../../../../../api/copilot/session/get";
import {
  CopilotAgentName,
  CopilotPurpose,
  CopilotRole,
} from "../../../../../utils/enums/Enums";
import { useBriefHelperFunctions } from "./useBriefHelperFunctions";
import { ICopilotMessage } from "../../../../../utils/copilot-base/ICopilotBase";
import { getSelectedAgents } from "../../../../newContent/functions/getSelectedAgents";

export const useFillChat = ({
  setDisable,
  setChatResponse,
  setValue,
}: {
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  setChatResponse: React.Dispatch<React.SetStateAction<string>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const {
    postData,
    setCopilotSessionId,
    postIdea,
    setPostIdea,
    setImproveIdea,
    improveIdea,
    copilotSessionId,
    setSessionErrored,
  } = useCreationStore();
  const { refetch: refetchCopilotMessages } = useGetCopilotChat({
    sessionId: copilotSessionId,
    enabled: false,
  });
  const { sendMessage } = useBriefHelperFunctions({
    files: null,
    setDisable,
    setChatResponse,
    setValue,
  });
  const { setBriefMessages, briefMessages } = useCreationStore();
  const [initialLoad, setInitialLoad] = useState(false);
  const { refetch: refetchCopilotSession, isFetching } = useGetCopilotSession({
    enabled: false,
    purpose: CopilotPurpose.POSTCREATION,
    postId: postData?.id,
  });
  const [isCopilotSessionFetching, setIsCopilotSessionFetching] =
    useState(false);
  useEffect(() => {
    if (
      !copilotSessionId &&
      postData?.id &&
      !isCopilotSessionFetching &&
      briefMessages.length > 0
    ) {
      setIsCopilotSessionFetching(true);
      refetchCopilotSession().then((res) => {
        if (res.isError) {
          setSessionErrored(true);
        } else {
          //@ts-ignore
          setCopilotSessionId(res.data.data[0]?.id);
          setIsCopilotSessionFetching(false);
        }
      });
    }
  }, [postData]);

  useEffect(() => {
    if (postIdea && briefMessages.length > 0) {
      let isThereUserMessage = briefMessages.find(
        (item) => item.role === CopilotRole.USER
      );
      if (!isThereUserMessage) {
        sendMessage(postIdea);
        setPostIdea(null);
      }
    }
  }, [postIdea, briefMessages]);

  useEffect(() => {
    if (improveIdea && briefMessages.length > 0) {
      sendMessage(improveIdea);
      setImproveIdea(null);
    }
  }, [improveIdea, briefMessages]);

  useEffect(() => {
    if (copilotSessionId) {
      refetchCopilotMessages().then((res) => {
        if (res.isError) {
          if (postData?.id) {
            setSessionErrored(true);
          }
        } else {
          let tmp: ICopilotMessage[] = [];
          res!.data?.data?.map((item) => {
            tmp.push({
              files: item.files,
              message: item.message || "",
              role: item.role,
              copilotSessionId: item.copilotSessionId,
              agentIdentifier: item.agentIdentifier,
              agentName: CopilotAgentName[item.agentIdentifier || 1],
              agentImage: getSelectedAgents([item.agentIdentifier || 1])[0],
              version: item.version,
              type: item.type,
              data: item.data,
            });
          });
          setBriefMessages(tmp);
          setInitialLoad(true);
        }
      });
    }
  }, [copilotSessionId]);

  return { initialLoad };
};
