import { usePostCopilotChatMessage } from "../../../../../api/copilot/chat/post";
import { usePostFile } from "../../../../../api/library/files/post";
import { useCreationStore } from "../../../../../store/creationStore";
import { ICopilotMessage } from "../../../../../utils/copilot-base/ICopilotBase";
import {
  CopilotAgentName,
  CopilotRole,
  SocialNetworkType,
  SocialNetworkTypeName,
} from "../../../../../utils/enums/Enums";
import { ISingleFIleVM } from "../../../../../utils/interfaces/IAssets";
import { getSelectedAgents } from "../../../../newContent/functions/getSelectedAgents";
import { useCreationHelperFunctions } from "../../../hooks/useCreationHelperFunctions";
import { useCreationLibraryHelperFunctions } from "../../Media/hooks/useCreationLibraryHelperFunctions";
import { useUpdateBriefMessages } from "./useUpdateBriefMessages";

export const useBriefHelperFunctions = ({
  setDisable,
  setChatResponse,
  setValue,
  setFiles,
  files,
}: {
  setFiles?: React.Dispatch<React.SetStateAction<File[] | null>>;
  files: File[] | null;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  setChatResponse: React.Dispatch<React.SetStateAction<string>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const {
    copilotSessionId,
    briefMessages,
    setBriefMessages,
    setIsImageUploadingInChat,
    setIsSendingMessage,
    setCopilotAgentLoadingState,
    setCopilotAgentLoading,
  } = useCreationStore();
  const { insertMediaForAllSocialNetworkType } =
    useCreationLibraryHelperFunctions();
  const { getSocialNetworkNameByType, getActiveSocialNetworkPost } =
    useCreationHelperFunctions();
  const { mutateAsync: postMessage } = usePostCopilotChatMessage();
  const { updateLoadingMessage } = useUpdateBriefMessages();
  const { mutateAsync: createFile } = usePostFile();

  const sendMessage = async (text: string, storyUpdated: boolean = false) => {
    // if (text.length < 2) return;
    setIsSendingMessage(true);
    setDisable(true);
    setChatResponse("");
    setCopilotAgentLoadingState(null);
    setCopilotAgentLoading(null);
    let uploadedFiles: ISingleFIleVM[] = [];
    if (files && files.length > 0) {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      setIsImageUploadingInChat(true);
      await createFile({ files: formData }).then((res) => {
        uploadedFiles = res.data.uploadedFiles;
        setIsImageUploadingInChat(false);
      });
    }
    setValue("");
    const userMessage: ICopilotMessage = {
      role: CopilotRole.USER,
      content: {
        Type: "UserMessage",
        Message: text,
      },
      files: uploadedFiles.length > 0 ? uploadedFiles : [],
      copilotSessionId: copilotSessionId!,
      version: 1,
    };
    const aiMessage: ICopilotMessage = {
      role: CopilotRole.ASSISTANT,
      content: undefined,
      files: [],
      copilotSessionId: copilotSessionId!,
      version: 1,
    };
    let socName: undefined | number = undefined;
    // add additional content for only one social media
    if (
      getActiveSocialNetworkPost()?.text &&
      getActiveSocialNetworkPost()!.text!.length > 0
    ) {
      socName = getActiveSocialNetworkPost()!.socialNetworkType;
    }
    if (setFiles) setFiles([]);
    // if (uploadedFiles.length > 0) {
    //   uploadedFiles.map((item) => {
    //     insertMediaForAllSocialNetworkType(item, false, false, true);
    //   });
    // }
    setBriefMessages([...briefMessages, userMessage, aiMessage]);
    await postMessage({
      message: text,
      fileIds:
        uploadedFiles.length > 0
          ? (uploadedFiles.map((item) => item.id) as number[])
          : undefined,
      sessionId: copilotSessionId!,
      socialNetworkType: socName,
    })
      .then(async (res) => {
        const toolItem = res.data.data.find(
          (item) => item.role === CopilotRole.TOOL
        );
        const aiMessages = res.data.data.filter(
          (item) => item.role === CopilotRole.ASSISTANT
        );
        let tmpMessages = [...useCreationStore.getState().briefMessages];
        if (toolItem && toolItem.files && toolItem.files.length > 0) {
          // for (const file of toolItem.files) {
          //   await insertMediaForAllSocialNetworkType(file);
          // }
          tmpMessages.splice(tmpMessages.length - 1, 0, {
            files: toolItem.files,
            content: toolItem.content,
            message: toolItem.message,
            agentName: CopilotAgentName[toolItem.agentIdentifier || 1],
            agentImage: getSelectedAgents([toolItem.agentIdentifier || 1])[0],
            textVersions: toolItem.textVersions,
            role: CopilotRole.TOOL,
            copilotSessionId: copilotSessionId!,
            version: 1,
          });
        }
        if (aiMessages.length > 0) {
          tmpMessages = updateLoadingMessage({
            obj: {
              content: aiMessages[0].content,
              message: aiMessages[0].message,
              agentName: CopilotAgentName[aiMessages[0].agentIdentifier || 1],
              agentImage: getSelectedAgents([
                aiMessages[0].agentIdentifier || 1,
              ])[0],
              role: CopilotRole.ASSISTANT,
              data: aiMessages[0].data,
              type: aiMessages[0].type,
              files: [],
              textVersions: aiMessages[0].textVersions,
              copilotSessionId: copilotSessionId!,
              version: 1,
            },
            tmpMessages: tmpMessages,
          });
          if (aiMessages.length > 1) {
            tmpMessages.push({
              content: aiMessages[1].content,
              message: aiMessages[1].message,
              agentName: CopilotAgentName[aiMessages[1].agentIdentifier || 1],
              agentImage: getSelectedAgents([
                aiMessages[1].agentIdentifier || 1,
              ])[0],
              role: CopilotRole.ASSISTANT,
              data: aiMessages[1].data,
              type: aiMessages[1].type,
              textVersions: aiMessages[1].textVersions,
              files: [],
              copilotSessionId: copilotSessionId!,
              version: 1,
            });
          }
        }
        setBriefMessages(tmpMessages);
        setDisable(false);
      })
      .catch(async (err) => {
        let tmpMessages = [...useCreationStore.getState().briefMessages];
        tmpMessages = await updateLoadingMessage({
          obj: {
            content: err.response.data.data,
            message: err.response.data.message,
            role: CopilotRole.ASSISTANT,
            files: [],
            copilotSessionId: copilotSessionId!,
            textVersions: err.response.data.textVersions,
            version: 1,
          },
          tmpMessages: tmpMessages,
          setLast: true,
        });
        setBriefMessages(tmpMessages);
        setDisable(false);
      })
      .finally(() => {
        setIsSendingMessage(false);
      });
  };

  return { sendMessage };
};
