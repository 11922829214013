import { useEffect, useState } from "react";
import FieldInput from "../../components/account/FieldInput";
import {
  faEnvelope,
  faGlobe,
  faPhone,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../design-system/SButton";
import { useBrandStore } from "../../store/brandStore";
import DeleteAgreementModal from "../../components/brand/modals/DeleteAgreementModal";
import { closeAllToast, toast } from "../../hooks/toast";
import { usePutBrandInfo } from "../../api/brand/info/put";
import FieldSelector from "../../components/account/FieldSelector";
import { ContentLanguages } from "../../utils/enums/Enums";
import { useUpdateContentLanguage } from "../../api/brand/contentLanguage/put";
import { getCountryFlag } from "../../components/brand/functions/getCountryFlag";
import { useConnectBrandsHelpers } from "./hooks/useConnectBrandsHelpers";
import { BrandsConnectSelector } from "./BrandsConnectSelector";
import { Tooltip } from "react-tooltip";
import { useHasChanges } from "./hooks/useHasChanges";

function BrandSettings() {
  const { currentBrand, setConnectBrandIds, setDisconnectBrandIds } =
    useBrandStore();
  const { mutateAsync: putBrandInfo } = usePutBrandInfo();
  const { mutateAsync: updateContentLanguage } = useUpdateContentLanguage();
  const { saveBrandConnections } = useConnectBrandsHelpers();
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    website: "",
  });
  const [isContentLanguagesOpen, setIsContentLanguagesOpen] = useState(false);
  const [contentLanguage, setContentLanguage] = useState<number | null>(
    currentBrand ? currentBrand?.contentLanguage : ContentLanguages.ENGLISH
  );
  const { hasChanges } = useHasChanges({ values, contentLanguage });
  useEffect(() => {
    if (currentBrand) {
      setValues({
        name: currentBrand.brandingData?.brandName,
        email: currentBrand?.email || "",
        phone: currentBrand?.phone || "",
        website: currentBrand?.website || "",
      });
      setContentLanguage(currentBrand?.contentLanguage);
    }
  }, [currentBrand]);

  const handleSubmit = async () => {
    if (!currentBrand) return;
    if (hasChanges.connections) {
      await saveBrandConnections()
        .then((res) => {
          res && toast("Successfully updated brand connections!", "success");
          setConnectBrandIds([]);
          setDisconnectBrandIds([]);
        })
        .catch((error) => {
          toast(error.response.data.message, "error");
        });
    }
    if (hasChanges.brandingData) {
      await putBrandInfo({
        brandId: currentBrand.id,
        contactData: {
          email: values.email.length > 0 ? values.email : null,
          phone: values.phone.length > 0 ? values.phone : null,
          website: values.website.length > 0 ? values.website : null,
          name: values.name,
        },
      })
        .then((res) => {
          toast("Brand info updated", "success");
        })
        .catch((error) => {
          toast(error.response.data.message, "error");
        });
    }

    if (hasChanges.contentLanguage) {
      toast("Updating content language...", "loading");
      updateContentLanguage({
        brandId: currentBrand.id,
        contentLanguageId: contentLanguage,
      })
        .then(() => {
          closeAllToast();
          toast("Content language updated!", "success");
        })
        .catch((error) => {
          toast(error.response.data.message, "error");
        });
    }
  };
  const [isDeleteAgreementModalOpen, setIsDeleteAgreementModalOpen] =
    useState(false);

  return (
    <>
      <DeleteAgreementModal
        isOpen={isDeleteAgreementModalOpen}
        setIsOpen={setIsDeleteAgreementModalOpen}
      />
      <div>
        <FieldInput
          fieldName="Brand Name"
          inputProps={{
            placeholder: "Brand name",
            value: values.name,
            onChange: (e) => {
              setValues({ ...values, name: e.target.value });
            },
          }}
        />
        <FieldInput
          fieldName="Email address"
          inputProps={{
            icon: faEnvelope,
            placeholder: "Email address",
            value: values.email,
            onChange: (e) => {
              setValues({ ...values, email: e.target.value });
            },
          }}
        />
        <FieldInput
          fieldName="Phone number"
          inputProps={{
            icon: faPhone,
            placeholder: " Phone number",
            value: values.phone,
            onChange: (e) => {
              setValues({ ...values, phone: e.target.value });
            },
          }}
        />
        <FieldInput
          fieldName="Website"
          inputProps={{
            icon: faGlobe,
            placeholder: "Brand website",
            value: values.website,
            onChange: (e) => {
              setValues({ ...values, website: e.target.value });
            },
          }}
        />
        <FieldSelector
          fieldName="Content Language"
          toolTipContent={"This will affect every AI generated content."}
          selectorProps={{
            selected: contentLanguage,
            setSelected: setContentLanguage,
            items: (
              Object.keys(ContentLanguages) as Array<
                keyof typeof ContentLanguages
              >
            )
              .filter((key) => typeof ContentLanguages[key] === "number")
              .map((key) => {
                return {
                  index: ContentLanguages[key],
                  text: key.toLocaleLowerCase(),
                  avatar: getCountryFlag(key),
                };
              }),
            isOpen: isContentLanguagesOpen,
            setIsOpen: setIsContentLanguagesOpen,
            id: "content-languages-dropdown",
            excludedIds: ["content-languages-dropdown"],
            image: getCountryFlag(
              contentLanguage ? ContentLanguages[contentLanguage] : "ENGLISH"
            ),
            className:
              "absolute w-1/2 h-[250px] bottom-[calc(100%+5px)] shadow-xl",
          }}
        />
        <BrandsConnectSelector />

        {/* <FieldSelector fieldName="Timezone" selectorProps={undefined}  /> */}
        <div className="flex justify-between mt-[20px]">
          <div className="flex gap-[12px]">
            {!hasChanges.hasChanges && (
              <Tooltip className="z-[200]" id="save-changes-tooltip">
                Make changes first to save
              </Tooltip>
            )}
            <SButton
              disabled={!hasChanges.hasChanges}
              size="sm"
              lIcon={hasChanges.hasChanges ? undefined : faQuestionCircle}
              onClick={handleSubmit}
              data-tooltip-id="save-changes-tooltip"
            >
              Save Changes
            </SButton>
          </div>
          <SButton
            type="tertiaryGray"
            className="text-white ml-auto !bg-error-600 hover:!bg-error-700 hover:text-white"
            size="sm"
            onClick={() => setIsDeleteAgreementModalOpen(true)}
          >
            Delete Brand
          </SButton>
        </div>
      </div>
    </>
  );
}

export default BrandSettings;
