import { useState } from "react";
import PageHeading from "../components/PageHeading";
import { ITabItem } from "../design-system/STertiaryTabs";
import WorkspaceOverview from "../components/workspace/WorkspaceOverview";
import WorkspaceMembers from "../components/workspace/WorkspaceMembers";
import { useNavigate } from "react-router-dom";
import { useGetUser } from "../hooks/useUserInfo";
import { useGetCurrentPackage } from "../api/subscriptions/current/get";
import { useGetWorkspaceUsage } from "../api/workspaces/limits/get";
import SButton from "../design-system/SButton";
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import { BrandInviteMemberModal } from "../components/brand/BrandInviteMemberModal";
import Billing from "./Billing";
import { useGetCurrentWorkspace } from "../api/workspaces/current/get";
import { OrganizationUserRole } from "../utils/enums/Enums";
import { BillingInfo } from "../components/billing/BillingInfo";
import { useSetDefaultWorkspaceSection } from "../components/workspace/hooks/useSetDefaultWorkspaceSection";
import WorkspaceSettingsHeader from "../components/workspace/WorkspaceSettingsHeader";

const getBySelected = (selected: number, arr: string[]) => {
  switch (selected) {
    case 0:
      return arr[0];
    case 1:
      return arr[1];
    case 2:
      return arr[2];
    default:
      return arr[0];
  }
};

const Workspace = () => {
  const { data: currentWorkspace } = useGetCurrentWorkspace();
  const { data: userInfo } = useGetUser();
  const { data: currentPlan } = useGetCurrentPackage();
  const { data: limits } = useGetWorkspaceUsage();

  const [selected, setSelected] = useState(0);
  const tabItems: ITabItem[] = [
    {
      index: 0,
      text: "Overview",
    },
    {
      index: 1,
      text: "Members",
    },
    ...(userInfo?.data.data.userRole !== OrganizationUserRole.VIEWER
      ? [
          {
            index: 2,
            text: "Billing",
          },
        ]
      : []),
  ];

  useSetDefaultWorkspaceSection({ setSelected });
  const [inviteModal, setInviteModal] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="w-full flex-col gap-[24px] p-4">
      {inviteModal &&
        userInfo?.data.data.userRole !== OrganizationUserRole.VIEWER && (
          <BrandInviteMemberModal
            inviteModal={inviteModal}
            setInviteModal={setInviteModal}
            isWorkspaceOnly={true}
          />
        )}
      <WorkspaceSettingsHeader />
      {currentPlan && <BillingInfo className="mb-4" />}
      <PageHeading
        title={getBySelected(selected, [
          `${currentWorkspace?.data.name} Overview`,
          "Workspace Members",
          "Workspace Billing",
        ])}
        subtitle={getBySelected(selected, [
          "Manage your workspace utilization.",
          "Manage workspace members and their permissions.",
          "Manage your subscription.",
        ])}
        tabsProps={{
          items: tabItems,
          selected: selected,
          setSelected: (i) => {
            setSelected(i);
            navigate(
              "/workspace?subsection=" +
                tabItems[i as number].text?.toLocaleLowerCase()
            );
          },
          rItems: (
            <>
              {selected === 1 &&
                userInfo?.data.data.userRole !==
                  OrganizationUserRole.VIEWER && (
                  <SButton
                    onClick={() => setInviteModal(true)}
                    type={"secondaryColor"}
                    size={"md"}
                    lIcon={faUserPlus}
                  >
                    Add workspace member
                  </SButton>
                )}
            </>
          ),
        }}
        className="pb-[24px]"
      />
      {selected === 0 && (
        <WorkspaceOverview
          setInviteModal={setInviteModal}
          isViewer={
            userInfo?.data.data.userRole === OrganizationUserRole.VIEWER
          }
          currentPlan={currentPlan}
          limits={limits}
        />
      )}
      {selected === 1 && (
        <WorkspaceMembers
          isViewer={
            userInfo?.data.data.userRole === OrganizationUserRole.VIEWER
          }
        />
      )}
      {selected === 2 &&
        userInfo?.data.data.userRole !== OrganizationUserRole.VIEWER && (
          <Billing />
        )}
    </div>
  );
};

export default Workspace;
