import SBadge from "../../design-system/SBadge";
import React, { useState } from "react";
import TextsSectionSkeleton from "./TextsSectionSkeleton";
import { useBrandStore } from "../../store/brandStore";
import SButton from "../../design-system/SButton";
import { faSparkles } from "@fortawesome/pro-regular-svg-icons";
import { useBrandHelperFunctions } from "./hooks/useBrandHelperFunctions";
import { BrandingSectionName } from "../../utils/interfaces/IBrand";
import { BrandConfirmRegenerateSections } from "./BrandConfirmRegenerateSections";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import { useAccountStore } from "../../store/accountStore";
import { toast } from "../../hooks/toast";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

interface ITextsSectionProps {
  title: string;
  subtitle: string;
  texts: IBrandSectionTextsProps[];
  isLoading: boolean;
  loadingCount: number;
  fieldName: BrandingSectionName;
  icon?: IconProp;
}

export interface IBrandSectionTextsProps {
  title?: string;
  content?: string;
  tags?: string[] | string[][];
  key: string;
  showBorder?: boolean;
  isTitleEditable?: boolean;
  handleChangeText?: (text: string) => void;
  handleChangeTitle?: ({ text, sKey }: { text: string; sKey: string }) => void;
  handleChangeTag?: ({
    parentIndex,
    text,
    index,
    sKey,
  }: {
    parentIndex?: number;
    text: string;
    index: number;
    sKey: string;
  }) => void;
}

function TextsSection({
  isLoading,
  title,
  subtitle,
  texts,
  loadingCount,
  fieldName,
  icon,
}: ITextsSectionProps) {
  const { handleRegenerateSection } = useBrandHelperFunctions();
  const [confirmRegenerate, setConfirmRegenerate] = useState<
    BrandingSectionName | false
  >(false);
  const { personalInfo } = useAccountStore();
  const { currentBrand } = useBrandStore();
  return (
    <div className="group bg-base-white border-[1px] rounded-[8px] border-gray-200">
      {confirmRegenerate !== false && (
        <BrandConfirmRegenerateSections
          openModal={confirmRegenerate}
          setOpenModal={setConfirmRegenerate}
          onConfirm={() => {
            confirmRegenerate && handleRegenerateSection(confirmRegenerate);
            setConfirmRegenerate(false);
          }}
        />
      )}
      <div className="bg-gray-50 rounded-t-[8px] px-[32px] py-[20px] gap-[5px] flex justify-between items-center max-md:py-[16px] max-md:px-[20px] max-md:gap-[15px]">
        <div>
          <div className="flex items-center gap-3">
            {icon && (
              <FontAwesomeIcon icon={icon} className="text-brand-500 text-xl" />
            )}
            <p className="display-xs-sb text-graycool-800">{title}</p>
          </div>
          <p className="text-md text-graycool-600 mt-[2px]">{subtitle}</p>
        </div>
        {personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
          <SButton
            onClick={() => setConfirmRegenerate(fieldName)}
            lIcon={faSparkles}
            type="secondaryColor"
            className="group-hover:opacity-100 group-hover:pointer-events-auto opacity-0 pointer-events-none max-md:opacity-100 max-md:pointer-events-auto"
          />
        )}
      </div>
      {!currentBrand?.isBrandDataGenerated || isLoading ? (
        <TextsSectionSkeleton count={loadingCount} />
      ) : (
        <div className="px-[32px] rounded-b-[8px] bg-base-white max-md:px-[20px]">
          {texts.map((item, index) => (
            <div
              key={index}
              className={`${item.showBorder && "border-b-[1px]"} border-b-graycool-100 ${!item.title ? "py-[16px]" : "py-[24px]"} max-md:py-[16px]`}
            >
              <div className="flex items-center gap-2">
                {item.title && (
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="text-brand-600 text-[8px]"
                  />
                )}
                <p
                  className="text-lg-sb text-gray-900"
                  contentEditable={
                    item.isTitleEditable &&
                    personalInfo?.userRole !== OrganizationUserRole.VIEWER
                  }
                  onBlur={(e) => {
                    if (e.target.innerText.trim().length === 0 && item.title) {
                      toast("Title cannot be empty", "error");
                      e.target.innerText = item.title;
                      return;
                    }
                    item.handleChangeTitle &&
                      item.handleChangeTitle({
                        text: e.target.innerText,
                        sKey: item.key,
                      });
                  }}
                  onPaste={(event) => {
                    event.preventDefault();
                    const text = event.clipboardData.getData("text/plain");
                    document.execCommand("insertText", false, text);
                  }}
                >
                  {item.title}
                </p>
              </div>
              <div className="flex items-start gap-2">
                {!item.title && (
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="text-brand-600 translate-y-3 text-[8px]"
                  />
                )}
                <p
                  className="outline-none text-md text-graycool-600 mt-[4px] focus:underline"
                  contentEditable={
                    personalInfo?.userRole !== OrganizationUserRole.VIEWER
                  }
                  onBlur={(e) => {
                    if (e.target.innerText.trim().length === 0) {
                      toast("Text cannot be empty", "error");
                      e.target.innerText = item.content as string;
                      return;
                    }
                    item.handleChangeText &&
                      item.handleChangeText(e.target.innerText);
                  }}
                  onPaste={(event) => {
                    event.preventDefault();
                    const text = event.clipboardData.getData("text/plain");
                    document.execCommand("insertText", false, text);
                  }}
                >
                  {item.content}
                </p>
              </div>
              {item.tags && (
                <div
                  className={`${
                    Array.isArray(item.tags[0]) && "flex-col"
                  } flex flex-wrap gap-[8px] mt-[16px]`}
                >
                  {Array.isArray(item.tags[0])
                    ? (item.tags as string[][]).map((group, groupIndex) => (
                        <div
                          key={groupIndex}
                          className="flex flex-wrap gap-[8px]"
                        >
                          {group.map((tag, index) => (
                            <p
                              key={index}
                              className="outline-none bg-base-white border-[1px] border-gray-300 rounded-[6px] text-sm-m text-gray-700 px-[12px] py-[4px] shadow-none"
                              contentEditable={
                                personalInfo?.userRole !==
                                OrganizationUserRole.VIEWER
                              }
                              onPaste={(event) => {
                                event.preventDefault();
                                const text =
                                  event.clipboardData.getData("text/plain");
                                document.execCommand("insertText", false, text);
                              }}
                              onBlur={(e) => {
                                if (e.target.innerText.trim().length === 0) {
                                  toast("Tag cannot be empty", "error");
                                  e.target.innerText = tag;
                                  return;
                                }
                                item.handleChangeTag &&
                                  item.handleChangeTag({
                                    sKey: item.key,
                                    text: e.target.innerText,
                                    index: index,
                                    parentIndex: groupIndex,
                                  });
                              }}
                            >
                              {tag}
                            </p>
                          ))}
                        </div>
                      ))
                    : (item.tags as string[]).map((tag, index) => (
                        <p
                          key={index}
                          className="outline-none bg-base-white border-[1px] border-gray-300 rounded-[6px] text-sm-m text-gray-700 px-[12px] py-[4px] shadow-none"
                          contentEditable={
                            personalInfo?.userRole !==
                            OrganizationUserRole.VIEWER
                          }
                          onPaste={(event) => {
                            event.preventDefault();
                            const text =
                              event.clipboardData.getData("text/plain");
                            document.execCommand("insertText", false, text);
                          }}
                          onBlur={(e) => {
                            if (e.target.innerText.trim().length === 0) {
                              toast("Tag cannot be empty", "error");
                              e.target.innerText = tag;
                              return;
                            }
                            item.handleChangeTag &&
                              item.handleChangeTag({
                                sKey: item.key,
                                text: e.target.innerText,
                                index: index,
                              });
                          }}
                        >
                          {tag}
                        </p>
                      ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default TextsSection;
