import React from "react";
import { ImageGenerationStyle } from "../../../../../../utils/enums/Enums";
import { stylePreviewImages } from "../../../../../../utils/constants/stylePreviewImages";

interface StylePreviewBadgeProps {
  styleId: ImageGenerationStyle;
  styleName: string;
  isActive: boolean;
  onClick?: () => void;
}

export const StylePreviewBadge = ({
  styleId,
  styleName,
  isActive,
  onClick,
}: StylePreviewBadgeProps) => {
  return (
    <div
      onClick={onClick}
      className={`h-fit relative group cursor-pointer transition-all duration-200`}
    >
      <div className="mx-auto relative w-[100px] h-[65px] rounded-lg overflow-hidden">
        <img
          src={stylePreviewImages[styleId]}
          alt={styleName}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-30 group-hover:bg-opacity-0 transition-all duration-200" />
      </div>
      {isActive && (
        <div className="absolute -inset-1 rounded-lg ring-2 ring-brand-500" />
      )}
      <div className="text-nowrap pt-2 text-gray-900 text-xs-m w-full text-center">
        {styleName}
      </div>
    </div>
  );
};
