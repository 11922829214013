import { usePostCopilotSession } from "../../../api/copilot/session/post";
import { usePostPost } from "../../../api/posts/post";
import { useCreationStore } from "../../../store/creationStore";
import { CopilotPurpose } from "../../../utils/enums/Enums";

export const useSessionHelperFunctions = () => {
  const { setPostId, setPostData, setCopilotSessionId, setActiveSoc } =
    useCreationStore();
  const { mutateAsync: createPost, isPending } = usePostPost();
  const {
    mutateAsync: createBrandChatSession,
    isPending: isBrandChatSessionPending,
  } = usePostCopilotSession();

  const socialNetworkTypes = [
    { socialNetworkType: 0 },
    { socialNetworkType: 1 },
    { socialNetworkType: 2 },
    { socialNetworkType: 3 },
    { socialNetworkType: 4 },
    { socialNetworkType: 5 },
    { socialNetworkType: 7 },
    { socialNetworkType: 8 },
    { socialNetworkType: 9 },
    { socialNetworkType: 10 },
  ];

  const handleCreateNewPost = async () => {
    const response = await createPost({
      socialNetworkPosts: socialNetworkTypes,
      story: "",
    });
    setPostId(response.data.id);
    setPostData(response.data);
    setActiveSoc(response.data.socialNetworkPosts[0].socialNetworkType);
  };

  const handleCreateNewBrandChatSession = async () => {
    const response = await createBrandChatSession({
      purposeType: CopilotPurpose.BRANDCREATION,
    });
    setCopilotSessionId(response.data.id);
  };

  return {
    handleCreateNewPost,
    isPending,
    handleCreateNewBrandChatSession,
    isBrandChatSessionPending,
  };
};
