import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SModalBase from "../../../design-system/SModalBase";
import { useCreationStore } from "../../../store/creationStore";
import { AgentSchedulePreview } from "./AgentSchedulePreview";
import {
  faAdd,
  faCircleQuestion,
  faExternalLink,
  faX,
} from "@fortawesome/pro-regular-svg-icons";
import DatePicker from "../../creation/modals/schedule/DatePicker";
import { useEffect, useState } from "react";
import TimePicker from "../../creation/modals/schedule/TimePicker";
import UpcomingPosts from "../../creation/modals/schedule/UpcomingPosts";
import SButton from "../../../design-system/SButton";
import { PostStatus, SocialNetworkType } from "../../../utils/enums/Enums";
import SCheckbox from "../../../design-system/SCheckbox";
import { useScheduleHelperFunctions } from "./hooks/useScheduleHelperFunctions";
import { useChannelsHelper } from "../../creation/Tabs/Channels/functions/useChannelsHelper";
import { usePublishing } from "../../creation/hooks/usePublishing";
import { ISocialNetworkVM } from "../../../utils/interfaces/ISocialNetworks";
import DatePickerModal from "../../creation/modals/schedule/DatePickerModal";
import TimePickerModal from "../../creation/modals/schedule/TimePickerModal";
import { formatDate } from "date-fns";
import { toast } from "../../../hooks/toast";
import RemoveScheduleAgreement from "../../creation/modals/RemoveScheduleAgreement";

export const AgentScheduleModal = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<Date>(new Date());
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
  const [selectedSocialNetwork, setSelectedSocialNetwork] = useState(
    SocialNetworkType.TWITTER
  );
  const [id, setId] = useState<number | null>(null);

  const [
    isRemoveScheduleAgreementModalOpen,
    setIsRemoveScheduleAgreementModalOpen,
  ] = useState(false);

  const {
    isScheduleModalOpen,
    setIsScheduleModalOpen,
    inAdvanceDate,
    isPublishing,
    selectedSocialNetworks,
    setSelectedSocialNetworks,
    postData,
  } = useCreationStore();

  const { getSocialNetworkLogo, getSelectedSocialNetworks, availableNetworks } =
    useScheduleHelperFunctions();

  const { handleSelect } = useChannelsHelper();
  const { handleSchedule } = usePublishing();

  useEffect(() => {
    if (inAdvanceDate) {
      setSelectedDate(inAdvanceDate);
      setSelectedTime(inAdvanceDate);
    }
  }, [inAdvanceDate]);

  return (
    <SModalBase
      isOpen={isScheduleModalOpen}
      onClose={setIsScheduleModalOpen}
      additionalModal={
        <AgentSchedulePreview selectedSocialNetwork={selectedSocialNetwork} />
      }
      dialogPanelClassName="h-[600px] w-[550px] px-0 py-0"
      showX={false}
    >
      <RemoveScheduleAgreement
        isOpen={isRemoveScheduleAgreementModalOpen}
        id={id}
        setIsOpen={setIsRemoveScheduleAgreementModalOpen}
      />
      <p className="text-gray-600 text-md-sb px-6 py-4">Schedule Post</p>
      <hr className="w-full bg-gray-200 mb-4" />
      <div className="w-full h-[calc(100%-95px)] flex flex-col gap-3 px-6">
        <div className="flex items-center gap-2">
          <p className="text-gray-900 text-sm-m">Posting to</p>
          <FontAwesomeIcon
            className="text-gray-400 text-xs"
            icon={faCircleQuestion}
          />
        </div>
        <div className="w-full flex items-center gap-2 mb-3">
          {availableNetworks()?.map((item) => {
            const post = postData?.socialNetworkPosts?.find((socPost) => {
              return socPost.socialNetworkType === item.socialNetworkType;
            });

            const isPostScheduled = () => {
              return post?.postStatus === PostStatus.SCHEDULED;
            };
            return (
              <div
                className={`w-10 [&>div]:hover:opacity-100 relative cursor-pointer h-10 flex items-center justify-center rounded-full border-2  ${selectedSocialNetwork === item.socialNetworkType ? "border-brand-500" : "border-gray-200"}`}
                onClick={() => {
                  setSelectedSocialNetwork(item.socialNetworkType);
                }}
                key={item.socialNetworkType}
              >
                <div
                  className={`absolute -top-2 -left-2 ${!getSelectedSocialNetworks().includes(item.socialNetworkType) ? "opacity-0" : "opacity-100"} transition-all`}
                >
                  {!isPostScheduled() && (
                    <SCheckbox
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      checked={getSelectedSocialNetworks().includes(
                        item.socialNetworkType
                      )}
                      onChange={() => handleSelect(item)}
                      transparent={false}
                    />
                  )}
                  {isPostScheduled() && (
                    <SButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsRemoveScheduleAgreementModalOpen(true);
                        setId(post?.id ?? null);
                      }}
                      size="sm"
                      type="secondaryColor"
                      iconProps={{ size: "sm" }}
                      className="!w-5 !h-5"
                      icon={faX}
                    />
                  )}
                </div>
                <FontAwesomeIcon
                  icon={getSocialNetworkLogo(item.socialNetworkType).icon}
                  style={{
                    color: getSocialNetworkLogo(item.socialNetworkType).color,
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="w-full flex relative items-center gap-2">
          <DatePicker
            isDatePickerOpen={isDatePickerOpen}
            selectedDate={selectedDate}
            setIsDatePickerOpen={setIsDatePickerOpen}
          />
          <TimePicker
            isTimePickerOpen={isTimePickerOpen}
            selectedTime={selectedTime}
            setIsTimePickerOpen={setIsTimePickerOpen}
          />
          {isTimePickerOpen && (
            <TimePickerModal
              selected={selectedTime}
              setSelected={setSelectedTime}
              setIsTimePickerOpen={setIsTimePickerOpen}
            />
          )}
          {isDatePickerOpen && (
            <DatePickerModal
              selected={selectedDate}
              setSelectedTime={setSelectedTime}
              setSelected={setSelectedDate}
              setIsDatePickerOpen={setIsDatePickerOpen}
            />
          )}
        </div>
        <p className="text-gray-600 text-sm mb-2">
          Post will be scheduled according to your current timezone
        </p>
        <UpcomingPosts selectedDate={new Date()} />
        <div className="flex mt-auto items-center justify-end gap-2">
          {/* <SButton
            isLoading={isPublishing === "publishing"}
            disabled={
              selectedSocialNetworks.length === 0 || isPublishing
                ? isPublishing !== "publishing"
                : false
            }
            onClick={() => handlePublishNow()}
            type="secondaryGray"
          >
            Post Now
          </SButton> */}
          <SButton
            isLoading={isPublishing === "scheduling"}
            disabled={
              selectedSocialNetworks.length === 0 || isPublishing
                ? isPublishing !== "scheduling"
                : false
            }
            onClick={() => {
              if (
                selectedTime < new Date() &&
                formatDate(selectedDate, "yyyy-MM-dd").toString() ===
                  formatDate(new Date(), "yyyy-MM-dd").toString()
              ) {
                toast("Please select a future time", "error");
                return;
              }
              if (
                formatDate(selectedDate, "yyyy-MM-dd") <
                formatDate(new Date(), "yyyy-MM-dd")
              ) {
                toast("Please select a future date", "error");
                return;
              }
              handleSchedule({ date: selectedDate, time: selectedTime }).then(
                (res) => {
                  toast("Post scheduled successfully", "success");
                  setSelectedSocialNetworks([]);
                }
              );
            }}
          >
            Schedule
          </SButton>
        </div>
      </div>
    </SModalBase>
  );
};
