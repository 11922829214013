import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ICopilotSessionVM } from "../../../utils/interfaces/ICopilot";
import {
  CopilotAgentIdentifier,
  CopilotPurpose,
} from "../../../utils/enums/Enums";
import { makeRequest } from "../../auth/axios";

export const useGetCopilotSession = ({
  purpose,
  enabled = true,
  postId,
  agentIdentifier,
}: {
  postId?: number;
  purpose: CopilotPurpose;
  enabled?: boolean;
  agentIdentifier?: CopilotAgentIdentifier | null;
}) => {
  return useQuery<IBaseData<ICopilotSessionVM>>({
    queryKey: ["useGetCopilotSessions", postId, purpose, agentIdentifier],
    queryFn: async () => {
      const response = await makeRequest.get(
        `/Copilot?purpose=${purpose}${postId ? `&postId=${postId}` : ""}${agentIdentifier ? `&agentIdentifier=${agentIdentifier}` : ""}`
      );
      return response as IBaseData<ICopilotSessionVM>;
    },
    retry: 0,
    enabled: enabled,
  });
};
