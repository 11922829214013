import React, { useRef } from "react";
import Logo from "./Logo";
import { useBrandStore } from "../../../store/brandStore";
import {
  OrganizationType,
  OrganizationUserRole,
} from "../../../utils/enums/Enums";
import { usePutBrandInfo } from "../../../api/brand/info/put";
import { toast } from "../../../hooks/toast";
import { useAccountStore } from "../../../store/accountStore";

function MainInfo({ isOnSidebar }: { isOnSidebar: boolean }) {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: putBrandInfo } = usePutBrandInfo();
  const textRef = useRef<HTMLParagraphElement>(null);
  const { personalInfo } = useAccountStore();
  const saveData = () => {
    currentBrand &&
      currentBrand.brandingData?.brandName !== textRef.current?.innerText &&
      putBrandInfo({
        brandId: currentBrand.id,
        contactData: {
          name: textRef.current?.innerText as string,
          phone: currentBrand.phone as string | null,
          website: currentBrand.website as string | null,
          email: currentBrand.email as string | null,
        },
      })
        .then((res) => toast("Brand name updated successfully", "success"))
        .catch((err) => toast("Error updating brand name", "error"));
  };

  return (
    <div className="rounded-[8px] border-[1px] border-gray-20 max-md:flex-col">
      <div
        className={`flex items-center rounded-t-[8px] w-full gap-2  bg-gray-100 max-md:py-[16px] max-md:px-[20px] ${
          isOnSidebar ? "px-[32px] py-[16px]" : "px-[32px] py-[33px]"
        }`}
      >
        <p
          ref={textRef}
          contentEditable={
            personalInfo?.userRole !== OrganizationUserRole.VIEWER
          }
          onBlur={saveData}
          onPaste={(event) => {
            event.preventDefault();
            const text = event.clipboardData.getData("text/plain");
            document.execCommand("insertText", false, text);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              saveData();
            }
          }}
          className="display-xs-b text-graycool-800"
        >
          {currentBrand?.brandingData?.brandName}
        </p>
        {/*<p className="text-md text-graycool-600 mt-[2px]">‎</p>*/}
      </div>
      {!isOnSidebar && <Logo />}
    </div>
  );
}

export default MainInfo;
