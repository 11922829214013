import { useEffect, useState } from "react";
import { useBrandStore } from "../../../store/brandStore";
import TextsSection, { IBrandSectionTextsProps } from "../TextsSection";
import { toast } from "../../../hooks/toast";
import { usePutBrand } from "../../../api/brand/put";
import _ from "lodash";
import { faBook } from "@fortawesome/pro-regular-svg-icons";

export const RelevantTopics = () => {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  const [relevantTopics, setRelevantTopics] = useState<
    IBrandSectionTextsProps[]
  >([]);

  useEffect(() => {
    if (
      currentBrand?.brandingData?.relevantTopics &&
      currentBrand.brandingData.relevantTopics.length > 0
    ) {
      let tmpRelevantTopics: IBrandSectionTextsProps[] = [];
      currentBrand.brandingData.relevantTopics.forEach((item, index) => {
        tmpRelevantTopics.push({
          isTitleEditable: false,
          key: `relevant-topic-${index}`,
          content: item,
          showBorder:
            index !== currentBrand.brandingData.relevantTopics.length - 1,
          handleChangeText: (text: string) => {
            if (
              currentBrand &&
              currentBrand.brandingData.relevantTopics[index] !== text
            ) {
              let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
              tmpBrandingData.relevantTopics[index] = text;
              updateBrand({
                brandId: currentBrand.id,
                brandingData: tmpBrandingData,
              })
                .then(() => {
                  toast("Relevant topic updated successfully", "success");
                })
                .catch((err) => {
                  toast(
                    `Failed to update relevant topic, ${err.response.data.message}`,
                    "error"
                  );
                });
            }
          },
        });
      });
      setRelevantTopics(tmpRelevantTopics);
    }
  }, [currentBrand]);

  return (
    <TextsSection
      fieldName="Relevant Topics"
      loadingCount={3}
      title="Relevant Topics"
      subtitle=""
      isLoading={!currentBrand?.brandingData?.relevantTopics}
      texts={relevantTopics}
    />
  );
};
