import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCreationStore } from "../../../../../store/creationStore";
import { useGifs } from "./hooks/useGifs";
//@ts-ignore
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import {
  faRefresh,
  faSpinner,
  faVolume,
  faVolumeMute,
} from "@fortawesome/pro-regular-svg-icons";
import { KlipyType } from "../../../../../utils/interfaces/IKlipyGifs";
import { useState } from "react";
import SButton from "../../../../../design-system/SButton";
//@ts-ignore
import Travolta from "../../../../../assets/gifs/travolta.gif";
import { useFramesStore } from "../../../../../store/framesStore";

export const GifsView = ({
  gifSearch,
  type,
}: {
  gifSearch: string;
  type: KlipyType;
}) => {
  const { setWhichGifIsLoading, whichGifIsLoading } = useCreationStore();
  const { setSelectedImage } = useFramesStore();
  const { mediaList, wrapperRef, handleSubmit, isLoading, isFetching } =
    useGifs(gifSearch, type);
  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <div className="w-full h-full mt-[16px] mb-[12px]">
      <div className="relative overflow-y-auto">
        {!gifSearch && !isLoading && (
          <div className="">
            <p className="text-md-b text-gray-900 px-2 pb-3">
              Trending {type === "gif" ? "GIFs" : "Clips"}
            </p>
          </div>
        )}
        <div
          ref={wrapperRef}
          className="w-full rounded-[8px] bg-base-white h-full overflow-y-auto"
        >
          <ResponsiveMasonry
            columnsCountBreakPoints={{
              350: 2,
              900: 2,
              1200: 3,
              1440: 4,
              1960: 5,
              2200: 6,
            }}
          >
            <Masonry>
              {!isLoading &&
                mediaList.map((clip) => (
                  <>
                    {clip.type === KlipyType.GIF && (
                      <div
                        onClick={() => {
                          if (whichGifIsLoading) return;
                          setWhichGifIsLoading(clip.id);
                          handleSubmit(clip.file.md.gif.url);
                        }}
                        key={clip.blur_preview}
                        className={`relative ${whichGifIsLoading ? "cursor-default" : "cursor-pointer"} w-full h-[${clip.file.md.gif.height}px]`}
                      >
                        {clip.id === whichGifIsLoading && (
                          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-20">
                            <FontAwesomeIcon
                              icon={faSpinner}
                              size="lg"
                              className="text-white text-2xl animate-spin"
                            />
                          </div>
                        )}
                        <div className="relative w-full h-full">
                          <img
                            src={clip.blur_preview}
                            alt={clip.title || "Media"}
                            className="w-full h-full"
                          />
                          <img
                            src={clip.file.md.gif.url}
                            alt={clip.title || "Media"}
                            className="absolute top-0 left-0 w-full h-full"
                          />
                        </div>
                      </div>
                    )}
                    {clip.type === KlipyType.CLIP && (
                      <div
                        onMouseEnter={(e) => {
                          if (whichGifIsLoading) return;
                          const videoElement =
                            e.currentTarget.querySelector("video");
                          if (videoElement) {
                            videoElement.muted = isMuted;
                            videoElement.currentTime = 0;
                            videoElement.play().catch((error) => {
                              if (error.name === "NotAllowedError") {
                                videoElement.pause();
                              }
                            });
                          }
                        }}
                        onMouseLeave={(e) => {
                          const videoElement =
                            e.currentTarget.querySelector("video");
                          if (videoElement) {
                            videoElement.pause();
                            videoElement.currentTime = 0;
                          }
                        }}
                        onClick={() => {
                          if (whichGifIsLoading) return;
                          setSelectedImage(null);
                          setWhichGifIsLoading(clip.url);
                          handleSubmit(clip.file.mp4);
                        }}
                        key={clip.blur_preview}
                        className={`group relative ${
                          whichGifIsLoading
                            ? "cursor-default"
                            : "cursor-pointer"
                        } w-full h-[${clip.file_meta.gif.height}px]`}
                        style={{ position: "relative" }}
                      >
                        {clip.url === whichGifIsLoading && (
                          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-20">
                            <FontAwesomeIcon
                              icon={faSpinner}
                              size="lg"
                              className="text-white text-2xl animate-spin"
                            />
                          </div>
                        )}
                        <div className="group-hover:opacity-50 opacity-0 bg-gradient-to-b from-black to-transparent transition-bg z-20 absolute top-0 left-0 w-full h-full">
                          <SButton
                            className="absolute top-2 right-2 z-60"
                            type="secondaryGray"
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleMute();
                            }}
                            icon={isMuted ? faVolumeMute : faVolume}
                          />
                        </div>
                        {clip.blur_preview && (
                          <div className="relative w-full h-full">
                            <img
                              src={clip.blur_preview}
                              alt={clip.title || "Media"}
                              className="w-full h-full"
                            />
                            <video
                              src={clip.file.mp4}
                              muted={isMuted}
                              disablePictureInPicture
                              className="absolute top-0 left-0 w-full h-full"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </>
                ))}
            </Masonry>
          </ResponsiveMasonry>
          {isLoading && (
            <div className="w-full h-full flex justify-center items-center">
              <FontAwesomeIcon
                icon={faRefresh}
                className="text-2xl animate-spin text-gray-900"
              />
            </div>
          )}
          {!isLoading && !isFetching && mediaList.length === 0 && (
            <div className="w-full h-full flex justify-center items-center flex-col gap-4">
              <img
                src={Travolta}
                className="w-[150px] h-[100px] rounded-lg object-cover"
                alt="Not Found!"
              />
              <div className="flex flex-col items-center">
                <p className="text-lg-b text-gray-900">Nothing Found!</p>
                <p className="text-md text-gray-900">
                  Try searching something else.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
