export const RecentsSkeleton = () => {
  return (
    <div className="flex px-3 animate-pulse py-[10px] w-full gap-1 items-center justify-between">
      <div className="flex gap-2 items-center">
        <div className="rounded-full w-[50px] h-[50px] bg-gray-200" />
        <div className="flex flex-col gap-1">
          <div className="w-[120px] h-4 rounded bg-gray-200" />
          <div className="w-[90px] h-4 rounded bg-gray-200" />
        </div>
      </div>
      <div className="w-[170px] h-[80px] rounded-xl bg-gray-200" />
    </div>
  );
};
