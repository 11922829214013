import { ImageGenerationStyle } from "../enums/Enums";

import photoRealistic from "../../assets/images/style-previews/photorealistic.png";
import facePortrait from "../../assets/images/style-previews/face-portrait.png";
import popArt from "../../assets/images/style-previews/pop-art.png";
import cartoon from "../../assets/images/style-previews/cartoon.png";
import splatterPaint from "../../assets/images/style-previews/splatter-paint.png";
import blogIllustration from "../../assets/images/style-previews/blog-illustration.png";
import minimalLineArt from "../../assets/images/style-previews/minimal-line-art.png";
import neon from "../../assets/images/style-previews/neon.png";
import animatedCharacter from "../../assets/images/style-previews/animated-character.png";
import painting from "../../assets/images/style-previews/painting.png";

export const stylePreviewImages: Record<ImageGenerationStyle, string> = {
  [ImageGenerationStyle.NONE]: "",
  [ImageGenerationStyle.Photorealistic]: photoRealistic,
  [ImageGenerationStyle.FacePortrait]: facePortrait,
  [ImageGenerationStyle.PopArt]: popArt,
  [ImageGenerationStyle.Cartoon]: cartoon,
  [ImageGenerationStyle.SplatterPaint]: splatterPaint,
  [ImageGenerationStyle.BlogIllustration]: blogIllustration,
  [ImageGenerationStyle.MinimalLineArt]: minimalLineArt,
  [ImageGenerationStyle.Neon]: neon,
  [ImageGenerationStyle.AnimatedCharacter]: animatedCharacter,
  [ImageGenerationStyle.Painting]: painting,
};
