import React, { useEffect, useState } from "react";
import TextsSection, { IBrandSectionTextsProps } from "../TextsSection";
import _ from "lodash";
import { useBrandStore } from "../../../store/brandStore";
import { usePutBrand } from "../../../api/brand/put";
import { toast } from "../../../hooks/toast";
import { faLocationDot } from "@fortawesome/pro-regular-svg-icons";

function MissionStatement() {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  const [missionStatement, setMissionStatement] = useState<
    IBrandSectionTextsProps[]
  >([]);

  useEffect(() => {
    if (currentBrand?.brandingData?.missionStatement) {
      let tmpMissionStatement: IBrandSectionTextsProps[] = [];
      tmpMissionStatement.push({
        isTitleEditable: true,
        key: `mission-statement`,
        content: currentBrand.brandingData.missionStatement.paragraph,
        title: currentBrand.brandingData.missionStatement.subHeadline,
        showBorder: false,
        handleChangeText: (text: string) => {
          if (currentBrand?.brandingData?.missionStatement.paragraph !== text) {
            let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
            tmpBrandingData.missionStatement.paragraph = text;
            updateBrand({
              brandId: currentBrand.id,
              brandingData: tmpBrandingData,
            })
              .then(() => {
                toast("Mission Statement updated successfully", "success");
              })
              .catch((err) => {
                toast(
                  `Failed to update Mission Statement, ${err.response.data.message}`,
                  "error"
                );
              });
          }
        },
        handleChangeTitle: ({ text, sKey }: { text: string; sKey: string }) => {
          if (
            currentBrand?.brandingData?.missionStatement.subHeadline !== text
          ) {
            let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
            tmpBrandingData.missionStatement.subHeadline = text;
            updateBrand({
              brandId: currentBrand.id,
              brandingData: tmpBrandingData,
            })
              .then(() => {
                toast("Mission Statement updated successfully", "success");
              })
              .catch((err) => {
                toast(
                  `Failed to update Mission Statement, ${err.response.data.message}`,
                  "error"
                );
              });
          }
        },
      });
      setMissionStatement(tmpMissionStatement);
    }
  }, [currentBrand]);

  return (
    <>
      <TextsSection
        fieldName="Mission Statement"
        loadingCount={1}
        title="Mission Statement"
        subtitle=""
        isLoading={!currentBrand?.brandingData?.missionStatement}
        texts={missionStatement}
      />
    </>
  );
}

export default MissionStatement;
