import React from "react";
import Header from "../header/Header";
import { BillingInfo } from "../billing/BillingInfo";
import { AgentSidebar } from "../newContent/AgentSidebar";
import { useCreationStore } from "../../store/creationStore";
import { useBillingWarning } from "./hooks/useBillingWarning";

function SidebarWrapper({ children }: { children: React.ReactNode }) {
  const { selectedChatId, setSelectedChatId, setDeleteConfirmation } =
    useCreationStore();
  const { showBillingWarning } = useBillingWarning();

  return (
    <div className="flex flex-col w-full h-full">
      <div className="w-full flex gap-3">
        <div className="pl-[12px] max-md:hidden">
          <Header />
          <AgentSidebar
            setSelectedChatId={setSelectedChatId}
            selectedChatId={selectedChatId}
            setDeleteConfirmation={setDeleteConfirmation}
          />
        </div>
        <div className="w-full pr-[12px] md:pl-0 pl-[12px]">
          <div className="md:hidden">
            <Header />
          </div>
          {showBillingWarning() && (
            <div className="w-full top-[12px] mt-[12px] z-[15]">
              <BillingInfo className="relative" />
            </div>
          )}
          <div
            className={`relative w-full ${
              showBillingWarning()
                ? "h-[calc(100dvh-169px)]"
                : "md:h-[calc(100dvh-22px)] h-[calc(100dvh-90px)]"
            }  mt-[12px] mr-[12px] overflow-y-auto overflow-x-hidden rounded-xl flex gap-2`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SidebarWrapper;
