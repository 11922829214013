import {
  CopilotAgentIdentifier,
  CopilotPurpose,
} from "../../../utils/enums/Enums";
import { getSelectedAgents } from "./getSelectedAgents";

export const getPurposeImages = ({
  selectedAgent,
  selectedCopilotPurpose,
}: {
  selectedAgent: CopilotAgentIdentifier | null;
  selectedCopilotPurpose: CopilotPurpose | null;
}) => {
  if (selectedCopilotPurpose === CopilotPurpose.REGULAR) {
    return selectedAgent ? getSelectedAgents([selectedAgent]) : null;
  }

  if (
    selectedCopilotPurpose === CopilotPurpose.BRANDCREATION ||
    selectedCopilotPurpose === CopilotPurpose.BRAND
  ) {
    return getSelectedAgents([
      CopilotAgentIdentifier.BrandStrategist,
      CopilotAgentIdentifier.General,
    ]);
  }
  if (selectedCopilotPurpose === CopilotPurpose.POSTCREATION) {
    return getSelectedAgents([
      CopilotAgentIdentifier.General,
      CopilotAgentIdentifier.Copywriter,
      CopilotAgentIdentifier.BrandStrategist,
    ]);
  }

  return [];
};
