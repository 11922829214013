import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SButton from "../../design-system/SButton";
import { faPaperPlaneAlt } from "@fortawesome/pro-solid-svg-icons";
import { faCamera } from "@fortawesome/pro-regular-svg-icons";
import { useTextareaHeight } from "./hooks/useTextareaHeight";
import VoiceRecorder from "../creation/Tabs/Brief/VoiceRecorder";
import { useTextareaVoice } from "./hooks/useTextareaVoice";
import { toast } from "../../hooks/toast";
import { useCreationStore } from "../../store/creationStore";
import { Dispatch, SetStateAction, useRef } from "react";
import { useCreationHelperFunctions } from "../creation/hooks/useCreationHelperFunctions";
import { SelectFileOptions } from "../../hooks/useUploadFiles";
import UploadedFilesView from "../creation/Tabs/Brief/UploadedFilesView";
// @ts-ignore
import { MentionsInput, Mention } from "react-mentions";
import { CopilotAgentName, CopilotPurpose } from "../../utils/enums/Enums";
import { parseTaggedText } from "../creation/comments/Message";
import { useAgentsSuggestion } from "./hooks/useAgentsSuggestion";
import { getSelectedAgents } from "./functions/getSelectedAgents";
import SuggestedPrompts from "../creation/SuggestedPrompts";

export interface AgentChatTextareaProps {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  disable: boolean;
  files: File[] | null;
  setFiles: React.Dispatch<React.SetStateAction<File[] | null>>;
  sendMessage: (value: string) => void;
  selectFile: (options: SelectFileOptions) => void;
  setExpandedSingleVideoModal: Dispatch<SetStateAction<boolean>>;
  setExpandedSingleImageModal: Dispatch<SetStateAction<string | null>>;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
}

export const AgentChatTextarea = ({
  value,
  setValue,
  files,
  disable,
  setFiles,
  selectFile,
  sendMessage,
  setExpandedSingleVideoModal,
  setExpandedSingleImageModal,
  textareaRef,
}: AgentChatTextareaProps) => {
  const { transcript, resetTranscript, listening, lastState, setLastState } =
    useTextareaVoice(setValue);
  const { setSuggestedPrompts, chatAgents, selectedChatId, selectedTab } =
    useCreationStore();
  const lastKeyPressRef = useRef<string | null>(null);

  const handleSend = () => {
    if (value && value.trim().length > 0) {
      const parsedValue = parseTaggedText(value);
      value = parsedValue
        .map((part) => (typeof part === "string" ? part : `@${part.name}`))
        .join("");
      if (files && files.length > 5) {
        toast("You can't upload more than 5 images", "error");
        return;
      }
      setSuggestedPrompts([]);
      sendMessage(value);
    } else if (files && files.length > 0) {
      sendMessage(value);
      setSuggestedPrompts([]);
    }
    setValue("");
  };
  const { isPostPublishedOrScheduled } = useCreationHelperFunctions();
  const { getChatAgents, mentionId, setMentionId, mentionPosition } =
    useAgentsSuggestion({
      chatAgents,
      textareaRef,
      value,
    });

  const { selectedCopilotPurpose } = useCreationStore();
  return (
    <div className="w-full relative max-w-full flex z-20 flex-col focus-within:border-gray-200 border border-gray-100 bg-gray-50">
      {selectedChatId === "general" && selectedTab === 0 && (
        <SuggestedPrompts
          setValue={setValue}
          files={files}
          sendMessage={sendMessage}
          disable={disable}
        />
      )}
      <MentionsInput
        id="create-brand-chat-textarea"
        inputRef={textareaRef}
        trigger={"@"}
        value={value}
        onBlur={() => {
          setMentionId(null);
        }}
        disabled={disable}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setValue(e.target.value);
        }}
        placeholder={
          selectedCopilotPurpose === CopilotPurpose.REGULAR
            ? "Message"
            : "Message or @mention"
        }
        onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
          lastKeyPressRef.current = e.code;
          if (e.shiftKey && e.key === "Enter") return;
          if (e.code === "Enter") {
            e.preventDefault();
            handleSend();
            resetTranscript();
          }
        }}
        className="w-full max-h-[300px] motionsInputChat p-4 bg-transparent resize-none !border-none !outline-none focus-visible:!outline-[0] focus:!ring-0"
        customSuggestionsContainer={(children: React.ReactNode) => {
          if (mentionId === null) {
            setMentionId(chatAgents[0]);
          }
          return (
            <div className="rounded-[12px] shadow-lg bg-base-white absolute overflow-hidden bottom-[calc(100%+24px)] min-w-[200px] w-full z-[100]">
              {children}
            </div>
          );
        }}
      >
        <Mention
          appendSpaceOnAdd
          onAdd={() => {
            setMentionId(null);
          }}
          renderSuggestion={(suggestion: any) => (
            <div
              onMouseEnter={() => {
                setMentionId(suggestion.id);
              }}
              className={`px-[12px] flex items-center gap-2 py-[12px] z-[100] truncate text-sm-m ${mentionId === suggestion.id ? "bg-gray-100" : "hover:bg-gray-100"}  text-gray-700`}
            >
              <img
                alt={suggestion.display + " agent"}
                src={getSelectedAgents([suggestion.id])[0]}
                className="w-5 h-5 rounded-full"
              />
              {suggestion.display}
            </div>
          )}
          markup={`@{
                  "name": "__display__",
                  "id": "__id__"
                }`}
          className="mention-item-chat"
          data={
            chatAgents.length > 0
              ? getChatAgents()
              : [{ id: 0, display: "No agents" }]
          }
        />
      </MentionsInput>
      {mentionId && (
        <span
          className="z-[100] absolute bg-brand-100 text-gray-500"
          style={{
            top: mentionPosition.top - 14,
            left: mentionPosition.left - 197,
          }}
        >
          {CopilotAgentName[mentionId]}
        </span>
      )}

      <div className="flex items-center justify-end gap-2 p-2">
        <div className="mr-auto py-2">
          <UploadedFilesView
            setIsExpandedImageModalOpen={setExpandedSingleImageModal}
            setIsExpandedVideoModalOpen={setExpandedSingleVideoModal}
            files={files}
            setFiles={setFiles}
          />
        </div>
        <div
          className={`w-[40px] h-[40px] rounded-[8px] flex justify-center items-center bg-transparent cursor-pointer text-gray-500`}
          onClick={() => {
            if (isPostPublishedOrScheduled()) return;
            selectFile({ multiple: true, accept: "media" });
          }}
        >
          <FontAwesomeIcon icon={faCamera} />
        </div>
        <VoiceRecorder
          inputRef={textareaRef}
          value={value}
          setValue={setValue}
          setLastState={setLastState}
          lastState={lastState}
          listening={listening}
          transcript={transcript}
          resetTranscript={resetTranscript}
          additionalFunction={handleSend}
        />
        <SButton
          className=""
          type={"secondaryColor"}
          data-tooltip-id="brief-send-message-button"
          size="lg"
          onClick={() => {
            handleSend();
            resetTranscript();
          }}
          style={{
            border: "none",
            background: "none",
          }}
        >
          <FontAwesomeIcon icon={faPaperPlaneAlt} />
        </SButton>
      </div>
    </div>
  );
};
