import React, { Dispatch, SetStateAction } from "react";
import Head from "./Library/Head";
import ImageView from "./Library/ImageView";
import { renderItems } from "../../../Library/functions/renderItems";
import { handleDoubleClick } from "../../../Library/functions/handleDoubleClick";
import { LibrarySkeleton } from "../../../Library/LibrarySkeleton";
import { useCreationLibraryHelperFunctions } from "./hooks/useCreationLibraryHelperFunctions";
import { useCreationHelperFunctions } from "../../hooks/useCreationHelperFunctions";
import { EmptyState } from "../../../Library/EmptyState";
import { LibraryDropdown } from "../../../Library/LibraryDropdown";
import { HorizontalFolders } from "../../../Library/HorizontalFolders";
import {
  IHandleDeleteProps,
  IHandleDownloadProps,
  IHandleFolderDownloadProps,
  IHandleMoveIntoProps,
  IHandleMoveOutProps,
  IHandleRenameProps,
} from "../../../Library/hooks/useGridCardActions";
import { FolderSkeletons } from "../../../Library/FolderSekeletons";
import { useLibraryStore } from "../../../../store/libraryStore";
import { ILibraryProps } from "../../../../pages/Library";

function LibraryTab({
  isFetching,
  parentFolderId,
  librarySearchValue,
  setOpenModal,
  wrapperRef,
  dropboxRef,
  selectFile,
  handleDelete,
  handleDownload,
  handleFolderDownload,
  handleMoveOut,
  handleRename,
  handleMoveInto,
  setOpenConfirmation,
}: {
  wrapperRef: any;
  isFetching: boolean;
  parentFolderId?: number;
  librarySearchValue?: string;
  dropboxRef: React.RefObject<HTMLInputElement>;
  selectFile: (options: { multiple: boolean }) => void;
  setOpenConfirmation?: Dispatch<SetStateAction<boolean>>;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleRename?: (values: IHandleRenameProps) => void;
  handleDelete?: (values: IHandleDeleteProps) => void;
  handleMoveOut?: (values: IHandleMoveOutProps) => void;
  handleDownload?: (values: IHandleDownloadProps) => void;
  handleFolderDownload?: (values: IHandleFolderDownloadProps) => void;
  handleMoveInto?: (values: IHandleMoveIntoProps) => void;
}) {
  const {
    setFoldersAndFiles,
    setFolderHistory,
    foldersAndFiles,
    setPage,
    setParentFolder,
  } = useLibraryStore();

  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();
  const items = renderItems(foldersAndFiles);
  const { insertMediaForAllSocialNetworkType, setSelectedImageFromResponse } =
    useCreationLibraryHelperFunctions();

  const determineSelected = (item: ILibraryProps) => {
    if (!getActiveSocialNetworkPost()) return false;
    if (!getActiveSocialNetworkPost()?.files) return false;
    return getActiveSocialNetworkPost()!.files?.some(
      (sItem) => sItem.url === item?.object?.url
    );
  };
  return (
    <div
      ref={wrapperRef}
      className={`mt-[16px] overflow-auto max-md:px-[16px] ${!isFetching && items.length === 0 && "pb-[350px]"}`}
      style={{ height: "calc(100% - 96px)" }}
    >
      <Head />
      <div className="grid gap-4 grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5">
        {items.map((item) => (
          <>
            {item.cardType === "Folder" && (
              <HorizontalFolders
                className="w-full h-[240px] lg:h-[260px] 2xl:h-[180px]"
                isContent={true}
                onClick={() => {
                  handleDoubleClick({
                    setPage,
                    setFoldersAndFiles,
                    cardTitle: item.cardTitle,
                    cardType: item.cardType,
                    setFolderHistory,
                    setParentFolder,
                    id: item.id,
                  });
                }}
                handleDelete={handleDelete}
                handleDownload={handleDownload}
                handleFolderDownload={handleFolderDownload}
                handleRename={handleRename}
                rawTitle={item.rawTitle}
                setOpenConfirmation={setOpenConfirmation}
                cardTitle={item.cardTitle}
                cardType={item.cardType}
                id={item.id}
                setFoldersAndFiles={setFoldersAndFiles}
                setPage={setPage}
              />
            )}
          </>
        ))}
        {isFetching && (
          <>
            <FolderSkeletons />
            <FolderSkeletons />
          </>
        )}
      </div>
      <div className="mt-[16px] grid gap-[16px] grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {items.map((item) => (
          <>
            {(item.cardType === "Image" || item.cardType === "Video") && (
              <ImageView
                cardId={item.id}
                handleDelete={handleDelete}
                handleDownload={handleDownload}
                extension={item.extension}
                setOpenConfirmation={setOpenConfirmation}
                cardTitle={item.cardTitle}
                type={item.cardType}
                isSelected={determineSelected(item)}
                onClick={async () => {
                  if (item.object) {
                    const response = await insertMediaForAllSocialNetworkType(
                      item.object,
                      false,
                      true
                    );
                    if (response) setSelectedImageFromResponse(response);
                  }
                }}
                source={
                  item.cardType === "Image"
                    ? (item.mediumImageSrc as string)
                    : item.imageSrc || ""
                }
              />
            )}
          </>
        ))}
        {isFetching && (
          <LibrarySkeleton className="!w-[unset] !h-[unset] aspect-square !px-0 !py-0" />
        )}
        {!isFetching && items.length === 0 && (
          <EmptyState
            className={"absolute right-1/2 translate-x-1/2"}
            searchValue={librarySearchValue}
            title="No Assets Found"
            subtitle="Change folders or create one"
            actionButton={
              <LibraryDropdown
                handleFiles={() => selectFile({ multiple: true })}
                handleFolder={() => setOpenModal(true)}
                handleDropbox={() => dropboxRef.current?.click()}
                className="translate-x-[50%] max-md:-translate-y-[calc(100%+60px)]"
              />
            }
          />
        )}
      </div>
    </div>
  );
}

export default LibraryTab;
