import { useEffect } from "react";
import { useCreationStore } from "../../../store/creationStore";
import { useUpdatePost } from "../../creation/hooks/useUpdatePost";
import _ from "lodash";
import { CopilotMessageType, CopilotRole } from "../../../utils/enums/Enums";

export const useInitPreview = ({
  initialLoad,
  setIsUpdatedByCopilot,
}: {
  initialLoad: boolean;
  setIsUpdatedByCopilot: (value: boolean) => void;
}) => {
  const { updateTextUI } = useUpdatePost();
  const {
    briefMessages,
    selectedChatId,
    postData,
    setIsPreviewOpen,
    setPostUpdateAnimation,
    isSendingMessage,
  } = useCreationStore();

  useEffect(() => {
    const textVersions = briefMessages.filter(
      (message) => message.type === CopilotMessageType.TextVersions
    );
    const lastTextVersionMessage = textVersions[textVersions.length - 1];
    if (
      !isSendingMessage &&
      lastTextVersionMessage &&
      lastTextVersionMessage.textVersions &&
      lastTextVersionMessage.textVersions.length >= 1 &&
      selectedChatId === "general"
    ) {
      let tmpPostData = _.cloneDeep(postData);
      updateTextUI({
        socialNetworkPostId: tmpPostData!.socialNetworkPosts[0].id,
        text: lastTextVersionMessage.textVersions[0].textVersion,
        sendRequest: true,
      });
      setIsPreviewOpen(true);
      setPostUpdateAnimation(true);
    }
  }, [briefMessages, isSendingMessage]);
};
