import { useCreationStore } from "../../../store/creationStore";
import { useEffect } from "react";
import { usePutSwitchBrand } from "../../../api/brand/switch/put";
import { toast } from "../../../hooks/toast";
import { generalSignalRService } from "../../../socket-services/GeneralSignalRService";
import { CopilotPurpose } from "../../../utils/enums/Enums";

export const useBrandSession = () => {
  const {
    initGeneralSignalR,
    setSelectedChatId,
    setSelectedCopilotPurpose,
    setSelectedAgent,
    setSelectedTab,
    setPostId,
  } = useCreationStore();
  const { mutateAsync: switchBrand } = usePutSwitchBrand();
  useEffect(() => {
    initGeneralSignalR();
    generalSignalRService.on<{ organizationId: number }>(
      "NewOrganizationCreated",
      (data: { organizationId: number }) => {
        switchBrand({ id: data.organizationId })
          .then((res) => {
            setSelectedCopilotPurpose(CopilotPurpose.BRAND);
            setSelectedChatId("brand");
            setPostId(undefined);
            setSelectedAgent(null);
            setSelectedTab(0);
          })
          .catch((err) => {
            toast(err.response.data.message, "error");
          });
      }
    );
  }, []);
};
