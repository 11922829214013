import { useEffect } from "react";
import { useGetCopilotSession } from "../../../api/copilot/session/get";
import { useGetCopilotChat } from "../../../api/copilot/chat/get";
import {
  CopilotAgentName,
  CopilotPurpose,
  PostStatus,
} from "../../../utils/enums/Enums";
import { useCreationStore } from "../../../store/creationStore";
import { useGetAllPosts } from "../../../api/posts/getAll";
import { useGetPostById } from "../../../api/posts/getById";
import { useSessionHelperFunctions } from "./useSessionHelperFunctions";
import { getSelectedAgents } from "../functions/getSelectedAgents";

export const useCreateNewSession = () => {
  const { handleCreateNewPost, isPending } = useSessionHelperFunctions();
  const { data: recentPost, isFetching } = useGetAllPosts({
    postStatus: PostStatus.DRAFT,
    page: 1,
    pageSize: 1,
  });
  const {
    briefMessages,
    selectedAgent,
    setBriefMessages,
    setPostId,
    postId,
    setPostData,
    copilotSessionId,
    setCopilotSessionId,
    selectedCopilotPurpose,
    setChatAgents,
    setActiveSoc,
    selectedChatId,
    setIsPostFetching,
  } = useCreationStore();

  const sessionEnabled = () => {
    if (selectedCopilotPurpose === null) return false;
    if (
      selectedCopilotPurpose === CopilotPurpose.POSTCREATION &&
      postId === undefined
    ) {
      return false;
    }
    return true;
  };

  const { data: copilotSession, isFetching: isCopilotSessionFetching } =
    useGetCopilotSession({
      purpose:
        selectedCopilotPurpose !== null
          ? selectedCopilotPurpose
          : CopilotPurpose.BRAND,
      agentIdentifier: selectedAgent,
      postId:
        selectedCopilotPurpose === CopilotPurpose.POSTCREATION
          ? postId
          : undefined,
      enabled: sessionEnabled(),
    });
  const { data: copilotChat, isFetching: isCopilotChatFetching } =
    useGetCopilotChat({
      sessionId: copilotSessionId,
      enabled: !!copilotSessionId,
    });
  const { data } = useGetPostById({ postId: postId || null });

  useEffect(() => {
    const createNewPostSession = async () => {
      if (
        !recentPost?.data ||
        briefMessages.length !== 0 ||
        postId ||
        isFetching
      )
        return;
      setPostId(recentPost.data?.posts[0]?.id);
      setActiveSoc(
        recentPost.data?.posts[0]?.socialNetworkPosts[0]?.socialNetworkType
      );
      setPostData(recentPost.data.posts[0]);
    };
    createNewPostSession();
  }, [recentPost, briefMessages.length, selectedChatId, isFetching]);

  useEffect(() => {
    setIsPostFetching(isFetching);
    if (
      selectedCopilotPurpose === CopilotPurpose.POSTCREATION &&
      !isFetching &&
      recentPost?.data.posts.length === 0
    ) {
      handleCreateNewPost();
    }
  }, [isFetching, selectedCopilotPurpose]);

  useEffect(() => {
    if (data) {
      setPostData(data.data);
      setActiveSoc(data.data.socialNetworkPosts[0].socialNetworkType);
    }
  }, [data]);

  useEffect(() => {
    if (copilotSession) {
      setCopilotSessionId(copilotSession?.data.id);
      setChatAgents(copilotSession?.data.agents);
    }
  }, [copilotSession]);

  useEffect(() => {
    if (copilotChat?.data[0].message && briefMessages.length === 0) {
      copilotChat.data[0].agentImage = getSelectedAgents([
        copilotChat.data[0].agentIdentifier || 1,
      ])[0];
      copilotChat.data[0].agentName =
        CopilotAgentName[copilotChat.data[0].agentIdentifier || 1];
      setBriefMessages([...briefMessages, copilotChat.data[0]]);
    }
  }, [copilotChat]);

  return { isCopilotSessionFetching, isCopilotChatFetching, isPending };
};
