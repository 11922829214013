import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  ISinglePostVM,
  ISocialNetworkPostFileVM,
  ISocialNetworkPostScore,
  ISocialNetworkPostVM,
  IStockGroupVM,
  IStockImageVM,
} from "../utils/interfaces/IPost";
import {
  AgentLoadingState,
  CopilotAgentIdentifier,
  CopilotPurpose,
  SocialNetworkType,
} from "../utils/enums/Enums";
import { ISocialNetworkVM } from "../utils/interfaces/ISocialNetworks";
import { IBaseData } from "../utils/interfaces/IBase";
import { ISingleFIleVM } from "../utils/interfaces/IAssets";
import { ICopilotMessage } from "../utils/copilot-base/ICopilotBase";
import { ISuggestedPrompt } from "../components/creation/hooks/useFillSuggestedPrompts";
import { generalSignalRService } from "../socket-services/GeneralSignalRService";

export type CreationTabs =
  | "brief"
  | "media"
  | "elements"
  | "accounts"
  | "published"
  | null;

export interface IContentStoreInitialInterface {
  postData?: ISinglePostVM;
  activeSoc: SocialNetworkType | null;
  activeTab: CreationTabs;
  postUpdating: boolean;
  briefMessages: ICopilotMessage[];
  copilotSessionId: string | null;
  activeSocialNetworkPost?: ISocialNetworkPostVM;
  selectedSocialNetworks: {
    id: number;
    socialNetworkType: SocialNetworkType;
    ayrshareSocialAccountId: number;
  }[];
  whichSocialNetworkTypeConnectIsLoading: null | SocialNetworkType;
  refetchConnections: boolean;
  isPublishing: null | "publishing" | "scheduling" | "planning";
  tabsOverlay: boolean;
  librarySearchValue: string;
  stocks: IStockImageVM[];
  stocksPage: number;
  isStocksFetching: boolean;
  stocksResponse?: IBaseData<IStockGroupVM>;
  defaultStockStory: string;
  isPostOpen: boolean;
  videoSelectionAgreementModalOpen: null | {
    file: ISingleFIleVM;
    forAll: boolean;
  };
  inAdvanceMedia: ISingleFIleVM[];
  postIdea?: string | null;
  postId?: number;
  improveIdea?: string | null;
  suggestedPrompts: ISuggestedPrompt[];
  isImageUploadingInChat: boolean;
  isMobileSectionsDrawerOpen: boolean;
  whichGifIsLoading: string | number | null;
  isSocialScoreLoading: boolean;
  socialScore?: ISocialNetworkPostScore;
  sessionErrored: boolean;
  inAdvanceDate: Date | null;
  positions: ISocialNetworkPostFileVM[];
  isPreviewOpen: boolean;
  selectedChatId: string | null;
  deleteConfirmation: null | ISinglePostVM;
  selectedCopilotPurpose: CopilotPurpose | null;
  selectedAgent: CopilotAgentIdentifier | null;
  chatAgents: CopilotAgentIdentifier[];
  assetsModal: boolean;
  isExpandedImagesOpen: boolean;
  selectedTab: number;
  isScheduleModalOpen: boolean;
  isPostFetching: boolean;
  selectedPreviewTab: number;
  assetsTab: "generator" | "stocks" | "assets" | "gifs";
  openAssetsGeneratedImageModal: boolean;
  isCommentsModalOpen: boolean;
  postUpdateAnimation: boolean;
  isSendingMessage: boolean;
  copilotAgentLoadingState: AgentLoadingState | null;
  copilotAgentLoading: CopilotAgentIdentifier | null;
}

const initialState: IContentStoreInitialInterface = {
  activeSoc: null,
  postUpdating: false,
  postData: undefined,
  activeTab: "brief",
  positions: [],
  briefMessages: [],
  copilotSessionId: null,
  activeSocialNetworkPost: undefined,
  selectedSocialNetworks: [],
  whichSocialNetworkTypeConnectIsLoading: null,
  refetchConnections: false,
  isPublishing: null,
  tabsOverlay: false,
  librarySearchValue: "",
  stocks: [],
  stocksPage: 1,
  isStocksFetching: false,
  stocksResponse: undefined,
  defaultStockStory: "black and white. cars",
  isPostOpen: false,
  videoSelectionAgreementModalOpen: null,
  inAdvanceMedia: [],
  postIdea: null,
  postId: undefined,
  improveIdea: null,
  suggestedPrompts: [],
  isImageUploadingInChat: false,
  isMobileSectionsDrawerOpen: true,
  whichGifIsLoading: null,
  isSocialScoreLoading: false,
  socialScore: undefined,
  sessionErrored: false,
  inAdvanceDate: null,
  isPreviewOpen: false,
  selectedChatId: null,
  deleteConfirmation: null,
  selectedCopilotPurpose: CopilotPurpose.POSTCREATION,
  selectedAgent: null,
  chatAgents: [],
  assetsModal: false,
  isExpandedImagesOpen: false,
  selectedTab: 0,
  isScheduleModalOpen: false,
  isPostFetching: false,
  selectedPreviewTab: 0,
  assetsTab: "assets",
  openAssetsGeneratedImageModal: false,
  isCommentsModalOpen: false,
  postUpdateAnimation: false,
  isSendingMessage: false,
  copilotAgentLoadingState: null,
  copilotAgentLoading: null,
};

const store = (set: any) => ({
  ...initialState,
  setBriefMessages: (briefMessages: ICopilotMessage[]) =>
    set({ briefMessages }),
  setSessionErrored: (sessionErrored: boolean) => set({ sessionErrored }),
  setActiveSoc: (activeSoc: SocialNetworkType) => set({ activeSoc }),
  setPostIdea: (postIdea: string | null | undefined) => set({ postIdea }),
  setImproveIdea: (improveIdea: string | null | undefined) =>
    set({ improveIdea }),
  setSuggestedPrompts: (suggestedPrompts: ISuggestedPrompt[]) =>
    set({ suggestedPrompts }),
  setWhichGifIsLoading: (whichGifIsLoading: string | number | null) =>
    set({ whichGifIsLoading }),
  setPostId: (postId: number | undefined) => set({ postId }),
  setVideoSelectionAgreementModalOpen: (
    videoSelectionAgreementModalOpen: null | {
      file: ISingleFIleVM;
      forAll: boolean;
    }
  ) => set({ videoSelectionAgreementModalOpen }),
  setIsPostOpen: (isPostOpen: boolean) => set({ isPostOpen }),
  setInAdvanceMedia: (inAdvanceMedia: ISingleFIleVM[]) =>
    set({ inAdvanceMedia }),
  setIsStocksFetching: (isStocksFetching: boolean) => set({ isStocksFetching }),
  setStocksResponse: (stocksResponse: IBaseData<IStockGroupVM> | undefined) =>
    set({ stocksResponse }),
  setStocksPage: (stocksPage: number) => set({ stocksPage }),
  setStocks: (stocks: IStockImageVM[]) => set({ stocks }),
  setLibrarySearchValue: (librarySearchValue: string) =>
    set({ librarySearchValue }),
  setTabsOverlay: (tabsOverlay: boolean) => set({ tabsOverlay }),
  setInAdvanceDate: (inAdvanceDate: Date | null) => set({ inAdvanceDate }),
  setRefetchConnections: (refetchConnections: boolean) =>
    set({ refetchConnections }),
  setWhichSocialNetworkTypeConnectIsLoading: (
    whichSocialNetworkTypeConnectIsLoading: SocialNetworkType | null
  ) => set({ whichSocialNetworkTypeConnectIsLoading }),
  setPostUpdating: (postUpdating: boolean) => set({ postUpdating }),
  setIsPublishing: (
    isPublishing: null | "publishing" | "scheduling" | "planning"
  ) => set({ isPublishing }),
  setCopilotSessionId: (copilotSessionId: string | null) =>
    set({ copilotSessionId }),
  setActiveTab: (activeTab: CreationTabs) => set({ activeTab }),
  setSelectedSocialNetworks: (
    selectedSocialNetworks: {
      id: number;
      socialNetworkType: SocialNetworkType;
      ayrshareSocialAccountId: number;
    }[]
  ) => set({ selectedSocialNetworks }),
  setPostData: (postData: ISinglePostVM | undefined) => set({ postData }),
  setActiveSocialNetworkPost: (activeSocialNetworkPost: ISocialNetworkPostVM) =>
    set({ activeSocialNetworkPost }),
  setIsImageUploadingInChat: (isImageUploadingInChat: boolean) =>
    set({ isImageUploadingInChat }),
  setIsMobileSectionsDrawerOpen: (isMobileSectionsDrawerOpen: boolean) =>
    set({ isMobileSectionsDrawerOpen }),
  setIsSocialScoreLoading: (isSocialScoreLoading: boolean) =>
    set({ isSocialScoreLoading }),
  setSocialScore: (socialScore: undefined | ISocialNetworkPostScore) =>
    set({ socialScore }),
  setPositions: (positions: ISocialNetworkPostFileVM[]) => set({ positions }),
  setIsPreviewOpen: (isPreviewOpen: boolean) => set({ isPreviewOpen }),
  setSelectedChatId: (selectedChatId: string) => set({ selectedChatId }),
  setDeleteConfirmation: (deleteConfirmation: null | ISinglePostVM) =>
    set({ deleteConfirmation }),
  setSelectedCopilotPurpose: (selectedCopilotPurpose: CopilotPurpose | null) =>
    set({ selectedCopilotPurpose }),
  setSelectedAgent: (selectedAgent: CopilotAgentIdentifier | null) =>
    set({ selectedAgent }),
  initGeneralSignalR: () => {
    generalSignalRService.startConnection();
  },
  setChatAgents: (chatAgents: CopilotAgentIdentifier[]) => set({ chatAgents }),
  setAssetsModal: (assetsModal: boolean) => set({ assetsModal }),
  setIsExpandedImagesOpen: (isExpandedImagesOpen: boolean) =>
    set({ isExpandedImagesOpen }),
  setSelectedTab: (
    selectedTab: number | ((prevSelectedTab: number) => number)
  ) => set({ selectedTab }),
  setIsScheduleModalOpen: (isScheduleModalOpen: boolean) =>
    set({ isScheduleModalOpen }),
  setIsPostFetching: (isPostFetching: boolean) => set({ isPostFetching }),
  setSelectedPreviewTab: (
    selectedPreviewTab: number | ((prevSelectedPreviewTab: number) => number)
  ) => set({ selectedPreviewTab }),
  setAssetsTab: (
    assetsTab: "generator" | "stocks" | "assets" | "gifs" | "templates"
  ) => set({ assetsTab }),
  setOpenAssetsGeneratedImageModal: (openAssetsGeneratedImageModal: boolean) =>
    set({ openAssetsGeneratedImageModal }),
  setIsCommentsModalOpen: (isCommentsModalOpen: boolean) =>
    set({ isCommentsModalOpen }),
  setPostUpdateAnimation: (postUpdateAnimation: boolean) =>
    set({ postUpdateAnimation }),
  setIsSendingMessage: (isSendingMessage: boolean) => set({ isSendingMessage }),
  setCopilotAgentLoadingState: (
    copilotAgentLoadingState: AgentLoadingState | null
  ) => set({ copilotAgentLoadingState }),
  setCopilotAgentLoading: (
    copilotAgentLoading: CopilotAgentIdentifier | null
  ) => set({ copilotAgentLoading }),
  reset: () => set(initialState),
});
export const useCreationStore = create(devtools(store));
