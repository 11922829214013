import { useEffect, useState } from "react";
import { useBrandStore } from "../../../store/brandStore";
import TextsSection, { IBrandSectionTextsProps } from "../TextsSection";
import { toast } from "../../../hooks/toast";
import { usePutBrand } from "../../../api/brand/put";
import _ from "lodash";
import { faGlobe } from "@fortawesome/pro-regular-svg-icons";

export const RecommendedChannels = () => {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  const [recommendedChannels, setRecommendedChannels] = useState<
    IBrandSectionTextsProps[]
  >([]);

  useEffect(() => {
    if (
      currentBrand?.brandingData?.recommendedChannels &&
      currentBrand.brandingData.recommendedChannels.length > 0
    ) {
      let tmpRecommendedChannels: IBrandSectionTextsProps[] = [];
      currentBrand.brandingData.recommendedChannels.forEach((item, index) => {
        tmpRecommendedChannels.push({
          isTitleEditable: false,
          key: `recommended-channel-${index}`,
          content: item,
          showBorder:
            index !== currentBrand.brandingData.recommendedChannels.length - 1,
          handleChangeText: (text: string) => {
            if (
              currentBrand &&
              currentBrand.brandingData.recommendedChannels[index] !== text
            ) {
              let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
              tmpBrandingData.recommendedChannels[index] = text;
              updateBrand({
                brandId: currentBrand.id,
                brandingData: tmpBrandingData,
              })
                .then(() => {
                  toast("Recommended channel updated successfully", "success");
                })
                .catch((err) => {
                  toast(
                    `Failed to update recommended channel, ${err.response.data.message}`,
                    "error"
                  );
                });
            }
          },
        });
      });
      setRecommendedChannels(tmpRecommendedChannels);
    }
  }, [currentBrand]);

  return (
    <TextsSection
      fieldName="Recommended Channels"
      loadingCount={3}
      title="Recommended Channels"
      subtitle=""
      isLoading={!currentBrand?.brandingData?.recommendedChannels}
      texts={recommendedChannels}
    />
  );
};
