import {
  faArrowRightFromBracket,
  faBars,
  faBell,
  faChevronDown,
  faCommentSmile,
  faCreditCard,
  faQuestionCircle,
  faSparkles,
} from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import SButton from "../../design-system/SButton";
import SSmallMenu from "../../design-system/SSmallMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useGetUser } from "../../hooks/useUserInfo";
import MobileDrawer from "./MobileDrawer";
import NotificationsInHeader from "./NotificationsInHeader";
import { useGetCurrentBrand } from "../../api/brand/current/get";
import { Tooltip } from "react-tooltip";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import WhichSocialNetworksSelector from "../utils/WhichSocialNetworksSelector";
import AccountHead from "./AccountHead";
// @ts-ignore
import BrandSwitcher from "../newSidebar/BrandSwitcher";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetNotifications } from "../../api/notifications/get";
import { getQuery } from "../../hooks/functions/queries";

function Header() {
  const { data: currentBrand, isError: isCurrentBrandError } =
    useGetCurrentBrand();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [
    isWhichSocialNetworksSelectorOpen,
    setIsWhichSocialNetworksSelectorOpen,
  ] = useState(false);
  const [isBrandSelectorOpen, setIsBrandSelectorOpen] = useState(false);

  return (
    <div className="flex w-full mt-[12px] z-[21]">
      <MobileDrawer
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />
      {isWhichSocialNetworksSelectorOpen && (
        <WhichSocialNetworksSelector
          saveAllOnClose={true}
          isOpen={isWhichSocialNetworksSelectorOpen}
          onClose={() => setIsWhichSocialNetworksSelectorOpen(false)}
        />
      )}
      <div
        className={`
          relative
        border border-gray-200
        px-[6px] z-[98] flex justify-between items-center py-[6px]
        w-full h-[55px] bg-base-white rounded-[12px] gap-[16px]
      `}
      >
        <SButton
          onClick={() => setIsMobileMenuOpen(true)}
          icon={faBars}
          size="sm"
          type="secondaryGray"
          className={"md:hidden"}
        />
        {!isCurrentBrandError && !currentBrand?.data?.isBrandDataGenerated && (
          <Tooltip
            style={{
              fontSize: "14px",
            }}
            id="tooltip-header-copilot"
            place="top"
            disableStyleInjection
          >
            Please wait while we generate your brand
          </Tooltip>
        )}
        {isCurrentBrandError && (
          <Tooltip
            style={{
              fontSize: "14px",
            }}
            id="tooltip-header-copilot"
            place="top"
            disableStyleInjection
          >
            Create brand to use STORI Assistant
          </Tooltip>
        )}
        <div className="w-full flex justify-between items-center max-md:hidden">
          <BrandSwitcher
            setIsBrandSelectorOpen={setIsBrandSelectorOpen}
            isBrandSelectorOpen={isBrandSelectorOpen}
          />
          <div
            onClick={() => setIsBrandSelectorOpen((prev) => !prev)}
            className="w-full justify-between flex items-center"
            id="sidebar-brand-switcher"
          >
            <p className="cursor-pointer pl-2 max-md:hidden text-sm-b text-gray-800 truncate max-w-[110px]">
              {currentBrand?.data?.brandingData?.brandName}
            </p>
            {currentBrand?.data?.brandingData?.brandName && (
              <FontAwesomeIcon
                icon={faChevronDown}
                className="text-xs text-gray-600"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
