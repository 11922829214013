import { faImage } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCreationStore } from "../../store/creationStore";

export const PreviewCardPlaceholder = () => {
  const { setAssetsModal } = useCreationStore();
  return (
    <div
      onClick={() => setAssetsModal(true)}
      className="h-[200px] gap-3 px-4 pt-4 pb-2"
    >
      <div className="border-2 flex flex-col gap-2 border-dashed cursor-pointer  rounded-lg w-full h-full items-center justify-center bg-gray-50 border-gray-200 ">
        <FontAwesomeIcon icon={faImage} className="text-gray-400 text-4xl" />
        <p className="text-gray-400 text-sm">Select or Upload</p>
      </div>
    </div>
  );
};
