// src/services/signalrService.ts

import { HubConnection } from "@microsoft/signalr";
import * as signalR from "@microsoft/signalr";

class SignalRService {
  private connection: HubConnection;
  private eventListeners: Array<{
    event: string;
    callback: (data: any) => void;
  }> = [];

  constructor() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api-dev.storiai.com/api/v1/hubs/agents", {
        accessTokenFactory: () => localStorage.getItem("token") || "",
      })
      .withHubProtocol(new signalR.JsonHubProtocol())
      .withAutomaticReconnect()
      .build();
    this.startConnection();
  }

  public async startConnection() {
    try {
      if (this.connection.state !== "Connected") {
        await this.connection.start();
        this.eventListeners.forEach(({ event, callback }) => {
          this.connection.on(event, callback);
        });
        console.log("AgentHub SignalR Connected Successfully");
      }
    } catch (err) {
      console.error(
        "Error while establishing AgentHub SignalR connection: ",
        err
      );
    }

    this.connection.onclose(() => {
      console.log("AgentHub SignalR Disconnected. Attempting to reconnect...");
      //this.startConnection();
    });
  }

  public on<T>(event: string, callback: (data: T) => void): void {
    try {
      if (this.connection.state === "Connected") {
        this.connection.on(event, callback);
        // console.log(`Listening for event: ${event}`);
      } else {
        this.eventListeners.push({ event, callback });
        // console.log(`Queued event listener for: ${event}`);
      }
    } catch (e) {
      console.error(
        "Error while establishing AgentHub SignalR connection: ",
        e
      );
    }
  }

  public off(event: string, callback: (...args: any[]) => void): void {
    this.connection.off(event, callback);
  }

  public async send<T>(event: string, data?: T): Promise<void> {
    try {
      await this.connection.send(event, data);
      console.log(`Sent AgentHub SignalR event: ${event} with data:`, data);
    } catch (err) {
      console.error("Error while sending AgentHub SignalR data: ", err);
    }
  }
}

export const agentHubSignalRService = new SignalRService();
