import { useEffect, useState } from "react";
import { AgentChat } from "../components/newContent/AgentChat";
import { useContentStore } from "../store/contentStore";
import { useGetAllPosts } from "../api/posts/getAll";
import { ConfirmDelete } from "../components/Library/ConfirmDelete";
import { useUpdatePost } from "../components/creation/hooks/useUpdatePost";
import { toast } from "../hooks/toast";
import { PostStatus } from "../utils/enums/Enums";
import SCheckbox from "../design-system/SCheckbox";
import { useCreationStore } from "../store/creationStore";
import { AgentAssets } from "../components/newContent/AgentAssets";
import ExpandedImages from "../components/creation/Tabs/Media/ExpandedImages";
import ExpandedImageModal from "../components/utils/ExpandedImageModal";
import { AgentScheduleModal } from "../components/newContent/schedule/AgentScheduleModal";

export const Chats = () => {
  const { setPosts, setIsPostsLoading } = useContentStore();
  const {
    deleteConfirmation,
    setDeleteConfirmation,
    assetsModal,
    isExpandedImagesOpen,
    setIsExpandedImagesOpen,
    isScheduleModalOpen,
    setPostId,
  } = useCreationStore();
  const { data, isFetching } = useGetAllPosts({ page: 1, pageSize: 5 });
  const [checked, setChecked] = useState(false);
  const { handleDeletePost, isDeleting } = useUpdatePost();
  useEffect(() => {
    if (data && !isFetching) {
      setPosts(data.data.posts);
    }
    setIsPostsLoading(isFetching);
  }, [data, isFetching]);
  useEffect(() => {
    setIsPostsLoading(isFetching);
  }, [isFetching]);
  const [expandedSingleImageModal, setExpandedSingleImageModal] = useState<
    string | null
  >(null);
  const [expandedSingleVideoModal, setExpandedSingleVideoModal] =
    useState(false);
  return (
    <div className="w-full h-full flex gap-[12px]">
      {(expandedSingleImageModal || expandedSingleImageModal) && (
        <ExpandedImageModal
          isOpen={!!expandedSingleImageModal}
          isExpandedVideoModalOpen={!!expandedSingleVideoModal}
          onClose={() => setExpandedSingleImageModal(null)}
          source={expandedSingleImageModal || undefined}
        />
      )}
      {isExpandedImagesOpen && (
        <ExpandedImages
          isOpen={isExpandedImagesOpen}
          onClose={() => setIsExpandedImagesOpen(false)}
        />
      )}
      {!!deleteConfirmation && (
        <ConfirmDelete
          loading={isDeleting}
          setOpenModal={() => setDeleteConfirmation(null)}
          onConfirm={() => {
            handleDeletePost(deleteConfirmation.id, checked).then((r) => {
              setPostId(undefined);
              toast(r.data.message, "success");
              setDeleteConfirmation(null);
            });
          }}
          onCancel={() => setDeleteConfirmation(null)}
          openModal={!!deleteConfirmation}
          title="Are you sure you want to delete this post?"
          children={
            <>
              {deleteConfirmation.socialNetworkPosts.some(
                (item) => item.postStatus === PostStatus.PUBLISHED
              ) && (
                <div className="flex items-center gap-2 mt-2">
                  <SCheckbox
                    checked={checked}
                    onChange={() => setChecked((val) => !val)}
                  />
                  <p
                    onClick={() => setChecked((val) => !val)}
                    className="text-sm text-gray-900 cursor-default select-none"
                  >
                    Delete this on social media account as well?
                  </p>
                </div>
              )}
            </>
          }
        />
      )}
      {assetsModal && <AgentAssets />}
      {isScheduleModalOpen && <AgentScheduleModal />}
      <AgentChat
        setExpandedSingleVideoModal={setExpandedSingleVideoModal}
        setExpandedSingleImageModal={setExpandedSingleImageModal}
      />
    </div>
  );
};
