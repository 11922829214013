import { useEffect } from "react";
import { useCreationStore } from "../../../store/creationStore";

export interface ISuggestedPrompt {
  label: string;
  value: string;
  autoSend: boolean;
}

export const useFillSuggestedPrompts = () => {
  const { briefMessages, setSuggestedPrompts } = useCreationStore();
  useEffect(() => {
    if (briefMessages.length === 1) {
      setSuggestedPrompts([
        {
          label: "Create post",
          value:
            "Create inspirational post targeted to our first audience segment. Start with a storyline about our brand promise and transition to our services",
          autoSend: false,
        },
        {
          label: "Create image",
          value: "Create image of ",
          autoSend: false,
        },
        {
          label: "Give me idea",
          value: "Give me idea what to post about today",
          autoSend: false,
        },
        {
          label: "Help me write",
          value: "Help me write ",
          autoSend: false,
        },
      ]);
    }
  }, [briefMessages]);

  useEffect(() => {
    const lastMessage = briefMessages[briefMessages.length - 1];
    if (lastMessage) {
      if (
        lastMessage.suggestedUserReplies &&
        lastMessage.suggestedUserReplies.length > 0
      ) {
        setSuggestedPrompts(
          lastMessage.suggestedUserReplies.map((reply) => ({
            label: reply,
            value: reply,
            autoSend: true,
          }))
        );
      } else {
        const containtTextVersion = briefMessages.find(
          (item) => item.textVersions && item.textVersions.length > 0
        );
        if (containtTextVersion) {
          setSuggestedPrompts([
            {
              label: "Generate image",
              value: "Generate image ",
              autoSend: false,
            },
            {
              label: "Make it shorter",
              value: "Make it shorter",
              autoSend: true,
            },
            {
              label: "Break paragraphs",
              value: "Break paragraphs",
              autoSend: true,
            },
            {
              label: "Add CTA",
              value: "Add CTA",
              autoSend: true,
            },
            {
              label: "Use emojis",
              value: "Use emojis",
              autoSend: true,
            },
          ]);
        }
      }
    }
  }, [briefMessages]);
};
