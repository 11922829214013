import SModalBase from "../../design-system/SModalBase";
import { useCreationStore } from "../../store/creationStore";
import Media from "../creation/Tabs/Media";

export const AgentAssets = () => {
  const { assetsModal, setAssetsModal } = useCreationStore();
  return (
    <SModalBase
      dialogPanelClassName="min-w-[880px]"
      isOpen={assetsModal}
      onClose={setAssetsModal}
      title="Assets"
    >
      <div className="relative w-[800px] h-full overflow-y-auto">
        <Media />
      </div>
    </SModalBase>
  );
};
