import { useEffect } from "react";
import {
  AgentLoadingState,
  AgentLoadingStateValues,
  CopilotAgentIdentifier,
} from "../../../utils/enums/Enums";
import { agentHubSignalRService } from "../../../socket-services/AgentHubSignalRService";
import { useCreationStore } from "../../../store/creationStore";

export const useAgentsHub = () => {
  const { setCopilotAgentLoadingState, setCopilotAgentLoading } =
    useCreationStore();
  useEffect(() => {
    agentHubSignalRService.startConnection();
    agentHubSignalRService.on<{
      copilotChatSessionId: string;
      agentLoadingState: AgentLoadingState;
    }>(
      "AgentWriting",
      (data: {
        copilotChatSessionId: string;
        agentLoadingState: AgentLoadingState;
      }) => {
        if (data.agentLoadingState === AgentLoadingState.Default) {
          setCopilotAgentLoading(null);
          setCopilotAgentLoadingState(null);
        }
        if (
          data.agentLoadingState === AgentLoadingState.Copia ||
          data.agentLoadingState === AgentLoadingState.Sivo ||
          data.agentLoadingState === AgentLoadingState.Breni ||
          data.agentLoadingState === AgentLoadingState.Velin ||
          data.agentLoadingState === AgentLoadingState.Aseta
        ) {
          setCopilotAgentLoading(
            AgentLoadingStateValues[
              data.agentLoadingState
            ] as CopilotAgentIdentifier
          );
        } else {
          setCopilotAgentLoadingState(data.agentLoadingState);
          if (data.agentLoadingState === AgentLoadingState.WritingCopy) {
            setCopilotAgentLoading(
              AgentLoadingStateValues[AgentLoadingState.Copia]
            );
          }
        }
      }
    );
  }, []);
};
