import { useCreationStore } from "../../../../store/creationStore";
import { PreviewCard } from "../../PreviewCard";
import { BrandPreview } from "../brand/BrandPreview";

interface PostContentProps {
  handleBlur: (value: string) => void;
}

export const PostContent = ({ handleBlur }: PostContentProps) => {
  const { selectedChatId } = useCreationStore();
  return (
    <>
      {selectedChatId === "general" && <PreviewCard handleBlur={handleBlur} />}
      {(selectedChatId === "brand" || selectedChatId === "breni") && (
        <BrandPreview />
      )}
    </>
  );
};
