import { useState } from "react";
import { useUpdatePost } from "../../creation/hooks/useUpdatePost";
import { useCreationStore } from "../../../store/creationStore";

export const useSaveChanges = () => {
  const { updateTextUI } = useUpdatePost();
  const [isSaving, setIsSaving] = useState(false);
  const { postData } = useCreationStore();

  const handleBlur = async (
    textAreaValue: string,
    socialNetworkPostId?: number
  ) => {
    if (textAreaValue === postData?.socialNetworkPosts[0].text || !postData)
      return;
    if (
      socialNetworkPostId &&
      textAreaValue ===
        postData?.socialNetworkPosts.find(
          (item) => item.id === socialNetworkPostId
        )?.text
    )
      return;
    setIsSaving(true);
    await updateTextUI({
      text: textAreaValue,
      socialNetworkPostId:
        socialNetworkPostId || postData.socialNetworkPosts[0].id,
      socialNetworkPostIds: [
        socialNetworkPostId || postData.socialNetworkPosts[0].id,
      ],
      sendRequest: true,
    });
    setIsSaving(false);
  };

  return { isSaving, handleBlur };
};
