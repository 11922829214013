import { useEffect } from "react";
import { getQuery } from "../../../hooks/functions/queries";
import { useGetCurrentBrand } from "../../../api/brand/current/get";
import { useLocation } from "react-router-dom";
import { useCreationStore } from "../../../store/creationStore";
import { CopilotPurpose } from "../../../utils/enums/Enums";

export const useDefaultSidebarValues = () => {
  const { data: currentOrganization, isFetching } = useGetCurrentBrand();
  const location = useLocation();
  const hideIfNoBrand = currentOrganization?.data?.id === undefined;
  const {
    selectedChatId,
    setSelectedChatId,
    setSelectedAgent,
    setSelectedCopilotPurpose,
  } = useCreationStore();
  useEffect(() => {
    if (!isFetching && location.pathname === "/" && selectedChatId === null) {
      if (!currentOrganization?.data?.id) {
        setSelectedChatId("brand");
        setSelectedAgent(null);
        setSelectedCopilotPurpose(CopilotPurpose.BRANDCREATION);
      } else {
        setSelectedChatId("general");
        setSelectedAgent(null);
        setSelectedCopilotPurpose(CopilotPurpose.POSTCREATION);
      }
    }
  }, [currentOrganization, isFetching, location.pathname]);
  useEffect(() => {
    if (location.pathname !== "/") {
      const subsection = getQuery("subsection");
      setSelectedChatId(
        subsection ? subsection : location.pathname.split("/")[1]
      );
      setSelectedAgent(null);
    }
  }, []);

  return { hideIfNoBrand, isFetching, currentOrganization };
};
