import { useGetWorkspaces } from "../../api/workspaces/get";
import SBadge from "../../design-system/SBadge";
import { faPencil, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { usePutSwitchTeam } from "../../api/workspaces/swich/put";
import { useContentStore } from "../../store/contentStore";
import { useGetCurrentWorkspace } from "../../api/workspaces/current/get";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAccountStore } from "../../store/accountStore";
import { usePutWorkspace } from "../../api/workspaces/put";
import { toast } from "../../hooks/toast";
import { useEffect, useState } from "react";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import { LibraryCreateFolderModal } from "../Library/LibraryCreateFolderModal";
import { useLibraryStore } from "../../store/libraryStore";
import SRadio from "../../design-system/SRadio";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import LettersAvatar from "../utils/LettersAvatar";
import SButton from "../../design-system/SButton";
import { useSubscriptionActions } from "../../hooks/useSubscriptionActions";
import WorkspaceBrands from "./WorkspaceBrands";

export const WorkspaceSettings = () => {
  const { data: workspaces } = useGetWorkspaces();
  const { data: currentWorkspace } = useGetCurrentWorkspace();
  const { mutateAsync: updateWorkspace } = usePutWorkspace();
  const { mutateAsync: switchWorkspace, isPending } = usePutSwitchTeam();
  const { setPosts, setPage } = useContentStore();
  const { personalInfo } = useAccountStore();
  const [value, setValue] = useState<string>("");
  const [loadingId, setLoadingId] = useState<number | null>(null);
  const [openRename, setOpenRename] = useState<boolean>(false);
  const { activatePlan } = useSubscriptionActions();

  const {
    setFoldersAndFiles,
    setPage: setLibraryPage,
    setParentFolder,
  } = useLibraryStore();

  useEffect(() => {
    if (workspaces && personalInfo) {
      const ownerWorkspace = workspaces?.data.find((workspace) => {
        return (
          currentWorkspace?.data.id === workspace.id &&
          personalInfo?.userRole === OrganizationUserRole.OWNER
        );
      });
      if (ownerWorkspace) {
        setValue(ownerWorkspace.name);
      }
    }
  }, [currentWorkspace, workspaces, personalInfo]);

  return (
    <div className="flex flex-col mt-1 w-full pb-[60px]">
      {openRename && (
        <LibraryCreateFolderModal
          action="Rename"
          type="Workspace"
          name={value}
          setName={(e) => setValue(e.target!.value)}
          openModal={openRename}
          setOpenModal={() => setOpenRename(false)}
          onSave={() => {
            if (!currentWorkspace) return;
            updateWorkspace({
              workspaceId: currentWorkspace?.data.id,
              data: {
                name: value,
                color: currentWorkspace?.data.color,
              },
            })
              .then((res) =>
                toast("Successfully updated workspace name", "success")
              )
              .catch((err) => toast(err.response.data.message))
              .finally(() => setOpenRename(false));
          }}
        />
      )}
      <WorkspaceBrands />
    </div>
  );
};
