import { useCreationStore } from "../../../store/creationStore";
import { PostStatus, SocialNetworkType } from "../../../utils/enums/Enums";
import _ from "lodash";
import { ISinglePostVM } from "../../../utils/interfaces/IPost";
import { usePutRemoveSchedule } from "../../../api/creation/removeSchedule/put";
import { useGetCurrentBrand } from "../../../api/brand/current/get";
import { toast } from "../../../hooks/toast";
import { usePutPost } from "../../../api/posts/put";
import { usePatchPost } from "../../../api/posts/patch";
import { useFramesStore } from "../../../store/framesStore";
import { useDeletePost } from "../../../api/posts/delete";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ISingleBrandVM } from "../../../utils/interfaces/IBrand";
import { useUpdatePost } from "./useUpdatePost";
import { useBrandStore } from "../../../store/brandStore";
import { useNavigate } from "react-router-dom";

export const useCreationHelperFunctions = () => {
  const navigate = useNavigate();
  const { isError: isCurrentBrandError, data: currentBrand } =
    useGetCurrentBrand();
  const { reset: resetFramesStore } = useFramesStore();

  const { refetch: refetchBrand } = useGetCurrentBrand();
  const { mutateAsync: deletePost, isPending: isDeletePostPending } =
    useDeletePost();
  const {
    reset,
    setPostData,
    setPostIdea,
    activeSoc,
    setIsPostOpen,
    setPostId,
    postData,
    briefMessages,
    setActiveSoc,
    setIsPreviewOpen,
  } = useCreationStore();
  const { isProgressLoading } = useBrandStore();
  const handleOpenPost = async ({
    postId,
    shouldReset = true,
    postIdea,
    onSocialNetworkSelectorOpen,
  }: {
    onSocialNetworkSelectorOpen: () => void;
    postId?: number;
    shouldReset?: boolean;
    postIdea?: string | null;
  }) => {
    try {
      const res = await refetchBrand();

      if (
        isProgressLoading ||
        res.isError ||
        !res.data?.data.isBrandDataGenerated ||
        res.data?.data.hasErrors
      ) {
        toast("Your brand data is not generated yet", "loading", {
          autoClose: 3000,
        });
        return;
      }

      if (res.data.data.activeSocialNetworks.length === 0) {
        onSocialNetworkSelectorOpen();
        return;
      }

      if (shouldReset) {
        reset();
        resetFramesStore();
      }

      if (postIdea) setPostIdea(postIdea);
      if (postId) setPostId(postId);

      setIsPostOpen(true);
      setIsPreviewOpen(true);
      navigate("/");
    } catch (error) {
      console.error("Error fetching brand data:", error);
      toast("An error occurred while fetching brand data", "error", {
        autoClose: 3000,
      });
    }
  };
  const { mutateAsync: putPost, isPending: isPostPending } = usePutPost();
  const getActiveSocialNetworkPost = () => {
    if (postData)
      return postData!.socialNetworkPosts.find(
        (post) => post.socialNetworkType === activeSoc
      );
  };

  const isPostPublishedOrScheduled = () => {
    return (
      getActiveSocialNetworkPost()?.postStatus === PostStatus.PUBLISHED ||
      getActiveSocialNetworkPost()?.postStatus === PostStatus.SCHEDULED
    );
  };
  const isPostPublished = () => {
    return getActiveSocialNetworkPost()?.postStatus === PostStatus.PUBLISHED;
  };
  const isPostScheduled = () => {
    return getActiveSocialNetworkPost()?.postStatus === PostStatus.SCHEDULED;
  };
  const getSocialNetworkTypeByName = (type: string) => {
    let lwrCase = type.toLowerCase();
    switch (lwrCase) {
      case "facebook":
        return SocialNetworkType.FACEBOOK;
      case "twitter":
        return SocialNetworkType.TWITTER;
      case "x":
        return SocialNetworkType.TWITTER;
      case "linkedin":
        return SocialNetworkType.LINKEDIN;
      case "instagram":
        return SocialNetworkType.INSTAGRAM;
      case "pinterest":
        return SocialNetworkType.PINTEREST;
      case "reddit":
        return SocialNetworkType.REDDIT;
      case "telegram":
        return SocialNetworkType.TELEGRAM;
      case "discord":
        return SocialNetworkType.DISCORD;
      case "slack":
        return SocialNetworkType.SLACK;
    }
  };
  const getSocialNetworkNameByType = (type: SocialNetworkType) => {
    switch (type) {
      case SocialNetworkType.FACEBOOK:
        return "Facebook";
      case SocialNetworkType.TWITTER:
        return "Twitter";
      case SocialNetworkType.LINKEDIN:
        return "LinkedIn";
      case SocialNetworkType.INSTAGRAM:
        return "Instagram";
      case SocialNetworkType.PINTEREST:
        return "Pinterest";
      case SocialNetworkType.REDDIT:
        return "Reddit";
      case SocialNetworkType.TELEGRAM:
        return "Telegram";
      case SocialNetworkType.DISCORD:
        return "Discord";
      case SocialNetworkType.SLACK:
        return "Slack";
      default:
        return "Unknown";
    }
  };

  const getSocialNetworkPostWithSocialNetworkType = (
    soc: SocialNetworkType
  ) => {
    if (postData)
      return postData.socialNetworkPosts.find(
        (post) => post.socialNetworkType === soc
      );
  };

  const handleSavePost = async (p?: ISinglePostVM) => {
    // just save images
    let tmpPostData = _.cloneDeep(p || postData);
    const res = await putPost({
      postData: tmpPostData as ISinglePostVM,
      postId: postData!.id,
    });
    setPostData(res.data);
    return res;
  };

  const handleDeleteEmptyPost = async () => {
    if (!postData) return;
    const isCopilotEmpty =
      briefMessages.length === 0 || briefMessages.length === 1;
    const allTextsAreEmpty = postData.socialNetworkPosts.every(
      (item) => item.text === null || item.text.length === 0
    );
    const allImagesAreEmpty = postData.socialNetworkPosts.every(
      (item) => item.files.length === 0
    );
    if (allImagesAreEmpty && isCopilotEmpty && allTextsAreEmpty) {
      deletePost({ postId: postData.id });
    }
  };

  const toggleSocialNetworkInPost = async ({
    socialNetworkTypes,
    append,
  }: {
    socialNetworkTypes: { type: SocialNetworkType; text: string | null }[];
    append: boolean;
  }) => {
    if (postData) {
      const tmpPostData = _.cloneDeep(postData);
      // Append missing social networks from socialNetworkTypes
      socialNetworkTypes.forEach((socialNetworkType) => {
        const existsInPostData = tmpPostData.socialNetworkPosts.some(
          (item) => item.socialNetworkType === socialNetworkType.type
        );

        if (!existsInPostData) {
          // If not in postData, append it
          tmpPostData.socialNetworkPosts.push({
            ayrshareSocialAccountId: null,
            //@ts-ignore
            componentTexts: null,
            files: [],
            isChecked: false,
            isExamplePost: false,
            //@ts-ignore
            name: null,
            postId: postData.id,
            postStatus: PostStatus.DRAFT,
            postUrl: null,
            releaseDate: null,
            selectedTextId: null,
            //@ts-ignore
            socialNetworkConnectionId: null,
            //@ts-ignore
            socialScore: null,
            text: socialNetworkType.text || null,
            textHistories: [],
            socialNetworkType: socialNetworkType.type,
          });
        }
      });
      tmpPostData.socialNetworks.push(
        ...socialNetworkTypes.map((item) => item.type)
      );
      const res = await handleSavePost(tmpPostData);
      return res.data;
    }
  };

  const handleSetFirstActiveSocialNetwork = (
    brandActiveSocialNetworks: SocialNetworkType[]
  ) => {
    if (!postData || !currentBrand) return;
    let isChanged = false;
    postData.socialNetworkPosts.forEach((element) => {
      if (
        brandActiveSocialNetworks.includes(element.socialNetworkType) &&
        !isChanged
      ) {
        setActiveSoc(element.socialNetworkType);
        isChanged = true;
      }
    });
  };

  return {
    toggleSocialNetworkInPost,
    isPostPublishedOrScheduled,
    isPostPublished,
    isPostScheduled,
    handleSavePost,
    getSocialNetworkPostWithSocialNetworkType,
    getActiveSocialNetworkPost,
    getSocialNetworkTypeByName,
    handleDeleteEmptyPost,
    handleOpenPost,
    getSocialNetworkNameByType,
    isPostPending,
    postData,
    activeSoc,
    handleSetFirstActiveSocialNetwork,
  };
};
