import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faTrash } from "@fortawesome/pro-regular-svg-icons";
import "../../../index.css";
import { ISingleBrandVM } from "../../../utils/interfaces/IBrand";
import { IWorkspaceUsersVM } from "../../../utils/interfaces/IWorkspace";
import SButton from "../../../design-system/SButton";
import { Dispatch, SetStateAction } from "react";

export interface IBrandsListRow {
  brand: ISingleBrandVM;
  index: number;
  members?: IWorkspaceUsersVM[];
  className?: string;
  onDetailsClick?: () => void;
  setIsConfirmDeleteOpen: Dispatch<SetStateAction<number | boolean>>;
  windowWidth: number;
}

function BrandsListRow({
  brand,
  members,
  index,
  className,
  onDetailsClick,
  setIsConfirmDeleteOpen,
  windowWidth,
}: IBrandsListRow) {
  const brandMembers = members?.filter((user) =>
    user.organizationUsers.find(
      (orgUser) => orgUser.organizationId === brand.id
    )
  );
  const truncateValue = windowWidth <= 768 ? 3 : 5;
  const displayMembers = brandMembers
    ?.map((user, index) => {
      return (
        <img
          key={index}
          className="w-6 h-6 rounded-full object-cover border-2 mr-[-5px] border-white"
          src={user.photoUrl}
          alt={user.name}
        />
      );
    })
    .slice(0, truncateValue);
  const remainingCount = Math.max(
    0,
    brandMembers ? brandMembers?.length - truncateValue : 0
  );
  return (
    <tr className={`${className} border-b border-gray-200 items-center`}>
      <td className="px-[24px] py-[16px] w-[60%]">
        <div className="flex items-center">
          {brand && (
            <img
              src={brand.logo}
              alt={brand.brandingData?.brandName}
              className="w-[40px] h-[40px] rounded-full mr-[12px] object-contain border-[1px] border-opacity-[0.08] border-base-black p-[2px]"
            />
          )}
          <div className="flex flex-col">
            <span className="text-gray-900 text-sm-m">
              {brand.brandingData?.brandName}
            </span>
            <span className="text-gray-600 text-sm">{brand.website}</span>
          </div>
        </div>
      </td>
      <td className="py-[16px] w-[40%] text-sm text-slate-600">
        <span className="text-gray-600 text-sm flex pl-5">
          {displayMembers}
          {remainingCount > 0 && (
            <div className="flex items-center justify-center w-[24px] h-[24px] rounded-full bg-gray-200 border-[1px] border-white p-[3px]">
              <span className={"text-xs-sb text-gray-600"}>
                +{remainingCount}
              </span>
            </div>
          )}
        </span>
      </td>
      <td className="p-[16px] flex items-center ">
        <button
          onClick={onDetailsClick}
          aria-label="Details"
          className="py-[10px] px-[8px] text-gray-600 hover:text-gray-800"
        >
          <FontAwesomeIcon icon={faExternalLink} />
        </button>
        <SButton
          type="tertiaryColor"
          className="text-error-500 hover:text-error-700 hover:bg-error-50"
          icon={faTrash}
          onClick={() => setIsConfirmDeleteOpen(brand.id)}
        />
      </td>
    </tr>
  );
}

export default BrandsListRow;
