import { useContentScroll } from "../../content/hooks/useConntentScroll";
import { useContent } from "../../content/hooks/useContent";
import { useContentCount } from "../../content/hooks/useContentCount";
import { EmptyContentState } from "../../creation/EmptyState/EmptyContentState";
import { ISinglePostVM } from "../../../utils/interfaces/IPost";
import PreviewPosts from "./content/PreviewPosts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeading from "../../PageHeading";
import { faX } from "@fortawesome/free-solid-svg-icons";
import SInput from "../../../design-system/SInput";

function PreviewPostsList() {
  const { posts, isFetching, totalCount } = useContentScroll();
  const {
    handleOpenPost,
    setIsWhichSocialNetworksSelectorOpen,
    searchValue,
    setSearchValue,
  } = useContent();
  const { selected } = useContentCount();

  return (
    <>
      <div className="w-full px-0 h-fit pb-[50px] rounded-[10px]">
        <SInput
          className="w-full pb-4"
          placeholder="Search..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {totalCount === 0 && !isFetching && searchValue.length === 0 && (
          <EmptyContentState
            setIsWhichSocialNetworksSelectorOpen={
              setIsWhichSocialNetworksSelectorOpen
            }
          />
        )}
        <PreviewPosts
          handleOpenPost={(p: ISinglePostVM) => {
            handleOpenPost({
              postId: p.id,
              onSocialNetworkSelectorOpen: () =>
                setIsWhichSocialNetworksSelectorOpen(true),
            });
          }}
          handleCreateNew={() =>
            handleOpenPost({
              onSocialNetworkSelectorOpen: () =>
                setIsWhichSocialNetworksSelectorOpen(true),
            })
          }
          posts={posts}
          totalCount={totalCount || 0}
          searchValue={searchValue}
          isLoading={isFetching}
          selected={selected}
        />
      </div>
    </>
  );
}

export default PreviewPostsList;
