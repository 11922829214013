import { useCreationStore } from "../../../../../store/creationStore";
import { ICopilotMessage } from "../../../../../utils/copilot-base/ICopilotBase";

export const useUpdateBriefMessages = () => {
  /**
   * Updates the last message in the briefMessages array.
   *
   * This function is responsible for updating the properties of the last message
   * in the briefMessages array stored in the creation store. It takes an object
   * of type ICreateTaskMessage as an argument, which contains the new values
   * to be applied to the last message.
   *
   * The function works as follows:
   * 1. Creates a copy of the current briefMessages array.
   * 2. Creates a copy of the last message in the array.
   * 3. Iterates through the properties of the input object.
   * 4. Updates the corresponding properties of the last message copy.
   * 5. Replaces the last message in the array copy with the updated message.
   * 6. Sets the updated array as the new briefMessages in the store.
   *
   * This approach ensures immutability and triggers a re-render when the state changes.
   *
   * @param obj - An object of type ICreateTaskMessage containing the updates to apply.
   */
  const updateLoadingMessage = ({
    obj,
    tmpMessages,
    setLast = true,
  }: {
    obj: ICopilotMessage;
    tmpMessages: ICopilotMessage[];
    setLast?: boolean;
  }) => {
    const updatedMessages = tmpMessages.map((message, index) => {
      if (!message.content) {
        if (setLast) {
          if (index === tmpMessages.length - 1) {
            return { ...message, ...obj };
          }
          return { ...message };
        } else {
          return { ...message, ...obj };
        }
      }
      return message;
    });
    return updatedMessages;

    // setBriefMessages(updatedMessages);
  };

  return { updateLoadingMessage };
};
