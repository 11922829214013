import SUnderlineTabs from "../../design-system/SUnderlineTabs";
import {
  CopilotAgentIdentifier,
  CopilotAgentName,
  CopilotPurpose,
} from "../../utils/enums/Enums";
import { GroupChatImages } from "./GroupChatImages";
import { useCreationStore } from "../../store/creationStore";
import { getPurposeImages } from "./functions/getPurposeImages";

interface ChatTitleProps {
  selectedCopilotPurpose: CopilotPurpose | null;
  selectedAgent: CopilotAgentIdentifier;
  chatAgents: CopilotAgentIdentifier[];
  postName?: string;
}

export const ChatTitle = ({
  selectedCopilotPurpose,
  selectedAgent,
  chatAgents,
  postName,
}: ChatTitleProps) => {
  const getTitle = () => {
    if (selectedCopilotPurpose === CopilotPurpose.REGULAR) {
      return CopilotAgentName[selectedAgent];
    }

    if (postName && selectedCopilotPurpose === CopilotPurpose.POSTCREATION) {
      return postName;
    }

    if (selectedCopilotPurpose === CopilotPurpose.BRAND) {
      return "Brand";
    }

    if (selectedCopilotPurpose === CopilotPurpose.BRANDCREATION) {
      return "Create Brand";
    }

    if (selectedCopilotPurpose === CopilotPurpose.POSTCREATION) {
      return "General";
    }

    return "";
  };

  const { selectedTab, setSelectedTab } = useCreationStore();

  return (
    <div
      className={`flex  ${selectedCopilotPurpose === CopilotPurpose.REGULAR ? "gap-2 items-center" : "flex-col"}`}
    >
      {selectedCopilotPurpose === CopilotPurpose.REGULAR && (
        <div className="flex items-center gap-2">
          <GroupChatImages
            images={
              getPurposeImages({
                selectedAgent,
                selectedCopilotPurpose,
              }) || [""]
            }
            name={CopilotAgentName[selectedAgent]}
            variant="chat"
          />
        </div>
      )}
      <p className="text-md-b text-gray-700">{getTitle()}</p>
      {selectedCopilotPurpose === CopilotPurpose.POSTCREATION && (
        <SUnderlineTabs
          items={[
            { text: "Messages", index: 0 },
            { text: "History", index: 1 },
          ]}
          selected={selectedTab}
          setSelected={setSelectedTab}
          className="translate-y-[15px]"
        />
      )}
    </div>
  );
};
