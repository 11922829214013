import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../../auth/axios";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ICopilotMessage } from "../../../utils/copilot-base/ICopilotBase";

export const useGetCopilotChat = ({
  sessionId,
  enabled,
}: {
  sessionId?: null | string;
  enabled?: boolean;
}) => {
  return useQuery<IBaseData<ICopilotMessage[]>>({
    queryKey: ["useGetCopilotChat", sessionId],
    queryFn: async () => {
      const response = await makeRequest.get(`/Copilot/${sessionId}`);
      return response as IBaseData<ICopilotMessage[]>;
    },
    retry: 0,
    enabled: enabled,
  });
};
