import { useQuery } from "@tanstack/react-query";
import { IBrandTaskVM } from "../../../../utils/interfaces/IBrand";
import { IBaseData } from "../../../../utils/interfaces/IBase";
import { makeRequest } from "../../../auth/axios";

export const useGetBrandTasks = ({
  from,
  to,
}: {
  from: string;
  to: string;
}) => {
  const isAuthenticated = !!localStorage.getItem("token");
  return useQuery<IBaseData<IBrandTaskVM[]>>({
    queryKey: ["useGetBrandTasks", from, to],
    queryFn: async () => {
      const response = await makeRequest.get(
        `/BrandTasks?From=${from}&To=${to}`
      );
      return response as IBaseData<IBrandTaskVM[]>;
    },
    retry: false,
    enabled: isAuthenticated,
  });
};
