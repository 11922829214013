import { useEffect, useState } from "react";
import { useBrandStore } from "../../../store/brandStore";
import TextsSection, { IBrandSectionTextsProps } from "../TextsSection";
import { toast } from "../../../hooks/toast";
import { usePutBrand } from "../../../api/brand/put";
import _ from "lodash";
import { faMessage } from "@fortawesome/pro-regular-svg-icons";

export const SuggestedKeyMessages = () => {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  const [suggestedKeyMessages, setSuggestedKeyMessages] = useState<
    IBrandSectionTextsProps[]
  >([]);

  useEffect(() => {
    if (
      currentBrand?.brandingData?.suggestedKeyMessages &&
      currentBrand.brandingData.suggestedKeyMessages.length > 0
    ) {
      let tmpSuggestedKeyMessages: IBrandSectionTextsProps[] = [];
      currentBrand.brandingData.suggestedKeyMessages.forEach((item, index) => {
        tmpSuggestedKeyMessages.push({
          isTitleEditable: false,
          key: `suggested-key-message-${index}`,
          content: item,
          showBorder:
            index !== currentBrand.brandingData.suggestedKeyMessages.length - 1,
          handleChangeText: (text: string) => {
            if (
              currentBrand &&
              currentBrand.brandingData.suggestedKeyMessages[index] !== text
            ) {
              let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
              tmpBrandingData.suggestedKeyMessages[index] = text;
              updateBrand({
                brandId: currentBrand.id,
                brandingData: tmpBrandingData,
              })
                .then(() => {
                  toast(
                    "Suggested Key Messages updated successfully",
                    "success"
                  );
                })
                .catch((err) => {
                  toast(
                    `Failed to update Suggested Key Messages, ${err.response.data.message}`,
                    "error"
                  );
                });
            }
          },
        });
      });
      setSuggestedKeyMessages(tmpSuggestedKeyMessages);
    }
  }, [currentBrand]);

  return (
    <TextsSection
      fieldName="Suggested Key Messages"
      loadingCount={1}
      title="Suggested Key Messages"
      subtitle=""
      isLoading={!currentBrand?.brandingData?.suggestedKeyMessages}
      texts={suggestedKeyMessages}
    />
  );
};
