import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MessageBulletPoints = ({
  title,
  bulletPoints,
}: {
  title: string;
  bulletPoints: string[];
}) => {
  return (
    <div>
      <p className="text-gray-800 text-md-sb mb-1">{title}:</p>
      <div className="flex flex-col gap-3">
        {bulletPoints.map((point) => {
          return (
            <div className="flex items-start gap-2">
              <FontAwesomeIcon
                className="text-amethyst-700 translate-y-[5px] text-[8px]"
                icon={faCircle}
              />
              <p className="text-sm-m">{point}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
