import React, { useEffect, useState } from "react";
import TextsSection, { IBrandSectionTextsProps } from "../TextsSection";
import _ from "lodash";
import { useBrandStore } from "../../../store/brandStore";
import { usePutBrand } from "../../../api/brand/put";
import { toast } from "../../../hooks/toast";
import { faEye } from "@fortawesome/pro-regular-svg-icons";

function VisionStatement() {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  const [visionStatement, setVisionStatement] = useState<
    IBrandSectionTextsProps[]
  >([]);

  useEffect(() => {
    if (currentBrand?.brandingData?.visionStatement) {
      let tmpVisionStatement: IBrandSectionTextsProps[] = [];
      tmpVisionStatement.push({
        isTitleEditable: true,
        key: `vision-statement`,
        content: currentBrand.brandingData.visionStatement.paragraph,
        title: currentBrand.brandingData.visionStatement.subHeadline,
        showBorder: false,
        handleChangeText: (text: string) => {
          if (currentBrand?.brandingData?.visionStatement.paragraph !== text) {
            let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
            tmpBrandingData.visionStatement.paragraph = text;
            updateBrand({
              brandId: currentBrand.id,
              brandingData: tmpBrandingData,
            })
              .then(() => {
                toast("Vision Statement updated successfully", "success");
              })
              .catch((err) => {
                toast(
                  `Failed to update Vision Statement, ${err.response.data.message}`,
                  "error"
                );
              });
          }
        },
        handleChangeTitle: ({ text, sKey }: { text: string; sKey: string }) => {
          if (
            currentBrand?.brandingData?.visionStatement.subHeadline !== text
          ) {
            let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
            tmpBrandingData.visionStatement.subHeadline = text;
            updateBrand({
              brandId: currentBrand.id,
              brandingData: tmpBrandingData,
            })
              .then(() => {
                toast("vision Statement updated successfully", "success");
              })
              .catch((err) => {
                toast(
                  `Failed to update vision Statement, ${err.response.data.message}`,
                  "error"
                );
              });
          }
        },
      });
      setVisionStatement(tmpVisionStatement);
    }
  }, [currentBrand]);

  return (
    <>
      <TextsSection
        fieldName="Vision Statement"
        loadingCount={1}
        title="Vision Statement"
        subtitle=""
        isLoading={!currentBrand?.brandingData?.visionStatement}
        texts={visionStatement}
      />
    </>
  );
}

export default VisionStatement;
