import { useEffect, useRef, useState } from "react";
import { PreviewCardPlaceholder } from "./PreviewCardPlaceholder";
import { useCreationStore } from "../../store/creationStore";
import { usePostGeneratePostName } from "../../api/creation/generateName/post";
import { useUpdatePost } from "../creation/hooks/useUpdatePost";
import ImagesGrid from "../creation/Tabs/Media/ImagesGrid";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import { useGetUser } from "../../hooks/useUserInfo";
import SButton from "../../design-system/SButton";
import {
  faGif,
  faSparkles,
  faPaintbrushPencil,
  faUpload,
  faImage,
} from "@fortawesome/pro-regular-svg-icons";
import { isVideo } from "../Library/hooks/getFileType";

export const PreviewCard = ({
  handleBlur,
}: {
  handleBlur: (textAreaValue: string) => void;
}) => {
  const {
    postData,
    setAssetsModal,
    setIsExpandedImagesOpen,
    setPositions,
    postUpdateAnimation,
    setPostUpdateAnimation,
  } = useCreationStore();
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { mutateAsync: generateName } = usePostGeneratePostName();
  const { updateName } = useUpdatePost();
  const { setAssetsTab } = useCreationStore();

  const { data: personalInfo } = useGetUser();
  const contentRef = useRef<HTMLPreElement>(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (postData) {
      setValue(postData.socialNetworkPosts[0].text ?? "");
      setPositions(
        postData.socialNetworkPosts[0].files.sort(
          (a, b) => a.orderId - b.orderId
        )
      );
      setIsLoading(false);
    }
    if (postData && !postData.name) {
      if (postData.story.length > 0) {
        generateName({ story: postData.story }).then((res) => {
          updateName(res.data.name);
        });
      } else if (
        postData.socialNetworkPosts.length > 0 &&
        postData.socialNetworkPosts[0].text &&
        postData.socialNetworkPosts[0].text?.length > 20
      ) {
        generateName({ story: postData.socialNetworkPosts[0].text }).then(
          (res) => {
            updateName(res.data.name);
            setIsLoading(false);
          }
        );
      }
    }
  }, [postData]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (postUpdateAnimation) {
      const wait = async () => {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setPostUpdateAnimation(false);
      };
      wait();
    }
    return () => clearTimeout(timeout);
  }, [postUpdateAnimation]);

  if (isLoading) {
    return (
      <div className="w-full h-[430px] relative border border-gray-200 rounded-xl flex-grow">
        <div className="animate-pulse">
          <div className="w-full h-[200px] bg-gray-200 rounded-t-xl" />
          <div className="p-4 space-y-3">
            <div className="h-4 bg-gray-200 rounded w-3/4" />
            <div className="h-4 bg-gray-200 rounded w-1/2" />
            <div className="h-4 bg-gray-200 rounded w-2/3" />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="p-0 h-full">
      <div className="w-full flex justify-between items-center gap-2 pb-4 overflow-auto hide__scroll">
        <SButton
          className="font-light"
          type="secondaryGray"
          size="sm"
          lIcon={faSparkles}
          onClick={(e) => {
            e.stopPropagation();
            setAssetsModal(true);
            setAssetsTab("generator");
          }}
        >
          Generate
        </SButton>
        <SButton
          type="secondaryGray"
          size="sm"
          lIcon={faGif}
          className=""
          onClick={(e) => {
            e.stopPropagation();
            setAssetsModal(true);
            setAssetsTab("gifs");
          }}
        >
          GIFs <span className="hidden text-nowrap xl:block">& Clips</span>
        </SButton>
        <SButton
          type="secondaryGray"
          size="sm"
          lIcon={faImage}
          className=""
          onClick={(e) => {
            e.stopPropagation();
            setAssetsModal(true);
            setAssetsTab("stocks");
          }}
        >
          Stock <span className="hidden text-nowrap xl:block">Photos</span>
        </SButton>
        {/* <SButton
          type="secondaryGray"
          size="sm"
          lIcon={faPaintbrushPencil}
          className=""
          onClick={(e) => {
            e.stopPropagation();
            setAssetsModal(true);
            setAssetsTab("templates");
          }}
        >
          Templates
        </SButton> */}
        <SButton
          type="secondaryGray"
          size="sm"
          lIcon={faUpload}
          className=""
          onClick={(e) => {
            e.stopPropagation();
            setAssetsModal(true);
            setAssetsTab("assets");
          }}
        />
      </div>
      <div className="w-full h-full relative border border-gray-200 rounded-xl flex-grow overflow-y-auto hide__scroll">
        <div className="[&>div]:hover:opacity-100 z-10">
          {postData?.socialNetworkPosts[0].files.length === 0 && (
            <PreviewCardPlaceholder />
          )}
          {postData?.socialNetworkPosts[0].files.length &&
          !isVideo(postData?.socialNetworkPosts[0].files[0].url || "")
            ? postData?.socialNetworkPosts[0].files.length > 0 && (
                <div className="w-full cursor-pointer px-4 pt-4 pb-2 h-[200px] rounded-lg">
                  <ImagesGrid
                    onClick={() => {
                      personalInfo?.data.data.userRole !==
                        OrganizationUserRole.VIEWER &&
                        setIsExpandedImagesOpen(true);
                    }}
                    images={postData?.socialNetworkPosts[0].files.map((item) =>
                      item.templateOutputUrl
                        ? item.templateOutputUrl
                        : item.url!
                    )}
                  />
                </div>
              )
            : ""}
          {postData?.socialNetworkPosts[0].files.length &&
          isVideo(postData?.socialNetworkPosts[0].files[0].url || "") ? (
            <video
              key={postData?.socialNetworkPosts[0].files[0].url}
              className="w-full h-full object-cover rounded-t-xl"
              controls
            >
              <source src={postData?.socialNetworkPosts[0].files[0].url} />
            </video>
          ) : (
            ""
          )}
        </div>
        <div className="bg-white p-4 w-full h-full rounded-b-xl border-x-0 border-b-0 ">
          <pre
            ref={contentRef}
            contentEditable
            onInput={(e: any) => {
              if (!isEditing) {
                setIsEditing(true);
              }
            }}
            onBlur={(e) => {
              setValue(
                e.target.innerText.replace(/\*/g, "").replace(/#{2,}/g, "")
              );
              handleBlur(e.target.innerText);
              if (e.target.innerText.length === 0) {
                setIsEditing(false);
              }
            }}
            className={`whitespace-pre-wrap break-words text-sm h-[calc(100%-10px)] border-0 resize-none outline-none focus-visible:outline-[0] focus:ring-0 inline transition-all text-gray-700 ${postUpdateAnimation && "bg-brand-200"}`}
          >
            {value}
          </pre>
          {!isEditing && !value.length && (
            <span
              onPaste={(event) => {
                event.preventDefault();
                const text = event.clipboardData.getData("text/plain");
                document.execCommand("insertText", false, text);
              }}
              onClick={() => {
                setIsEditing(true);
                contentRef.current?.focus();
              }}
              className="text-gray-500 text-sm"
            >
              Your content goes here...
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
