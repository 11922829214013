import SBadge from "../../design-system/SBadge";
import { useCreationStore } from "../../store/creationStore";
import { ICopilotMessage } from "../../utils/copilot-base/ICopilotBase";
import { IOneMonthPlanMessage } from "../../utils/interfaces/ICopilot";
import { MessageBulletPoints } from "./MessageBulletPoints";

export const OneMonthPlanMessages = ({
  item,
  message,
}: {
  item: ICopilotMessage;
  message: string | { brandTaskId: number; description: string }[];
}) => {
  const parsedMessage: IOneMonthPlanMessage[] = JSON.parse(message as string);
  const { briefMessages } = useCreationStore();
  return (
    <>
      {parsedMessage.map((idea) => {
        return (
          <div className="h-fit w-[500px] flex gap-[12px] relative max-md:gap-[4px] mb-4">
            <img
              src={
                item.agentImage ||
                briefMessages[briefMessages.length - 3].agentImage
              }
              alt={`${item.agentName}-avatar`}
              className="max-w-[40px] max-h-[40px] w-[40px] h-[40px] max-md:max-w-[30px] max-md:max-h-[30px] max-md:w-[30px] max-md:h-[30px] rounded-full cursor-pointer aspect-square"
            />
            <div className="flex w-full flex-col gap-0.5">
              <p className="text-xs-sb text-gray-500">
                {item.agentName ||
                  briefMessages[briefMessages.length - 3].agentName}
              </p>
              <div
                className={`bg-gray-50 flex flex-col justify-center items-start border rounded-[8px] rounded-tl-[0] border-gray-200 py-[10px] px-[14px] max-w-[456px] w-full`}
              >
                <div className="w-full">
                  <div className="flex flex-col gap-5 w-full p-4 rounded-lg min-h-[100px]">
                    <p className="text-gray-800 text-lg-b">
                      Week #{idea.weekNumber} - Content Strategy{" "}
                    </p>
                    <div className="w-full flex flex-col gap-5">
                      <div>
                        <p className="text-gray-800 text-md-sb mb-1">
                          Content to Create:
                        </p>
                        <div className="flex flex-col gap-5">
                          {idea.contentToCreate.map((content) => {
                            return (
                              <div className="flex flex-col bg-base-white p-3 rounded-lg gap-2">
                                <p className="text-sm-m">
                                  {content.contentTitle}
                                </p>
                                <p className="text-sm text-gray-500">
                                  {content.descriptionMemo}
                                </p>
                                <div className="self-end mt-1">
                                  <SBadge className="text-sm-m rounded-md">
                                    {content.recommendedPlatform}
                                  </SBadge>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <MessageBulletPoints
                        title="Key Tasks"
                        bulletPoints={idea.keyTasks as string[]}
                      />
                      <MessageBulletPoints
                        title="Objectives"
                        bulletPoints={idea.objectives as string[]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
