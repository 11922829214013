import { useBrandStore } from "../../store/brandStore";
import SBadge from "../../design-system/SBadge";
import { ICopilotMessage } from "../../utils/copilot-base/ICopilotBase";
import { useCreationStore } from "../../store/creationStore";
import { ICalendarContentOutlineResult } from "../../utils/interfaces/ICopilot";
import { MessageBulletPoints } from "./MessageBulletPoints";

export const CalendarContentOutline = ({
  message,
  item,
}: {
  message: string;
  item: ICopilotMessage;
}) => {
  const parsedMessage: ICalendarContentOutlineResult[] = JSON.parse(message);
  const { currentBrand } = useBrandStore();
  const { briefMessages } = useCreationStore();
  return (
    <>
      {parsedMessage.map((idea) => {
        return (
          <div className="h-fit w-[500px] flex gap-[12px] relative max-md:gap-[4px] mb-4">
            <img
              src={
                item.agentImage ||
                briefMessages[briefMessages.length - 3].agentImage
              }
              alt={`${item.agentName}-avatar`}
              className="max-w-[40px] max-h-[40px] w-[40px] h-[40px] max-md:max-w-[30px] max-md:max-h-[30px] max-md:w-[30px] max-md:h-[30px] rounded-full cursor-pointer aspect-square"
            />
            <div className="flex w-full flex-col gap-0.5">
              <p className="text-xs-sb text-gray-500">
                {item.agentName ||
                  briefMessages[briefMessages.length - 3].agentName}
              </p>
              <div
                className={`bg-gray-50 flex flex-col justify-center items-start border rounded-[8px] rounded-tl-[0] border-gray-200 py-[10px] px-[14px] max-w-[456px] w-full`}
              >
                <div className="w-full">
                  <div className="flex flex-col gap-5 w-full p-4 rounded-lg min-h-[100px]">
                    <div className="justify-between flex flex-col">
                      <p className="text-gray-800 text-lg-b">
                        {idea.timePeriod} - Content plan draft for{" "}
                        {currentBrand?.brandingData.brandName}
                      </p>
                      <p className="text-gray-500 text-md-m italic">
                        {idea.theme}
                      </p>
                    </div>
                    <MessageBulletPoints
                      title="Content Topics"
                      bulletPoints={idea.contentTopics}
                    />
                    <MessageBulletPoints
                      title="Event Ideas"
                      bulletPoints={idea.eventIdeas}
                    />
                    <div>
                      <p className="text-gray-800 text-md-sb mb-1">
                        Recommended Channels:
                      </p>
                      <div className="flex flex-wrap gap-2">
                        {idea.recommendedChannels.map((channel) => {
                          return (
                            <SBadge className="rounded-md">{channel}</SBadge>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
