import { useLocation } from "react-router-dom";
import { TeamPackageStatus } from "../../../utils/enums/Enums";
import { useGetCurrentPackage } from "../../../api/subscriptions/current/get";

export const useBillingWarning = () => {
  const { data: currentPlan } = useGetCurrentPackage();
  const location = useLocation();
  const showBillingWarning = () => {
    if (
      !currentPlan ||
      location.pathname === "/workspace" ||
      location.pathname === "/"
    )
      return false;

    const show =
      currentPlan?.data.status === TeamPackageStatus.PAUSED ||
      currentPlan?.data.status === TeamPackageStatus.CANCELED ||
      (currentPlan?.data.status === TeamPackageStatus.ACTIVE &&
        currentPlan?.data.isTrial);

    return show;
  };
  return { showBillingWarning };
};
