import { useRef, useState } from "react";
import LibraryTab from "./Media/LibraryTab";
import { useCreationStore } from "../../../store/creationStore";
// @ts-ignore
import DropboxChooser from "react-dropbox-chooser";
import { useUploadAssets } from "../../Library/hooks/useUploadAssets";
import { useLibraryDropbox } from "../../Library/hooks/useLibraryDropbox";
import { useFolderHistory } from "../../Library/hooks/useFolderHistory";
import { LibraryCreateFolderModal } from "../../Library/LibraryCreateFolderModal";
import { useCreationLibraryHelperFunctions } from "./Media/hooks/useCreationLibraryHelperFunctions";
import { toast } from "../../../hooks/toast";
import { useGridCardActions } from "../../Library/hooks/useGridCardActions";
import { ConfirmDelete } from "../../Library/ConfirmDelete";
import { deleteFileOrFolder } from "../../Library/functions/deleteFileOrFolder";
import { LibraryLoadingFile } from "../../Library/LibraryLoadingFile";
import { LibraryLoadingFilesModal } from "../../Library/LibraryLoadingFilesModal";
import { useLibraryStore } from "../../../store/libraryStore";
import { ISingleFIleVM } from "../../../utils/interfaces/IAssets";
import { AssetModalSearch } from "../../newContent/assetModal/AssetModalSearch";
import { SwitcherButtons } from "../../newContent/assetModal/SwitcherButtons";
import DefaultState from "./Media/HelperInput/DefaultState";
import { GifsView } from "./Media/HelperInput/GifsView";
import { KlipyType } from "../../../utils/interfaces/IKlipyGifs";
import StocksView from "./Media/HelperInput/StocksView";
import { useGeneratorHooks } from "../../newContent/assetModal/hooks/useGeneratorHooks";
import GeneratedSelector from "./Media/HelperInput/GeneratedSelector";
function Media() {
  const wrapperRef = useRef<any>();
  const { librarySearchValue, assetsTab, openAssetsGeneratedImageModal } =
    useCreationStore();
  const { parentFolderId, isFetching } = useFolderHistory({
    wrapperRef,
    searchValue: librarySearchValue.length > 0 ? librarySearchValue : undefined,
  });
  const [folderName, setFolderName] = useState("");
  const { selectUploadedMedia } = useCreationLibraryHelperFunctions();
  const { uploadErrors } = useLibraryStore();
  const containsDocs = (files: ISingleFIleVM[]) => {
    return files.some(
      (file) =>
        file.extension?.toLocaleLowerCase() === ".pdf" ||
        file.extension?.toLocaleLowerCase() === ".docx" ||
        file.extension?.toLocaleLowerCase() === ".txt"
    );
  };
  const {
    selectFile,
    createFolder,
    isPending,
    createFile,
    files: assetFiles,
    setFiles: setAssetFiles,
  } = useUploadAssets({
    parentFolderId,
    uploadFileAdditionalFunction: (res) => {
      if (containsDocs(res.data.uploadedFiles)) return;
      selectUploadedMedia(res);
    },
  });
  const { dropboxRef, onDropboxSelectSubmited, setDropboxFiles, dropboxFiles } =
    useLibraryDropbox({
      createFile,
      parentFolderId,
      isPending,
      uploadFileAdditionalFunction: (res) => {
        if (containsDocs(res.data.uploadedFiles)) return;
        selectUploadedMedia(res);
      },
    });

  const [openModal, setOpenModal] = useState(false);
  const {
    openRename,
    setOpenRename,
    updateFolder,
    setValue,
    value,
    handleDownload,
    deleteFile,
    deleteFolder,
    setOpenConfirmation,
    openConfirmation,
    handleDelete,
    handleMoveInto,
    handleMoveOut,
    handleFolderDownload,
    handleRename,
    renameAsset,
    deleteAsset,
  } = useGridCardActions();

  const {
    activeStyleTag,
    setActiveStyleTag,
    activeAspectRatio,
    setActiveAspectRatio,
    setSelected,
    selected,
  } = useGeneratorHooks();
  const [inputValue, setInputValue] = useState("");
  const [gifSearch, setGifSearch] = useState("");
  const [type, setType] = useState<"Gifs" | "Clips">("Gifs");

  return (
    <>
      {openModal && (
        <LibraryCreateFolderModal
          openModal={openModal}
          action="Create"
          type="Folder"
          setOpenModal={() => setOpenModal(false)}
          name={folderName}
          setName={(e) => setFolderName(e.target!.value)}
          onSave={() => {
            createFolder({ name: folderName, parentFolderId }).catch((err) => {
              toast(
                err?.response?.data?.message ||
                  "An error occurred while creating the folder"
              );
            });
            setOpenModal(false);
            setFolderName("");
          }}
        />
      )}

      {openRename && (
        <LibraryCreateFolderModal
          action="Rename"
          type={"Folder"}
          openModal={openRename}
          setOpenModal={() => setOpenRename(false)}
          name={value}
          setName={(e) => setValue(e.target!.value)}
          onSave={() => {
            updateFolder({
              folderName: value,
              id: renameAsset?.id || 0,
            })
              .then(() => {
                toast(`Folder renamed successfully`, "success");
              })
              .catch(() => {
                toast(`Folder rename failed`, "error");
              });
            setOpenRename(false);
            setValue("");
          }}
        />
      )}

      {openConfirmation && (
        <ConfirmDelete
          title="Are you sure you want to delete this asset?"
          setOpenModal={() => setOpenConfirmation(false)}
          openModal={openConfirmation}
          onCancel={() => setOpenConfirmation(false)}
          onConfirm={() =>
            deleteFileOrFolder({
              deleteAsset,
              deleteFolder,
              deleteFile,
              setOpenConfirmation,
            })
          }
        />
      )}

      {openAssetsGeneratedImageModal && (
        <GeneratedSelector selected={selected} setSelected={setSelected} />
      )}

      <div className="relative w-full h-[calc(100vh-100px)] overflow-hidden">
        <div className="min-h-[calc(46px+17px)] w-full flex justify-start md:items-center border-b-[1px] border-b-graycool-100 pb-2">
          <SwitcherButtons
            setOpenModal={setOpenModal}
            dropboxRef={dropboxRef}
            selectFile={selectFile}
            setInputValue={setInputValue}
          />
          <DropboxChooser
            appKey="js3i72s02ssirnl"
            success={(files: any) => {
              onDropboxSelectSubmited(files);
            }}
            extensions={[
              ".jpg",
              ".jpeg",
              ".png",
              ".mp4",
              ".mov",
              ".avi",
              ".wmv",
              ".flv",
              ".webm",
              ".pdf",
              ".docx",
              ".doc",
              ".xlsx",
              ".xls",
              ".txt",
            ]}
            multiselect={true}
          >
            <input ref={dropboxRef} className="hidden" />
          </DropboxChooser>
        </div>
        <LibraryLoadingFilesModal
          setDropboxFiles={setDropboxFiles}
          setFiles={setAssetFiles}
          isPending={isPending}
        >
          {assetFiles?.map((file) => {
            return (
              <LibraryLoadingFile
                url={URL.createObjectURL(file)}
                type={file.type}
                name={file.name}
                size={file.size}
                isSuccess={uploadErrors === null}
                isPending={isPending}
              />
            );
          })}
          {dropboxFiles?.map((file: any) => {
            return (
              <LibraryLoadingFile
                url={file.thumbnailLink}
                type="image/jpeg"
                icon={file.icon}
                name={file.name}
                size={file.bytes}
                isSuccess={uploadErrors === null}
                isPending={isPending}
              />
            );
          })}
        </LibraryLoadingFilesModal>
        <AssetModalSearch
          inputValue={inputValue}
          setSelected={setSelected}
          setInputValue={setInputValue}
          activeAspectRatio={activeAspectRatio}
          activeStyleTag={activeStyleTag}
          setGifSearch={setGifSearch}
          gifSearch={gifSearch}
          setType={setType}
          type={type}
        />
        {assetsTab === "assets" && (
          <LibraryTab
            wrapperRef={wrapperRef}
            isFetching={isFetching}
            handleDelete={handleDelete}
            handleDownload={handleDownload}
            handleFolderDownload={handleFolderDownload}
            handleMoveInto={handleMoveInto}
            handleRename={handleRename}
            handleMoveOut={handleMoveOut}
            librarySearchValue={librarySearchValue}
            setOpenModal={setOpenModal}
            dropboxRef={dropboxRef}
            selectFile={selectFile}
          />
        )}
        {assetsTab === "generator" && (
          <DefaultState
            setInputValue={setInputValue}
            activeStyleTag={activeStyleTag}
            setActiveStyleTag={setActiveStyleTag}
            activeAspectRatio={activeAspectRatio}
            setActiveAspectRatio={setActiveAspectRatio}
            setSelected={setSelected}
          />
        )}
        {assetsTab === "gifs" && (
          <GifsView
            type={type === "Gifs" ? KlipyType.GIF : KlipyType.CLIP}
            gifSearch={gifSearch}
          />
        )}
        {assetsTab === "stocks" && (
          <div className="w-full h-full">
            <StocksView inputValue={inputValue} setInputValue={setInputValue} />
          </div>
        )}
      </div>
    </>
  );
}

export default Media;
