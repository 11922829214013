import React from "react";
import SuggestedPrompts from "./DefaultState/SuggestedPrompts";
import {
  ImageGenerationAspectRatio,
  ImageGenerationStyle,
} from "../../../../../utils/enums/Enums";
import Generations from "./DefaultState/Generations";
import GenerationActions from "./DefaultState/GenerationActions";
import { ISingleFIleVM } from "../../../../../utils/interfaces/IAssets";

function DefaultState({
  setInputValue,
  activeStyleTag,
  setActiveStyleTag,
  activeAspectRatio,
  setActiveAspectRatio,
  setSelected,
}: {
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  activeStyleTag: ImageGenerationStyle;
  setActiveStyleTag: React.Dispatch<React.SetStateAction<ImageGenerationStyle>>;
  activeAspectRatio: ImageGenerationAspectRatio;
  setActiveAspectRatio: React.Dispatch<
    React.SetStateAction<ImageGenerationAspectRatio>
  >;
  setSelected: React.Dispatch<React.SetStateAction<ISingleFIleVM | null>>;
}) {
  return (
    <div className="h-full overflow-y-auto hide__scroll">
      <GenerationActions
        activeStyleTag={activeStyleTag}
        setActiveStyleTag={setActiveStyleTag}
        activeAspectRatio={activeAspectRatio}
        setActiveAspectRatio={setActiveAspectRatio}
      />
      <Generations setSelected={setSelected} />
    </div>
  );
}

export default DefaultState;
