import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp, faRefresh } from "@fortawesome/pro-regular-svg-icons";

interface SaveStatusProps {
  isSaving: boolean;
}

export const SaveStatus = ({ isSaving }: SaveStatusProps) => (
  <div className="flex px-4 items-center gap-1 h-[40px]">
    <FontAwesomeIcon
      className={`text-gray-700 text-xs ${isSaving && "animate-spin"}`}
      icon={isSaving ? faRefresh : faCloudArrowUp}
    />
    <p className="text-xs text-gray-700">{isSaving ? "Saving..." : "Saved"}</p>
  </div>
);
