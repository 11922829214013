import { useState } from "react";
import {
  ImageGenerationAspectRatio,
  ImageGenerationStyle,
} from "../../../../utils/enums/Enums";
import { ISingleFIleVM } from "../../../../utils/interfaces/IAssets";

export const useGeneratorHooks = () => {
  const [activeStyleTag, setActiveStyleTag] = useState<ImageGenerationStyle>(
    ImageGenerationStyle.NONE
  );
  const [activeAspectRatio, setActiveAspectRatio] =
    useState<ImageGenerationAspectRatio>(ImageGenerationAspectRatio["1:1"]);
  const [selected, setSelected] = useState<null | ISingleFIleVM>(null);

  return {
    activeStyleTag,
    setActiveStyleTag,
    activeAspectRatio,
    setActiveAspectRatio,
    selected,
    setSelected,
  };
};
