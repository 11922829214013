import { useGetCurrentBrand } from "../../../api/brand/current/get";
import { useCreationStore } from "../../../store/creationStore";
import { CopilotPurpose } from "../../../utils/enums/Enums";

import { PostHeader } from "./headers/PostHeader";
import { BrandPreviewHeader } from "./headers/BrandPreviewHeader";

interface PreviewHeaderProps {
  selectedCopilotPurpose: CopilotPurpose | null;
  isSaving: boolean;
  showPostHeader?: boolean;
  postName?: string;
}

const PreviewHeader = ({
  selectedCopilotPurpose,
  isSaving,
  postName,
}: PreviewHeaderProps) => {
  const { data: currentBrand } = useGetCurrentBrand();
  const { selectedChatId } = useCreationStore();
  if (!currentBrand) return null;

  return (
    <>
      {selectedChatId === "general" && (
        <PostHeader
          currentBrand={currentBrand}
          isSaving={isSaving}
          postName={postName}
        />
      )}
      {selectedChatId === "brand" && (
        <BrandPreviewHeader currentBrand={currentBrand} />
      )}
    </>
  );
};

export default PreviewHeader;
