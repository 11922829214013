import React from "react";
import { useCreationStore } from "../../../../../../store/creationStore";
import { useGetFiles } from "../../../../../../api/library/files/get";
import { ISingleFIleVM } from "../../../../../../utils/interfaces/IAssets";
import { ILibraryProps } from "../../../../../../pages/Library";
import { useCreationHelperFunctions } from "../../../../hooks/useCreationHelperFunctions";
import ImageView from "../../../Media/Library/ImageView";
import { useCreationLibraryHelperFunctions } from "../../hooks/useCreationLibraryHelperFunctions";

export const GenerationsSkeleton = () => {
  return (
    <div
      className={`grid grid-cols-4 gap-[20px] max-md:gap-[8px] max-md:grid-cols-3 mt-8 overflow-auto animate-pulse`}
    >
      <div className="w-full aspect-square object-cover rounded-[12px] bg-gray-200" />
      <div className="w-full aspect-square object-cover rounded-[12px] bg-gray-200" />
      <div className="w-full aspect-square object-cover rounded-[12px] bg-gray-200" />
      <div className="w-full aspect-square object-cover rounded-[12px] bg-gray-200" />
    </div>
  );
};

function Generations({
  setSelected,
}: {
  setSelected: React.Dispatch<React.SetStateAction<ISingleFIleVM | null>>;
}) {
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();
  const { insertMediaForAllSocialNetworkType, setSelectedImageFromResponse } =
    useCreationLibraryHelperFunctions();

  const { data: files, isFetching } = useGetFiles({
    page: 1,
    pageSize: 12,
    generatedOnly: true,
  });

  if (isFetching) {
    return <GenerationsSkeleton />;
  }

  if (!files?.data.files[0]) {
    return null;
  }

  const determineSelected = (item: ISingleFIleVM) => {
    if (!getActiveSocialNetworkPost()) return false;
    if (!getActiveSocialNetworkPost()?.files) return false;
    return getActiveSocialNetworkPost()!.files?.some(
      (sItem) => sItem.url === item?.url
    );
  };

  return (
    <div className="relative h-fit overflow-y-auto">
      {files?.data.files.length > 0 && (
        <div className="w-full h-[2px] bg-gray-100 mt-4 mb-4" />
      )}
      <div className="grid grid-cols-4 gap-[20px] max-md:gap-[8px] max-md:grid-cols-3">
        {files?.data.files.map((item) => (
          <ImageView
            key={item?.thumbnailMediumUrl || item.url}
            cardId={item.id || 0}
            handleDelete={undefined}
            handleDownload={undefined}
            extension={item.extension}
            setOpenConfirmation={undefined}
            cardTitle={item.name || ""}
            type="Image"
            isSelected={determineSelected(item)}
            onClick={async () => {
              if (item) {
                const response = await insertMediaForAllSocialNetworkType(
                  item,
                  false,
                  true
                );
                if (response) setSelectedImageFromResponse(response);
              }
            }}
            source={item?.thumbnailMediumUrl || item.url}
          />
        ))}
      </div>
    </div>
  );
}

export default Generations;
